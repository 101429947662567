import {useEffect} from "react";
import {api} from "../../api";
import {useSelector} from "react-redux";

const OrderDetailModal = () => {
	//Достаем данные из стейта для изменения каталога
	const {id} = useSelector(state => state.multiModal.modals[0].modalData);

	async function getData(){
		try{
			const response = await api.orderApi.getOrderDetail({id:id})

			console.log(response)
		}catch (e) {
			console.log(e)
		}
	}

	useEffect( ()=>{
		getData();
	},[])

	return (
		<div>
		</div>
	);
}

export default OrderDetailModal;