import {useEffect, useState} from 'react';
import {ReactSVG} from "react-svg";
import arrowUp from '../../../images/catalogs/category/arrow-up.svg';
import arrowDown from '../../../images/catalogs/category/arrow-down.svg';
import {useOpenMatch} from '../../../hooks/'
import {useDispatch, useSelector} from "react-redux";


const SearchHighlight = ({containerID, isMatching, matchingType}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [matchCount, setMatchCount] = useState(0);
	const [isFocused, setIsFocused] = useState(false);

	const dispatch = useDispatch()
	const openMatch = useOpenMatch();

	const lastSearchHighlight = useSelector(state => state.appData.lastSearchHighlight)

	const handleOnFocus = () => {
		dispatch({type: 'CHANGE_LAST_SEARCH_HIGHLIGHT', lastSearchHighlight: containerID})
		setIsFocused(true);
	};

	const handleBlur = () => {
		if(searchTerm.trim() === ''){
			setIsFocused(false);
		}
	};

	useEffect(() => {
		highlightText();
	}, [searchTerm]);

	const highlightText = () => {
		if (!searchTerm.trim()) {
			removeHighlight();
			setMatchCount(0);
			setCurrentIndex(-1);
			return;
		}

		const safeSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Экранируем спецсимволы
		const regex = new RegExp(`(${safeSearchTerm})`, 'gi');

		const titles = document.querySelectorAll(`${containerID} span`);

		titles.forEach((title) => {
			// Проверяем, есть ли у элемента сохранённый оригинальный текст
			if (!title.dataset.originalText) {
				title.dataset.originalText = title.textContent; // Сохраняем оригинальный текст в data-атрибут
			}

			const text = title.dataset.originalText;
			title.innerHTML = text.replace(regex, match => `<mark>${match}</mark>`);
		});

		const matches = document.querySelectorAll(`${containerID} mark`);
		setMatchCount(matches.length);
		setCurrentIndex(matches.length > 0 ? 0 : -1);
	};

	const handlePrev = () => {
		if (matchCount === 0) return;

		const newIndex = currentIndex <= 0 ? matchCount - 1 : currentIndex - 1;
		setCurrentIndex(newIndex);
		scrollToCurrent(newIndex);
	};


	const handleNext = () => {
		if (matchCount === 0) return;

		const newIndex = currentIndex >= matchCount - 1 ? 0 : currentIndex + 1;
		setCurrentIndex(newIndex);
		scrollToCurrent(newIndex);
	};

	// Функция прокручивает к выбранному элементу и удаляет выделение с предыдущего
	// Также стоит условие если мы находимся в матчинге , то открываем матч
	const scrollToCurrent = (index) => {
		const titles = document.querySelectorAll(`${containerID} mark`);
			if (titles[index]) {
				const currentElementID = titles[index].closest('.i_catalog-category-item').getAttribute('data-id');
				isMatching ? openMatch(Number(currentElementID), false, matchingType) : titles[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
				removeHighlight();
				titles[index].classList.add('current');
			}
	}

	// Функция восстанавливает оригинальный текст , и удаляет выделение с выбранного элемента
	// Также стоит условие если поиск у нас пустой , то удалаем все выделения и восстанавливаем текст
	const removeHighlight = () => {
		const highlightedTitles = document.querySelectorAll(`${containerID} span`);
		const highlightedMarks = document.querySelectorAll(`${containerID} mark`)

		if(searchTerm.trim() === ''){
			highlightedTitles.forEach(title => {
				if (title.dataset.originalText) {
					title.textContent = title.dataset.originalText; // Восстанавливаем оригинальный текст
					delete title.dataset.originalText; // Удаляем временный атрибут
				}
			});
		}else{
			highlightedMarks.forEach(title => {
				title.classList.remove('current');
			})
		}
	};

	// Слушатель на нажатие клавиши Enter чтобы переключать между элементами, Enter работает только на том элементе на котором был фокус в последний раз
	useEffect(() => {
		const handleKeyDown = (event) => {
			if (event.key === 'Enter' && lastSearchHighlight === containerID) {
				handleNext();
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [isMatching, matchCount, currentIndex]);


	return (
		<div className={`i_search-highlight ${isFocused ? 'active' : ''}`}>
			<input
				type="text"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				placeholder="Поиск..."
				onFocus={handleOnFocus}
				onBlur={handleBlur}
			/>
			<div className="i_search-highlight-btn">
				<button onClick={handlePrev} disabled={matchCount <= 1}><ReactSVG src={arrowUp} /></button>
				<button onClick={handleNext} disabled={matchCount <= 1}><ReactSVG src={arrowDown} /></button>
			</div>
		</div>
	);
};

export default SearchHighlight;
