import {ReactComponent as InfoSvg} from "../../../images/info.svg";
import {useDispatch} from "react-redux";

const DetailPrice = ({ price }) => {
	const dispatch = useDispatch()

	let markupsLastItem = 0
	let markupsContent = '';

	if(price?.markups && price?.markups?.length > 0){
		markupsLastItem = price.markups[price.markups.length - 1]
	}

	if(markupsLastItem){
		if(markupsLastItem.id !== 0) {
			markupsContent = `Цена продажи сформирована наценкой. <br> 
										Название: <strong>${markupsLastItem.name}</strong> <br> 
										Наценка: <strong>${markupsLastItem.markup}</strong> <br>
									  Приоритет: <strong>${markupsLastItem.priority}</strong>`
		}else{
			markupsContent = markupsLastItem.name
		}
	}

	return(
		<div className="i_product-detail-price">
			<div className="i_product-detail-price-items">
				<div className={`i_product-detail-price-item`}>
					<div className="i_product-detail-price-item-name">
						<span>Цена покупки</span>
					</div>
					<div className="i_product-detail-price-item-value">
            <span>
              {price && price?.purchase ? (
	              <>
		              {price?.purchase?.print_price}
	              </>
              ) : (
	              "Нет цены"
              )}
            </span>
					</div>
				</div>

				<div className={`i_product-detail-price-item`}>
					<div className="i_product-detail-price-item-name">
						<span>Цена RRP</span>
					</div>
					<div className="i_product-detail-price-item-value">
            <span>
              {price && price.rrp ? (
	              <>
		              {price?.rrp?.print_price}
	              </>
              ) : (
	              "Нет цены"
              )}
            </span>
					</div>
				</div>

				<div className={`i_product-detail-price-item`}>
					<div className="i_product-detail-price-item-name">
						<span>Цена продажи</span>
					</div>
					<div className="i_product-detail-price-item-value">
            <span>
              {price && price.retail ? (
	              <>
		              {price?.retail?.print_price}
	              </>
              ) : (
	              "Нет цены"
              )}
            </span>

						{
							price?.markups && price.markups.length > 0 && (
								<div className="i_product-detail-price-item-info"
								     onMouseEnter={() => dispatch({
									     type: "SWITCH_CURSOR_HELP", show: true, isHTML: true, showInMobile: true,
									     content: markupsContent
								     })}
								     onMouseLeave={() => dispatch({
									     type: "SWITCH_CURSOR_HELP",
									     show: false,
									     isHTML: false,
									     showInMobile: false
								     })}
								>
									<InfoSvg/>
								</div>
							)
						}


					</div>
				</div>
			</div>
		</div>
	)
}

export default DetailPrice;