import {useEffect, useState} from "react";
import SelectDropdown from "../../../pages/main/components/SelectDropdown";
import {useSelector} from "react-redux";
import styles from './SelectMarkupUnit.module.scss'
import {DoubleInput, CustomInput} from "../../../utils/ui";
import {api} from "../../../api";

const SelectMarkupUnit = ({onChange}) => {

	const modalData = useSelector(state => state.multiModal.modals[0]?.modalData?.item)
	const catalogCurrency = useSelector(state => state.markups.catalogCurrency)
	const originalMarkupUnit = modalData?.original?.markup_unit

	const [selectMarkupUnit, setSelectMarkupUnit] = useState(originalMarkupUnit || 'exact')
	const [exactValue, setExactValue] = useState(originalMarkupUnit === '%' ? modalData?.original?.markup : '')
	const [currencyValue, setCurrencyValue] = useState(originalMarkupUnit !== '%' ? modalData?.original?.markup : '')

	useEffect(() => {

		const data = {
			type: selectMarkupUnit,
			value: selectMarkupUnit === '%' ? exactValue : currencyValue,
		}

		onChange(data)
	}, [currencyValue, exactValue]);

	const markupUnitData = [
		{
			name: "Процент",
			value: '%'
		},
		{
			name: "Точное значение",
			value: 'exact'
		}
	]

	return (
		<div className={styles.markupsUnit}>
			<div className={styles.markupsUnitSelect}>
				<SelectDropdown defaultValue={selectMarkupUnit === '%' ? 'Процент' : 'Точное значение'}
				                label={'Тип наценки'}
				                item={markupUnitData} onChange={({value}) => {
					setSelectMarkupUnit(value)
				}}/>
			</div>

			{
				selectMarkupUnit === '%' ? (
					<div className={styles.markupsUnitRight}>
						<CustomInput type={'number'} title={'Размер наценки'} value={exactValue} onChange={(e) => {
							setExactValue(e)
						}}/>
					</div>
				) : (
					<div className={styles.markupsUnitRight}>
						<div className={styles.markupsUnitRightItem}>
							<CustomInput type={'number'} title={'Размер наценки'} value={currencyValue} onChange={(e) => {
								setCurrencyValue(e)
							}}/>
						</div>
						<div className={`${styles.markupsUnitRightItem} ${styles.markupsUnitRightItemCurrency}`}>
							<CustomInput type={'text'} title={'Валюта'} value={catalogCurrency} readOnly={true} />
						</div>
					</div>
				)
			}
		</div>
	)
}

export default SelectMarkupUnit;
