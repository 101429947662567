import {ReactComponent as ExportCatalogSvg} from "../../../images/catalogs/export-catalog.svg";
import {useDispatch} from "react-redux";

import styles from './ExportCatalog.module.scss'

const ExportCatalog = () => {

	const dispatch = useDispatch();

	return(
		<div className={styles.exportCatalog}
		     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Экспорт каталога'})}
		     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		     onClick={() => dispatch({type: "OPEN_MODAL", modalType: 'exportCatalogModal', modalLevel: 1})}
		>
			<ExportCatalogSvg />
		</div>
	)
}

export default ExportCatalog;