import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../../api";

//MUI components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import '../../styles/customMui/customTabs.scss'
import DetailProps from "../components/my/DetailProps";
import DetailParams from "../components/my/DetailParams";
import DetailAttribute from "../components/my/DetailAttribute";
import ImageSwiper from "../components/my/ImageSwiper";
import DetailQuantity from "../components/my/DetailQuantity";
import DetailPrice from "../components/my/DetailPrice";
import closeBtn from '../../images/burger-language-arrow.svg';
import infoImg from "../../images/info.svg";
import editBtn from "../../images/catalogs/catalog-edit.svg";
import Skeleton from "react-loading-skeleton";
import {ReactSVG} from "react-svg";
import {ScrollBox} from "../../utils/ui";
import QuantityCounter from "../../pages/main/components/QuantityCounter/QuantityCounter";

const MyCatalogProductDetailModal = () => {
	const [isLoading, setIsLoading] = useState(true)
	const [itemShowCount, setItemShowCount] = useState(5)
	const [showMore, setShowMore] = useState(false)
	const productId = useSelector(state => state.multiModal.modals[0].modalData.productId)
	const catalogCode = useSelector(state => state.catalog.catalogCode)

	const dispatch = useDispatch()

	//Табы для props
	const [currentPropsTab, setCurrentPropsTab] = useState('attributes')

	const handleChangePropsTab = (event, newValue) => {
		setCurrentPropsTab(newValue);
	};

	//Табы для props
	const [currentLeftTab, setCurrentLeftTab] = useState('price')

	const handleChangeLeftTab = (event, newValue) => {
		setCurrentLeftTab(newValue);
	};

	//Получаем данные с сервера
	const getData = useCallback(async () => {
		try {
			const productDetail = await api.cabinetApi.getCatalogProductDetail({id: productId, catalog_code: catalogCode});
			dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: productDetail.product})

			// Ставлю искуственную задержку чтобы не лагала модалка при открытии
			setTimeout(() => {
				setIsLoading(false)
			},300)

		} catch (e) {
			console.log(e)
		}
	},[])

	useEffect(() => {
		getData()
	}, [getData])

	//Показываем больше свойств

	const showMoreProps = () => {
		if (itemShowCount === 5) {
			setItemShowCount(100)
			setShowMore(true)
		} else {
			setItemShowCount(5)
			setShowMore(false)
		}
	}

	const productDetail = useSelector(state => state.catalog.list.productDetail)

	const counterItem = {
		productID: productDetail?.id,
		quantity: productDetail?.basket_quantity,
		maxQuantity: productDetail?.quantity?.order ? 10000 : productDetail?.quantity?.quantity,
	}

	return (
		<>
			{/*Скелетон для прогрузки страницы*/}

			{isLoading && (
				<Skeleton
					count={2}
					height={'100%'}
					inline={true}
					borderRadius={20}
					containerClassName={'i_modal-in-skeleton'}
				/>
			)
			}

			{/*Сама карточка товара*/}

			{!isLoading && productDetail && (
				<div className={`i_product-detail`}>
					<div className="i_product-detail-title">
						<span>{productDetail.name}</span>
					</div>
					<ScrollBox>
						<div className="i_product-detail-content">
							<div className="i_product-detail-content-left">
								{/*СВАЙПЕР*/}
								<div className="i_product-detail-images">
									{productDetail.images.length > 0 && (
										<ImageSwiper images={productDetail.images}/>
									)}
									{
										productDetail.images?.noimage && (
											<ReactSVG src={productDetail.images.noimage}
											          className={'i_product-detail-noimage'}/>
										)
									}
								</div>

								<TabContext value={currentLeftTab} className={'i_tabs'}>
									{/*ТАБ ПАНЕЛЬ*/}
									<TabList onChange={handleChangeLeftTab}
									         indicatorColor={'transparent'}
									         className={'i_tabs-header i_tabs-header-left'}>
										<Tab label="Цены" value="price" className={'i_tabs-header-item'}/>
										<Tab label="Остатки" value="quantity" className={'i_tabs-header-item'}/>
									</TabList>

									{/*ЦЕНЫ*/}
									<TabPanel value="price" className={'i_tabs-content-left'}>
										<DetailPrice price={productDetail.prices} pricetype={productDetail.price_types}/>
									</TabPanel>

									{/*ОСТАТКИ*/}
									<TabPanel value="quantity" className={'i_tabs-content-left'}>
										<DetailQuantity quantity={productDetail?.quantity}/>
									</TabPanel>
								</TabContext>

								{/*В КОРЗИНЕ*/}
								<div className="i_product-detail-quantity_counter">
									<div className="i_product-detail-quantity_counter-title">
										<span>В корзине</span>
									</div>
									<div className="i_product-detail-quantity_counter-item">
										<QuantityCounter counterItem={counterItem} inProductDetail={true} catalogCode={catalogCode}/>
									</div>
								</div>

							</div>
							<div className="i_product-detail-content-right">
								<div className="i_product-detail-props">
									<TabContext value={currentPropsTab} className={'i_tabs'}>

										{/*ВВЕРХНЯЯ ТАБ ПАНЕЛЬ*/}
										<TabList onChange={handleChangePropsTab}
										         indicatorColor={'transparent'}
										         className={'i_tabs-header'}
										         variant="scrollable"
										         scrollButtons="auto">
											<Tab label="Атрибуты" value="attributes" className={'i_tabs-header-item'}/>
											<Tab label="Свойства" value="props" className={'i_tabs-header-item'}/>
											<Tab label="Параметры" value="params" className={'i_tabs-header-item'}/>
										</TabList>

										{/*АТРИБУТЫ*/}
										<TabPanel value="attributes">
											<DetailAttribute attribute={productDetail} itemShowCount={itemShowCount}/>
										</TabPanel>

										{/*СВОЙСТВА*/}
										{
											productDetail.props?.props && productDetail.props?.props !== 'null' ? (
													<TabPanel value="props" className={'i_tap-panel'}>
														<DetailProps props={productDetail.props.props}
														             itemShowCount={itemShowCount}/>


														{/*Показываем блок раскрытия тадлицы свойств*/}
														{productDetail.props.props.length > 5 && (
															<div className={`i_show-more ${showMore ? 'show' : ''}`} onClick={showMoreProps}>
																<div className="i_show-more-img">
																	<img src={closeBtn} alt=""/>
																</div>
															</div>
														)}

													</TabPanel>
												) :
												<>
													<TabPanel value="props" className={'i_tap-panel'}>
														<div className="i_product-props-empty">
															<div className="i_product-props-empty-title">
																<img src={infoImg} alt=""/>
																<span>Свойства отсутствуют.</span>
															</div>
														</div>
													</TabPanel>
												</>
										}

										{/*ПАРАМЕТРЫ*/}
										{
											productDetail.params && productDetail.params !== 'null' && (
												<TabPanel value="params">
													<DetailParams params={productDetail.params} itemShowCount={itemShowCount}/>
												</TabPanel>
											)
										}
									</TabContext>

								</div>

								{/*ОПИСАНИЕ*/}
								<div className="i_product-detail-description">
									<span className="i_product-detail-content-title">Описание</span>
									{productDetail.description?.description ? (
											<>
										<span>
											{productDetail.description.description}
										</span>
											</>
										) :
										<>
											<div className="i_product-description-empty">
												<div className="i_product-description-empty-title">
													<img src={infoImg} alt=""/>
													<span>Описание отсутствует.</span>
												</div>
											</div>
										</>}
								</div>
							</div>
						</div>
					</ScrollBox>
				</div>
			)}
		</>
	)
}

export default MyCatalogProductDetailModal;