import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";
import toast from "react-hot-toast";
import {Toast} from "../utils/ui";

const DeleteMarketplaceConnection = () => {

	const dispatch = useDispatch();
	const currentItem = useSelector(state => state.multiPopup.currentItem);
	const marketplaces = useSelector(state => state.marketplaces.marketplacesList)

	// Функция для удаления подключения к маркетплейсу
	async function deleteConnection() {
		try{
			const response = await api.marketplacesApi.deleteMarketplaceConnection({id: currentItem})

			if(response.status === true){

				const updatedMarketplaces = marketplaces.map((item) => {
					if(item.connection_id === currentItem){
						delete item.connection_id;
						delete item.active;
					}
					return item
				})

				dispatch({type: 'SET_MARKETPLACES_LIST', marketplacesList: updatedMarketplaces})
				dispatch({type: "SWITCH_CURSOR_HELP", show: false})
				dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentItem: null})

				Toast(response.message, 'success');
			}else{
				Toast(response.message, 'error');
			}

		}catch (e) {
			console.log(e)
		}
	}

	return (
		<div className={'i_delete-distributor-catalog-popup'}>
			<div className="i_popup-title">
				<span>Вы действительно хотите удалить подключение?</span>
			</div>
			<div className="i_popup-buttons">
				<div className="i_popup-button-success" onClick={deleteConnection}>
					<button>Да</button>
				</div>
				<div className="i_popup-button-cancel" onClick={()=>{
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				}}>
					<button>Нет</button>
				</div>
			</div>
		</div>
	)
}

export default DeleteMarketplaceConnection;