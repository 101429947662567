import { useDispatch, useSelector } from "react-redux";

export default function useOpenMatch() {
	const dispatch = useDispatch();
	const updatedSourceList = useSelector(state => state.matching.categorySourceList);
	const updatedCatalogList = useSelector(state => state.matching.categoryCatalogList);

	const openMatch = (matchId, selectCurrentMatch, matchingType) => {
		const isSource = matchingType === 'source';
		const list = isSource ? updatedSourceList : updatedCatalogList;
		let newList = [...list];

		// Функция для открытия всех родительских элементов
		function openParentItem(id) {
			const parentItem = newList.find(item => item.id === id);
			if (parentItem) {
				if (parentItem.parent_id !== 0) openParentItem(parentItem.parent_id);
				parentItem.is_show = true;
			}
		}

		// Функция для прокрутки к нужному элементу
		function scrollToMatch() {
			const containerID = isSource ? ".i_matching-left" : ".i_matching-right";
			const catalogItemsBlock = document.querySelector(`${containerID} .i_catalog-category-items`);
			const catalogItem = catalogItemsBlock?.querySelector(`.i_catalog-category-item[data-id="${matchId}"]`);

			if (catalogItem) {
				catalogItem.scrollIntoView({ block: "center", behavior: "smooth" });
			}
		}

		newList = newList.map(item => {
			if (item.id === matchId) {
				if (item.parent_id !== 0) openParentItem(item.parent_id);

				if (selectCurrentMatch) {
					dispatch({
						type: isSource ? 'EDIT_SOURCE_ITEM' : 'EDIT_CATALOG_ITEM',
						[isSource ? 'currentSourceItem' : 'currentCatalogItem']: item.id,
					});
				}

				setTimeout(scrollToMatch, 100);

				return { ...item, is_active: selectCurrentMatch, show_match: selectCurrentMatch };
			}

			return item.has_children ? item : { ...item, is_active: false, is_show: false };
		});

		dispatch({
			type: isSource ? "EDIT_CATEGORY_SOURCE_LIST" : "EDIT_CATEGORY_CATALOG_LIST",
			[isSource ? "categorySourceList" : "categoryCatalogList"]: newList,
		});
	};

	return openMatch;
}
