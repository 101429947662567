import {ProductListHead, ProductListItem} from "../../../../../utils/ui/";
import {useDispatch} from "react-redux";
import styles from './SourceList.module.scss';
import {useEffect, useRef, useState} from "react";
import Cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import {useUrlParams} from "../../../../../hooks";

const SourceList = ({store}) => {
	const productBlockWidth = useRef(null)
	const dispatch = useDispatch();
	const catalogCode = store.catalogCode;
	const [loader, setLoader] = useState(true);
	const { addParam, getParam } = useUrlParams();

	useEffect(() => {
		dispatch({type: 'SET_CATALOG_LIST_TABLE_MIN_WIDTH', tableMinWidth: productBlockWidth.current.offsetWidth})

		const cookieData = Cookies.get(`columns_source_${catalogCode}`);
		if (cookieData) {
			const {columns, tableWidth} = JSON.parse(cookieData);
			const updatedColumns = JSON.parse(columns);

			if (updatedColumns && tableWidth) {
				dispatch({type: 'SET_CATALOG_LIST_TABLE_ITEM_WIDTH', tableItemWidth: updatedColumns || null})
				dispatch({type: 'SET_CATALOG_LIST_TABLE_WIDTH', tableWidth: tableWidth || null})
			}
		}

		// Ставим искусвенную задержку чтобы таблица загрузилась и высчиталась ширина колонок
		setLoader(false)

	}, [])

	useEffect(() => {
		dispatch({type: 'SET_CATALOG_LIST_TABLE_MIN_WIDTH', tableMinWidth: productBlockWidth.current.offsetWidth})
	}, [productBlockWidth?.current?.offsetWidth]);

	// Проверяем есть ли параметр product в урле и открываем модалку
	useEffect(() => {
		if (getParam('product')) {
			openCatalogDetailModal(getParam('product'))
		}
	},[])

	const openCatalogDetailModal = (id) => {
		addParam('product', id)

		dispatch({
			type: "OPEN_MODAL",
			modalType: "sourceCatalogProductDetailModal",
			modalLevel: 1,
			modalData: {productId : id},
			modalWidth: 'large'
		});
	};

	return (
		<div className={styles.productBlock} ref={productBlockWidth}>
			{
				<>
					<div className={`${styles.productBlockLoader} ${!loader ? styles.hideLoader : ''}`}>
						<Skeleton
							count={1}
							height={'80vh'}
							inline={true}
							borderRadius={15}
							containerClassName={styles.catalogPageInSkeleton}
						/>
					</div>

					{
						!loader && (
							<>
								<ProductListHead
									columns={store.list.columns}
									store={store}
									actionType={'catalogs'}
									showCheckBox={true}
									catalogType={'source'}
								/>
								<ProductListItem
									products={store.list.products.productList}
									columns={store.list.columns}
									store={store}
									emptyTitle={'Товары отсутствуют.'}
									showCheckBox={true}
									actionType={'catalogs'}
									catalogType={'source'}
									itemClick={openCatalogDetailModal}
								/>
							</>
						)
					}
				</>
			}
		</div>
	)
}

export default SourceList;