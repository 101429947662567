import {useEffect, useState} from "react";
import styles from '../style.module.scss'
import {CustomInput, EmptyBlock, SwitchButton} from "../../../../utils/ui";
import SelectDropdown from "../../../../pages/main/components/SelectDropdown";
import RedirectToCatalog from "../../../../utils/ui/EmptyBlock/components/RedirectToCatalog";
import CopyToClipboardButton from "../../../../pages/components/CopyToClipboardButton";

const ProductIntegration = ({
	                            marketFile,
	                            catalogList,
	                            dataSelectedCatalog,
	                            dataDefaultCatalog,
	                            changeProductIntegrationData,
	                            marketSettings
                            }) => {

	const [defaultCatalog, setDefaultCatalog] = useState(null);
	const [merchantId, setMerchantId] = useState(marketSettings.merchant_id);
	const [minQuantity, setMinQuantity] = useState(marketSettings.min_quantity);
	const [selectedCatalog, setSelectedCatalog] = useState(marketSettings.catalog_id);
	const [integrationActive, setIntegrationActive] = useState(marketSettings.product_on);

	useEffect(() => {
		setDefaultCatalog(dataDefaultCatalog);
		setSelectedCatalog(dataSelectedCatalog);
	}, [dataSelectedCatalog, dataDefaultCatalog])

	function changeSelectedCatalog(e) {
		setSelectedCatalog(e.value);
	}

	function changeIntegrationActive({isChecked}) {
		setIntegrationActive(isChecked);
	}

	useEffect(() => {
		changeProductIntegrationData({
			merchantId: merchantId,
			minQuantity: minQuantity,
			selectedCatalog: Number(selectedCatalog),
			active: integrationActive
		})
	}, [merchantId, minQuantity, selectedCatalog, integrationActive])


	return (
		<div className={styles.integrationItems}>
			<SwitchButton title={'Активно'} value={'product_on'} checkBoxValue={changeIntegrationActive}
			              inputChecked={integrationActive}/>

			<CustomInput title={'Merchant ID'} type={'text'} onChange={(e) => setMerchantId(e)} value={merchantId}
			             required={true}/>

			{catalogList.length === 0 ? (
				<EmptyBlock title={'Каталоги отсутствуют.'} fullWidth={true} Content={RedirectToCatalog}/>
			) : (
				<SelectDropdown label={'Выберите каталог для выгрузки'} item={catalogList} onChange={changeSelectedCatalog}
				                defaultValue={defaultCatalog}/>
			)}

			<CustomInput title={'Ограничение по остатку'} type={'number'} value={minQuantity}
			             onChange={(e) => setMinQuantity(e)}
			             helpTitle={'Не выгружаются товары, если остаток меньше указанного значения'} required={true}/>

			{
				marketFile && (
					<div className={styles.marketplacesItemFile}>
						<div className={styles.marketplacesItemFileContent}>
							<span>Прайс-лист: </span>
							<a href={marketFile} target={'_blank'}>{marketFile}</a>
						</div>
						<CopyToClipboardButton textToCopy={marketFile} style={{
							position: 'relative',
							top: 'unset',
							right: 'unset', transform: 'unset'
						}}/>
					</div>
				)
			}
		</div>
	)
}

export default ProductIntegration;