import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

// Регистрация компонентов Chart.js
ChartJS.register(ArcElement, Tooltip);

function MainPageDealerWidget({ dealerStatistic }) {

	const data = {
		labels: ['Активные', 'Не активные'],
		datasets: [
			{
				data: [dealerStatistic.count_active, dealerStatistic.count_inactive],
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)',
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
				],
				borderWidth: 1,
			},
		],
	};

	// Опции графика
	const options = {
		plugins: {
			legend: {
				display: false, // Отключение легенды
			},
		},
	};


	return (
		<div id="MainPageDealerWidget">
			<Doughnut data={data} options={options}/>
		</div>
	);
}

export default MainPageDealerWidget;
