import styles from './BasketList.module.scss'
import QuantityCounter from "../../../../pages/main/components/QuantityCounter/QuantityCounter";
import {ReactComponent as Delete} from "../../../../images/catalogs/category/delete.svg";
import {useSelector} from "react-redux";
import {useDeleteBasketItem} from '../../../../hooks'
import {BasketLoader, EmptyBlock} from "../../../../utils/ui";

const BasketList = () => {
	const basketList = useSelector(state => state.basket.basketList)
	const basketLoader = useSelector(state => state.basket.basketLoader)
	const {deleteBasketItem} = useDeleteBasketItem()

	return (
		<>
			{basketLoader && <BasketLoader height={'calc(100% - 70px)'}/>} {/*Отображение лоадера*/}

			{basketList?.length > 0 ?
				<div className={styles.basketItems}>
					{basketList?.map((item, index) => {

							const counterItem = {
								productID: item.product_id,
								quantity: item.quantity,
								maxQuantity: item.order ? 10000 : item.max_quantity,
							}

							return (
								<div className={styles.basketItem} key={index}>
									<div className={styles.basketItemName}>

										<div className={styles.basketItemImg}>
											<img src={item?.image} alt=""/>
										</div>

										<div className={styles.basketItemNameText}>
											<div className={styles.basketItemNameTitle}>
												<span>{item?.name}</span>
											</div>
											<div className={styles.basketItemNameSubtitle}>
												<span>{item?.added_from}</span>
												<span> | </span>

												<span>Дистрибьютор: Alser</span>
											</div>
										</div>

									</div>

									{/*Счетчик*/}
									<div className={styles.quantity}>
										<QuantityCounter counterItem={counterItem} inBasket={true} key={index}/>
									</div>

									<div className={`${styles.basketItemPrice} ${styles.basketPricePurchase}`}>
										<div className={styles.basketItemPriceTitle}>
											<span>Цена покупки:</span>
										</div>
										<div className={styles.basketItemPriceValue}>
											<span>{item?.purchase_print}</span>
										</div>
									</div>
									<div className={`${styles.basketItemPrice} ${styles.basketPriceRetail}`}>
										<div className={styles.basketItemPriceTitle}>
											<span>Цена продажи:</span>
										</div>
										<div className={styles.basketItemPriceValue}>
											<span>{item?.retail_print}</span>
										</div>
									</div>
									<div className={styles.basketItemDelete} onClick={() => {
										deleteBasketItem(item.product_id)
									}}>
										<Delete/>
									</div>
								</div>
							)
						}
					)}
				</div> :
				<EmptyBlock title={'Корзина пуста'}/>
			}


		</>
	)
}

export default BasketList;