import {useDispatch, useSelector} from "react-redux";
import plus from '../../../../images/catalogs/category/plus.svg'
import minus from '../../../../images/catalogs/category/minus.svg'
import deleteSvg from '../../../../images/closeModal.svg'
import matchChain from '../../../../images/match-chain.svg'
import matchingProduct from "../../../../images/matchingProduct.svg";
import {api} from "../../../../api";
import toast from "react-hot-toast";
import {useOpenMatch} from "../../../../hooks";
import {Toast} from "../../../../utils/ui";

// Рекурсивный компонент для отображения категорий
const Category = ({category, categories}) => {
	// Находим подкатегории для текущей категории
	const subCategories = categories.filter(subCategory => subCategory.parent_id === category.id);
	const dispatch = useDispatch();
	const level = category.depth_level - 1;
	const currentSourceList = useSelector(state => state.matching.currentSourceList);
	const categoryCatalogList = useSelector(state => state.matching.categoryCatalogList);
	const categorySourceList = useSelector(state => state.matching.categorySourceList);
	const currentCatalogItem = useSelector(state => state.matching.currentCatalogItem);
	const showCatalogProductList = useSelector(state => state.matching.showCatalogProductList);
	const productSourceList = useSelector(state => state.matching.sourceProductList);
	const showSourceProductList = useSelector(state => state.matching.showSourceProductList);
	const openMatch = useOpenMatch();

	// Делаем запрос на выбранный каталог для показа товаров
	function changeCurrentItem(id) {
		//Функция для изменения выбранной категории
		if (id === currentCatalogItem) {
			dispatch({type: 'EDIT_CATALOG_ITEM', currentCatalogItem: null})
		} else {
			dispatch({type: 'EDIT_CATALOG_ITEM', currentCatalogItem: id})
		}
	}

	// Делаем активной выбранную категорию
	function changeSelectedItem(id) {
		const updatedItem = categories.map(item => {
			if (item.id === id) {
				changeCurrentItem(id)
				return {
					...item,
					is_active: !item.is_active,
					show_match: item.match ? item.is_active === false ? true : !item.show_match : false,
					is_show: false
				}
			} else {
				return {...item, is_active: false}
			}
		})

		dispatch({type: "EDIT_CATEGORY_CATALOG_LIST", categoryCatalogList: updatedItem})
	}

	// Если у категории нет дочерних элементов то расскрвыаем ее по нажатию на круг
	function changeIsShow(id) {
		const updatedItem = categories.map(item => {
			if (item.id === id) {
				return {...item, is_show: !item.is_show}
			} else {
				return item
			}
		})

		dispatch({type: "EDIT_CATEGORY_CATALOG_LIST", categoryCatalogList: updatedItem})
	}

	const deleteMatchCategory = async (sourceId, categoryId) => {
		let body = {"id": categoryId,  "type": currentSourceList.type}
		try {
			const deleteMatch = await api.cabinetApi.deleteMatchCategory(body)
			if (deleteMatch.status === true) {
				try {
					// Удаляем матч из каталога источников
					const updatedSourceList = categorySourceList.map(item => {
						if (item.id === deleteMatch.source_category_id) {
							return {...item, match: null, show_match: false, is_show: false};
						} else {
							return item;
						}
					})

					// Удаляем матч из каталога товаров
					const updatedCatalogList = categories.map(item => {
						if (item.id === deleteMatch.dl_category_id) {
							return {
								...item,
								match: item.match.filter(el => el.id !== deleteMatch.id),
								show_match: item.match.length !== 1,
								is_show: false
							}
						} else {
							return item;
						}
					})

					// Если открыт список товаров, то удаляем матч из списка товаров
					if (showSourceProductList) {
						let updatedProductSourceList = productSourceList.products.map(item => {
							if (deleteMatch.products?.includes(item.id)) {
								return {...item, match: {status: false}}
							} else {
								return item
							}
						})

						updatedProductSourceList = {...productSourceList, products: updatedProductSourceList}

						dispatch({type: 'CHANGE_SOURCE_PRODUCT_LIST', sourceProductList: updatedProductSourceList})
					}

					dispatch({
						type: 'EDIT_CATEGORY_SOURCE_LIST',
						categorySourceList: updatedSourceList
					})

					dispatch({
						type: 'EDIT_CATEGORY_CATALOG_LIST',
						categoryCatalogList: updatedCatalogList
					})

					Toast(deleteMatch.message, 'success');

				} catch (error) {
					console.log(error)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}



	// Показываем товары в категории
	async function showProductList(categoryId, matchId) {
		try {
			dispatch({type: 'CHANGE_CATALOG_CATEGORY_ID', currentCatalogCategoryId: categoryId})
			dispatch({type: 'CHANGE_CATALOG_CATEGORY_MATCH_ID', currentCatalogCategoryMatchId: matchId})

			const productList = await api.cabinetApi.getMatchingProductMatchList({
				"id": matchId,
				"type": currentSourceList.type
			})

			dispatch({type: 'CHANGE_CATALOG_PRODUCT_LIST', catalogProductList: productList})
			dispatch({type: 'CHANGE_SHOW_CATALOG_PRODUCT_LIST', showCatalogProductList: !showCatalogProductList})

			// Устанавливаем активным выбранный элемент
			const updatedList = categoryCatalogList.map(item => {
				if (item.id === categoryId) {
					dispatch({type: 'EDIT_CATALOG_ITEM', currentCatalogItem: categoryId})
					return {...item, is_active: true, show_match: true}
				} else {
					return {...item, is_active: false}
				}
			})

			dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedList})
		} catch (e) {
			console.log(e)
		}
	}

	return (
		<div className={`i_catalog-category-item  i_catalog-category-item-level-${level}
		     ${category.is_show ? 'active' : ''}
		     ${!category.has_children ? 'last-child' : ''}
		     ${category.show_match ? 'show-match' : ''}
		     ${category.is_active ? 'active' : ''}`}
		     style={{paddingLeft: level === 0 ? '15px' : '30px'}}
		     data-id={category.id}
		>
				<div className={`i_catalog-category-item-title ${currentCatalogItem === category.id ? 'selected' : ''}`}
			     onClick={() => {
				     !category.has_children && changeSelectedItem(category.id)
			     }}
			>
				{
					category.id !== 0 && (
						<div
							className={`i_catalog-title-circle ${!category.has_children ? '' : 'last-child'} ${currentCatalogItem === category.id ? 'active' : category.is_active ? 'active' : ''}`}
							onClick={(e) => {
								e.stopPropagation();
								category.has_children && changeIsShow(category.id)
							}}
						>
							{category.is_show && category.has_children ?
								<img src={minus} alt="-"/> : (!category.has_children ? null : <img src={plus} alt="+"/>)}
						</div>
					)
				}
				<span>{category.name}</span>

				{category.match && category.match.length > 0 &&
					<div className={'i_title-chain'}>
						<img src={`${matchChain}`} alt={''}/>
					</div>
				}
			</div>
			{category.match && category.match.length > 0 && (
				<>
					<div className={`i_catalog-category-item-match ${category.show_match ? 'show' : 'hide'}`}>
						<div className="i_match-items">
							{category.match.map((match, index) => {
								return (
									<div className="i_match-item" key={index}>
										{/*Кнопка показа товаров*/}
										{match?.products &&
											<div className={'i_match-view-product'}
											     onMouseEnter={() => dispatch({
												     type: "SWITCH_CURSOR_HELP",
												     show: true,
												     content: 'Товары'
											     })}
											     onMouseLeave={() => dispatch({
												     type: "SWITCH_CURSOR_HELP",
												     show: false
											     })}
											     onClick={() => {
												     showProductList(category.id, match.id)
											     }}
											>
												<img src={`${matchingProduct}`} alt={''}/>
											</div>
										}
										<span onClick={() => {
											openMatch(Number(match.source_category_id), true, 'source')
										}}>{match?.name}</span>
										<div className="i_match-item-delete" onClick={() => {
											deleteMatchCategory(category.id, match.id)
										}}>
											<img src={`${deleteSvg}`} alt="delete"/>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</>
			)}
			{
				category.id !== 0 && (
					subCategories.map(subCategory => (
						<Category key={subCategory.id} category={subCategory} categories={categories}/>
					))
				)
			}

		</div>
	);
};

const MatchingCatalogCategoryList = () => {
	// Находим корневые категории (parent_id === 0)
	const categoryCatalogList = useSelector(state => state.matching.categoryCatalogList);
	const rootCategories = categoryCatalogList.filter(category => category.parent_id === 0 || category.id === 0);

	return (
		<div>
			{rootCategories.map(category => (
				<Category key={category.id} category={category} categories={categoryCatalogList}/>
			))}
		</div>
	);
};

export default MatchingCatalogCategoryList



