import { API_CABINET_URL } from "../utils/config";
import multiFetch from "../utils/multiFetch";

export class OrderApi{
	// API для получения списка заказов
	// Передаём {"offset":0}
	getOrderList(body){
		return multiFetch(`${API_CABINET_URL}/order.list`, body, {})
	}

	// API для получения детальной информации о заказе
	// Передаём {"id":0}
	getOrderDetail(body){
		return multiFetch(`${API_CABINET_URL}/order.detail`, body, {})
	}
}

