import deleteSvg from '../../../../images/closeModal.svg'

import {useEffect, useState} from "react";

const MatchingCheckedProduct = ({product, viewCatalogShowProduct, handleDeleteProductMatch}) => {
	const [isOpen, setIsOpen] = useState(false)

	//Функция для изменения состояния isOpen
	const switchOpen = (viewCatalogShowProduct) => {
		setIsOpen(viewCatalogShowProduct)
	}

	//При изменении viewCatalogShowProduct меняем состояние isOpen
	useEffect(()=>{
		switchOpen(viewCatalogShowProduct)
	}, [viewCatalogShowProduct])

	return(
		<div className={`i_catalog-show-product-checked close right-row`}>
			<div className="i_catalog-show-product-head">
				<div className="i_catalog-show-product-name">
					<span>{product.name}</span>
				</div>
				<div className="i_catalog-show-product-delete" onClick={(event) => {
					handleDeleteProductMatch(event, product)
				}}>
					<img src={deleteSvg} alt="delete"/>
				</div>
			</div>
		</div>
	)
}

export default MatchingCheckedProduct;