import styles from './ExportFilesLoader.module.scss'
import {ReactComponent as LoaderSvg} from "../../../images/tube-spinner.svg";

const ExportFilesLoader = ({style, total, current}) => {
	return(
		<div className={styles.loader}>

			<div className={styles.loaderSvg}>
				<LoaderSvg />
			</div>

			<div className={styles.loaderText}>
				<span>Позиций сформировано: {current} из {total}</span>
			</div>
		</div>
	)
}

export default ExportFilesLoader;