import { ReactComponent as BellSvg } from "../../../../images/bell.svg";
import styles from './Notice.module.scss';
import { useEffect, useState } from "react";
import { api } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import Redirect from "../Redirect";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {Toast} from "../../../../utils/ui";

const Notice = () => {
	const dispatch = useDispatch();
	const noticeCount = useSelector((state) => state.notice.noticeCount); // Получаем количество уведомлений из состояния
	const [prevCount, setPrevCount] = useState(null);

	const getData = async () => {
		const response = await api.cabinetApi.checkNotification();

		// Если количество уведомлений изменилось, показываем уведомление
		if (response.count > prevCount && prevCount !== null) {
			Toast(response.message, 'success');
		}

		// Ограничиваем значение до '99+', если больше 100 и обновляем noticeCount в глобальном состоянии
		dispatch({
			type: "SET_NOTICE_COUNT",
			noticeCount: response.count,
		});

		// Обновляем локальное состояние для предыдущего количества уведомлений
		setPrevCount(response.count);
	};

	const openNoticeModal = () => {
		dispatch({ type: "OPEN_MODAL", modalType: 'noticeModal', modalLevel: 1 });
	};

	useEffect(() => {
		getData();

		// Устанавливаем интервал для получения уведомлений каждые 30 секунд
		const intervalId = setInterval(() => {
			getData();
		}, 30000);

		// Очищаем интервал при размонтировании компонента
		return () => clearInterval(intervalId);
	}, []);

	return (
		<div className={styles.notice} onClick={openNoticeModal}>
			{noticeCount !== null && noticeCount !== 0 && (
				<div className={styles.noticeCount}>{noticeCount > 100 ? '99+' : noticeCount}</div>
			)}
			<BellSvg />
		</div>
	);
};

export default Notice;
