import {api} from "../../../../api";
import {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import "../styles/distributorPage.scss"
import {useDispatch, useSelector} from "react-redux";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import {COUNTRY_CONFIG} from "../../../../utils/config";
import {Tabs} from "../../../../utils/ui";


const DistributorPage = () => {
	const [distributorList, setDistributorList] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [loadBtn, setLoadBtn] = useState(false)
	const [currentDistributor, setCurrentDistributor] = useState(null)
	const [currentTab, setCurrentTab] = useState(null)

	const siteID = useSelector(state => state.userData.data.country).toLowerCase()
	const dispatch = useDispatch()

	const changeCurrentTab = (id) => {
		setCurrentTab(id)
		getDistibutor(id, false)
	}

	const getDistibutor = async (id, load = true) => {
		if (load) setIsLoading(true)

		let body = id ? {filter: {industry: id}} : {}

		try {
			const result = await api.cabinetApi.getDistributorList(body);

			setDistributorList(result)
			if (load) setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	// const openDistributorConnectModal = () => {
	// 	dispatch({type: "SWITCH_MODAL", modalType: 'distributorConnectModal', modalIsOpen: true})
	// }

	const connectDistributor = async (id) => {
		setCurrentDistributor(id)
		setLoadBtn(true)
		try {
			const result = await api.cabinetApi.connectDistributor({distributor_id: id})

			if (result.status === true) {
				getDistibutor()
			}

		} catch (e) {
			console.log(e)
		} finally {
			setLoadBtn(false)
			setCurrentDistributor(null)
		}
	}

	useEffect(() => {
		getDistibutor()
	}, [])

	return (
		<div className={`i_distributor-page`}>
			{isLoading && (
				<>
					<div style={{display: 'flex'}}>
						<Skeleton
							count={16}
							height={'9rem'}
							width={'24%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_distributor-page-skeleton'}
						/>
					</div>
				</>
			)}

			{!isLoading && (
				<>
					<div className="i_distributor-tabs">
						<Tabs items={distributorList.industries} onChange={changeCurrentTab} currentTab={currentTab}/>
					</div>
					<div className="i_distributor-items">
						{distributorList.distributors.map((distributor, index) => {
							return (
								<div className={`i_distributor-item`} key={index}>
									<div className="i_distributor-item-title">
										<span>{distributor.name}</span>
									</div>
									<div className="i_distributor-item-industry">
										<span>{distributor.industry}</span>
									</div>
									<div className="i_distributor-item-id">
										{
											distributor.connected ?
												<>
													<span>distributor_id: {distributor.id}</span>
													<CopyToClipboardButton textToCopy={distributor.id}/>
												</>

												: false
										}
									</div>


									<div className="i_dealet-item-contact">
										<span>Контактное лицо: {distributor.contact}</span>
									</div>

									<div className="i_distributor-item-phone">
										<span>Телефон: {distributor.phone}</span>
									</div>

									<div className="i_distributor-item-web">
										<span>{COUNTRY_CONFIG[siteID].binName}: {distributor.bin}</span>
									</div>

									<div className="i_distributor-item-phone">
										<span>Страна: {distributor.country}</span>
									</div>

									<div className={'i_distributor-item-bottom'}>

										{distributor.personal_connector && (
											<div className="i_distributor-item-industry"
											     onMouseEnter={() => dispatch({
												     type: "SWITCH_CURSOR_HELP",
												     show: true,
												     content: 'Мы запросим у вас данные авторизации для настройки персонального коннектора к дистрибьютору'
											     })}
											     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
											>
												<span>Персональный коннектор</span>
											</div>
										)}

										{
											distributor.connected_request ?
												<div className={'i_distributor-item-request'}>
													<span>Заявка отправлена</span>
												</div>
												:
												!distributor.connected ?
													<div className={`i_distributor-btn`}>
														<button
															className={`button-loader ${currentDistributor === distributor.id && loadBtn ? `loader` : ''}`}
															onClick={() => {
																connectDistributor(distributor.id)
															}}><span>Подключить</span></button>
													</div>
													:
													<div className={`i_distributor-item-connected`}>
														<span>Подключен</span>
													</div>
										}
									</div>


								</div>
							);
						})}
					</div>
				</>
			)}
		</div>
	)
}

export default DistributorPage;