import { AccountApi } from "./AccountApi";
import { AuthApi } from "./AuthApi";
import { CabinetApi } from "./CabinetApi";
import { BasketApi } from "./BasketApi";
import { MarketplacesApi } from "./MarketplacesApi";
import { OrderApi } from "./OrderApi";

export class Api {
	constructor() {
		this.accountApi = new AccountApi()
		this.authApi = new AuthApi()
		this.cabinetApi = new CabinetApi()
		this.basketApi = new BasketApi()
		this.marketplacesApi = new MarketplacesApi()
		this.orderApi = new OrderApi()
	}
}

export const api = new Api()