import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CategoryListModal from "../../../pages/main/components/CategoryListModal/CategoryListModal";
import {ButtonLoader, CustomInput, Toast} from "../../../utils/ui";
import {api} from "../../../api";
import toast from "react-hot-toast";

const EditDetailAttributeProduct = () => {
	const productDetail = useSelector(state => state.catalog.list.productDetail)
	const currentCategoryID = useSelector(state => state.multiModal.modals[1].currentCategoryId)
	const {productList, offset, nextOffset, currentCategoryId} = useSelector(state => state.catalog.list.products)

	const dispatch = useDispatch()

	//Состояния для инпутов
	const [name, setName] = useState(productDetail.name)
	const [longName, setLongName] = useState(productDetail.long_name)
	const [barcode, setBarcode] = useState(productDetail.barcode)
	const [brand, setBrand] = useState(productDetail.vendor)
	const [vendorCode, setVendorCode] = useState(productDetail.vendor_code)
	const [innerCode, setInnerCode] = useState(productDetail.inner_code)

	const [btnLoad, setBtnLoad] = useState(false)

	const formItems = [
		{
			name: 'Название товара',
			value: name,
			stateFunc: setName,
			required: true,
			helpTitle: 'Тип товара, Бренд товара, модель товара, несколько наиболее важных характеристик. Например: Ноутбук Asus N53s / Экран 15" / Core i5 / 512 ОЗУ / 512 SSD',
		},
		{
			name: 'Детальное название товара',
			value: longName,
			stateFunc: setLongName,
			helpTitle: 'В детальном названии можно указать как можно больше важных характеристик товара, короткими значениями или общепринятыми сокращениями. Используйте единый подход для всех похожих товаров.',
		},
		{
			name: 'Бренд',
			value: brand,
			stateFunc: setBrand,
			helpTitle: 'Укажите название производителя товара. Используйте единое написание для всех товаров данного производителя. Например Asus или Samsung. Если вы пишите на английском, то во всех товарах указываете бренд, так-же, на английском языке.'
		},
		{
			name: 'Артикул',
			value: vendorCode,
			stateFunc: setVendorCode,
			helpTitle: 'Это код, который продавцы и производители используют для идентификации и учета своих товаров. Артикул товара чаще всего печатается на этикетке или упаковке товара.'
		},
		{
			name: 'Штрихкод',
			value: barcode,
			stateFunc: setBarcode,
			helpTitle: 'Штрих код товара — это графическое изображение, содержащее закодированные буквы и цифры. Вы можете внести через штрих код сканер или вручную, под линиями штрих кода располагаются цифры, дублирующие информацию. Если штрих кодом является QR код, вы можете считать его своим смартфоном и внести полученное значение.'
		},
		{
			name: 'Внутренний код',
			value: innerCode,
			stateFunc: setInnerCode,
			helpTitle: 'Это ваш внутренний код. Он может быть любым, на ваше усмотрение. Данный код служит идентификатором данного товара в вашей базе товаров и по нему можно сверять товар в вашей базе и товар в базе Apicore.'
		},
	]

	// Отправляем форму для изменения атррибутов товара

	async function formSubmit() {
		setBtnLoad(true)

		const data = {
			id: productDetail.id,
			name: name,
			long_name: longName,
			barcode: barcode,
			vendor: brand,
			inner_code: innerCode,
			category_id: currentCategoryID,
			vendor_code: vendorCode
		}

		try{
			const response = await api.cabinetApi.updateCatalogProduct(data)
			const updatedProductDetail = await api.cabinetApi.getCatalogProductDetail({id: productDetail.id})

			if(response.status === true){

				// Обновляем детальную информацию о товаре
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail.product})
				Toast(response.message, 'success');

				dispatch({type: "CLOSE_MODAL", modalLevel: 2})

				//Обновляем список товаров в каталоге, чтобы изменения отобразились
				const updatedProductList = productList.map((item) => {
					if(item.id === productDetail.id){
						return {...item,
							name:name,
							long_name: longName,
							barcode: barcode,
							vendor: brand,
							inner_code: innerCode,
							category_id: currentCategoryID,
							vendor_code: vendorCode
						}
					}else{
						return item;
					}
				})
				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedProductList, nextOffset: nextOffset, offset: offset, currentCategoryId: currentCategoryId})
			}else{
				Toast(response.message, 'error');
			}

		}catch (e){
			console.log(e)
		}
		setBtnLoad(false)
	}


	return (
		<>
			{/* Инпуты */}
			{formItems.map((item, index) => {
				return (
					<CustomInput title={item.name} value={item.value} onChange={(e) => {
						item.stateFunc(e)
					}} showHelp={item.showHelp} helpTitle={item.helpTitle} required={item.required} />
				)
			})}

			{/*	Список категорий */}
			<CategoryListModal showSearch={true} closed={true} modalLevel={2}/>

			{/*Кнопка*/}
			<ButtonLoader title={'Сохранить'} load={btnLoad} onClick={formSubmit}/>
		</>
	)
}

export default EditDetailAttributeProduct;