import styles from './NavigateButton.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {ReactComponent as CategorySvg} from "../../../images/catalogs/category.svg"
import {ReactComponent as MarkupsSvg} from "../../../images/catalogs/markup.svg"

const NavigateButton = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [link, setLink] = useState('');

	const currentTools = useSelector(state => state.multiTools.currentTools)
	const catalogCode = useSelector(state => state.catalog.catalogCode)
	const markupsCode = useSelector(state => state.markups.catalogCode)

	useEffect(() => {
		switch (currentTools) {
			case 'catalogs-my-inner':
				setLink(`/catalogs/my/${catalogCode}/markups/`)
				break;
			case 'markups-my-catalog':
				setLink(`/catalogs/my/${markupsCode}/`)
				break;
			default:
				break;
		}
	}, [])

	function openPage(event) {
		event.stopPropagation()
		dispatch({type: "SWITCH_CURSOR_HELP", show: false})
		navigate(link);
	}

	return (
		<div className={`${styles.navigateBtn}`} onClick={(event) => {
			openPage(event)
		}}
	     onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: currentTools === 'markups-my-catalog' ? 'Товары каталога' : 'Настройка наценок'})}
	     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}>
			<div className={`${styles.navigateBtnSvg} ${currentTools === 'markups-my-catalog' ? styles.categorySvg : styles.markupsSvg}`}>
				{currentTools === 'markups-my-catalog' ? <CategorySvg /> : <MarkupsSvg />}
			</div>
		</div>
	)
}

export default NavigateButton;