import styles from './BasketLoader.module.scss'
import {ReactComponent as Loader} from "../../../images/tube-spinner.svg";

const BasketLoader = ({height}) => {
	return(
		<div className={styles.basketLoader} style={{height : height}}>
			<Loader />
		</div>
	)
}

export default BasketLoader;