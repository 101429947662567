import deleteSvg from '../../../../images/closeModal.svg'
import matchChain from '../../../../images/match-chain.svg'

import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

const MatchingCheckedProduct = ({product, viewSourceShowProduct, handleDeleteProductMatch}) => {

	const [isOpen, setIsOpen] = useState(false)
	const [maxHeight, setMaxHeight] = useState(0)
	const catalogHead = useRef(null);

	const dispatch = useDispatch()
	const categoryCatalogList = useSelector(state => state.matching.categoryCatalogList);

	useEffect(()=>{
		setTimeout(()=>{
			setMaxHeight(catalogHead.current.offsetHeight);
		}, 200)
	}, [isOpen])

	//Открываем или закрываем блок с информацией о товаре
	const handleOpen = (e) => {
		setIsOpen(!isOpen)
	}

	//Функция для изменения состояния isOpen
	const switchOpen = (viewSourceShowProduct) => {
		setIsOpen(viewSourceShowProduct)
	}

	// Открываем раздел в моих каталогах
	function openMatch(event,matchId) {
		event.stopPropagation()

		let updatedCatalogList = categoryCatalogList;

		function openParentItem(id) {
			const parentItem = updatedCatalogList.find(item => item.id === id);
			if (parentItem && parentItem.parent_id !== 0) {
				openParentItem(parentItem.parent_id);
			}
			if (parentItem) {
				parentItem.is_show = true;
			}
		}

		updatedCatalogList = updatedCatalogList.map(item => {
			if (item.id === matchId) {
				if (item.parent_id !== 0) {
					openParentItem(item.parent_id);
				}
				dispatch({type: 'EDIT_CATALOG_ITEM', currentCatalogItem: item.id})

				// Прокручиваем каталог к целевому элементу
				setTimeout(() => {
					const catalogCatalogItemsBlock = document.querySelector('.i_matching-right .i_catalog-category-items')
					const catalogCatalogItems = catalogCatalogItemsBlock.querySelector('.i_catalog-category-item[data-id="' + matchId + '"]')
					catalogCatalogItems.scrollIntoView({block: "center", behavior: "smooth"})
				}, 100)

				return {
					...item,
					is_active: true,
					show_match: true,
				};
			} else {
				if (item.has_children) {
					return item
				} else {
					return {
						...item,
						is_active: false,
						is_show: false
					};
				}
			}
		});

		dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedCatalogList})
	}

	//При изменении viewCatalogShowProduct меняем состояние isOpen
	useEffect(()=>{
		switchOpen(viewSourceShowProduct)
	}, [viewSourceShowProduct])

	return(
		<div className={`i_catalog-show-product-checked ${isOpen ? 'open' : 'close'}`} onClick={handleOpen} style={{maxHeight: !isOpen ? maxHeight + 14 : 500}}>
			<div className="i_catalog-show-product-head" ref={catalogHead}>
				<div className={'i_catalog-show-product-chain'}>
					<img src={matchChain} alt="chain"/>
				</div>
				<div className="i_catalog-show-product-name">
					<span>{product.name}</span>
				</div>
			</div>
			<div className="i_catalog-show-product-body">
				<div className={`i_catalog-show-product-item`} onClick={(event)=>{openMatch(event, product.match.category_id)}}>
					<span>{product.match.category_name}</span>
					<div className="i_catalog-show-product-delete" onClick={(event)=>{handleDeleteProductMatch(event,product)}}>
						<img src={deleteSvg} alt="delete"/>
					</div>
				</div>
			</div>
		</div>
	)
 }

export default MatchingCheckedProduct;