const initialState = {
	data : {
		status: false,
		authorized: false,
		user:{
			id: null,
			login: null,
		},
		entity:{
			type: null,
			id: null,
			name: null,
			hash: null,
			country: null
		},
		country: null
	},
}

export const userDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_USER_DATA" :
			return {
				...state,
				data: action.userData
			}

		case "SET_USER_AUTH" :
			return {
				...state,
				data: {
					...state.data,
					authorized: action.authorized
				}
			}
		default:
			return state;
	}
}