import { API_CABINET_URL } from "../utils/config";
import multiFetch from "../utils/multiFetch";

export class MarketplacesApi{

	// API для подключения к маркетплейсу
	addMarketplaceConnect(body){
		return multiFetch(`${API_CABINET_URL}/marketplace.connection.add`, body, {});
	}

	// API для изменения подключения к маркетплейсу
	editMarketplaceConnect(body){
		return multiFetch(`${API_CABINET_URL}/marketplace.connection.edit`, body, {});
	}

	//API для получения списка маркетплейсов
	//Передаём пустой BODY
	getMarketplaceList(){
		return multiFetch(`${API_CABINET_URL}/marketplace.list`, {}, {});
	}

	// API для удаления подключения к маркетплейсу
	// BODY Отправления {id: 1}
	deleteMarketplaceConnection(body){
		return multiFetch(`${API_CABINET_URL}/marketplace.connection.delete`, body, {});
	}

	// API для активации\деактивации записи
	// BODY Отправления {id: 1, is_active: true/false}
	setMarketplaceConnectionState(body){
		return multiFetch(`${API_CABINET_URL}/marketplace.connection.state`, body, {});
	}

}

