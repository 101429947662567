import React, {useEffect, useState} from 'react';
import styles from './SelectInput.module.scss';
import {useDispatch} from "react-redux";
import {ReactComponent as HelpIcon} from "../../../../images/info.svg";

const SelectInput = ({ value, title, checkBoxValue, inputChecked, onlyOne, disabled,  helpTitle, helpTitleIsHTML, showHelpTitleInMobile }) => {
	const [isChecked, setIsChecked] = useState(!!inputChecked);
	const dispatch = useDispatch();

	useEffect(() => {
		setIsChecked(!!inputChecked);
	}, [inputChecked]);

	const handleInputChange = () => {
		if (onlyOne) {
			// Передаем ID активного чекбокса в родительский компонент
			checkBoxValue(value);
		} else {
			// Обновляем состояние isChecked при изменении чекбокса
			const newCheckedValue = !isChecked;
			setIsChecked(newCheckedValue);

			// Если новое значение isChecked равно true, выводим значение в консоль
			checkBoxValue({ value: value, isChecked: newCheckedValue });
		}
	};

	return (
		<>
			<label className={`${styles.iSelectContain} ${disabled ? styles.disabled : ''}`}>
				<input
					type="checkbox"
					value={value}
					checked={inputChecked}
					onChange={handleInputChange}
				/>
				<div className={styles.iSelectInput}></div>
				{title && (
					<span>{title}</span>
				)}

				{helpTitle && (
					<div
						className={styles.inputHelpIcon}
						onMouseEnter={() =>
							dispatch({
								type: "SWITCH_CURSOR_HELP",
								show: true,
								content: helpTitle,
								isHTML: helpTitleIsHTML,
								showInMobile: showHelpTitleInMobile,
							})
						}
						onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false, isHTML: false, showInMobile: false})}
					>
						<HelpIcon/>
					</div>
				)}
			</label>
		</>
	);
};

export default SelectInput;
