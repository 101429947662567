import infoImg from "../../../images/info.svg";
import styles from './CategoryList.module.scss'
import CatalogTools from "../../../pages/components/CatalogTools";
import OpenCatalogs from "../../../pages/main/components/OpenCatalogs/OpenCatalogs";
import CloseCatalogs from "../../../pages/main/components/CloseCatalogs/CloseCatalogs";
import {ScrollBox, SelectCategoryList} from "../../../utils/ui";
import {useSelector} from "react-redux";

const SelectCategoryListWrapper = () => {

	const markupsStore = useSelector(state => state.markups);

	return (
		<div className={`${styles.catalogCategory}`}>
			<div className={styles.catalogTools}>
				<div className={styles.catalogToolsTight}>
					<OpenCatalogs store={markupsStore}/>
					<CloseCatalogs store={markupsStore}/>
				</div>
			</div>
			<div className={styles.catalogCategoryItems}>
				{
					markupsStore.categoryList.length > 0 && (
						<ScrollBox>
							<SelectCategoryList store={markupsStore}/>
						</ScrollBox>
					)
				}
				{
					markupsStore.categoryList.length === 0 && (
						<div className="i_catalog-category-empty">
							<div className="i_catalog-page-empty-title">
								<img src={infoImg} alt=""/>
								<span>Категории отсутствуют.</span>
							</div>
							<span>Вы можете добавить категории через&nbsp;
								<a href="https://docs.apicore.one/api/dealer/"
								   target="_blank">Dealer API</a>
							</span>
						</div>
					)
				}
			</div>
		</div>
	)
}

export default SelectCategoryListWrapper;