import styles from './CurrencyRate.module.scss'
import {Button} from "../../../../utils/ui";

const CurrencyRate = () => {
	return (
		<div className={styles.currencyRate}>
			<div className={styles.currencyRateTitle}>
				<span>Конвертация валют</span>
			</div>
			<div className={styles.currencyRateSubtitle}>
				<span>По умолчанию конвертация проходит по курсу Национального Банка РК</span>
			</div>

			{/*<Button title={'Выставить курс вручную'} />*/}

		</div>
	)
}

export default CurrencyRate;