import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";

const RedirectToCatalog = ({title}) => {

	const dispatch = useDispatch();

	function closeModal(){
		dispatch({type: "CLOSE_MODAL", modalLevel: 1})
	}

	return(
		<div>
			<span>Вы можете <Link to={'/catalogs/my/'} onClick={closeModal}>добавить новый каталог.</Link></span>
		</div>
	)
}

export default RedirectToCatalog;