import {useEffect, useState} from "react";
import styles from './Order.module.scss'
import {ButtonLoader, CustomInput, Toast} from "../../../../utils/ui";
import RadioButton from "../../../../utils/ui/Inputs/RadioButton/RadioButton";
import SelectDropdown from "../SelectDropdown";
import {api} from "../../../../api";
import {useDispatch, useSelector} from "react-redux";
import {useDeleteBasketItem} from "../../../../hooks";
const Order = ({open}) => {

	const [fio, setFio] = useState('')
	const [phone, setPhone] = useState('')
	const [email, setEmail] = useState('')
	const [address, setAddress] = useState('')
	const [comment, setComment] = useState('')
	const [company, setCompany] = useState('')
	const [personal, setPersonal] = useState('')
	const [billingDetail, setBillingDetail] = useState('')
	const [selectedOrderType, setSelectedOrderType] = useState('client')
	const [selectedPayerValue, setSelectedPayerValue] = useState('individual')
	const [selectedPayerName, setSelectedPayerName] = useState('Физическое лицо')

	const dispatch = useDispatch();

	const productList = useSelector(state => state.catalog.list.products.productList);
	const nextOffset = useSelector(state => state.catalog.list.products.nextOffset);
	const currentCategoryId = useSelector(state => state.catalog.list.products.currentCategoryId);
	const productDetail = useSelector(state => state.catalog.list.productDetail);

	const formIndividualData = [
		{
			name: 'ФИО клиента', value: fio, stateFunc: setFio, className: styles.orderItemName, req: true
		},
		{
			name: 'Телефон клиента', value: phone, stateFunc: setPhone, className: styles.orderItemPhone, isMobile: true
		},
		{
			name: 'Email клиента', value: email, stateFunc: setEmail, className: styles.orderItemEmail
		},
		{
			name: 'Адрес доставки', value: address, stateFunc: setAddress, className: styles.orderItemAddress
		},
		{
			name: 'Комментарий к заказу', value: comment, stateFunc: setComment, isTextarea: true, className: styles.orderItemComment
		}
	]

	const formLegalData = [
		{
			name: 'Название компании', value: company, stateFunc: setCompany, className: styles.orderItemName, req: true
		},
		{
			name: 'Реквизиты компании', value: billingDetail, stateFunc: setBillingDetail, className: styles.orderItemPhone,
		},
		{
			name: 'ФИО контактного лица', value: fio, stateFunc: setFio, className: styles.orderItemEmail
		},
		{
			name: 'Телефон', value: phone, stateFunc: setPhone, className: styles.orderItemPhone, isMobile: true
		},
		{
			name: 'Email', value: email, stateFunc: setEmail, className: styles.orderItemEmail
		},
		{
			name: 'Адрес доставки', value: address, stateFunc: setAddress,className: styles.orderItemAddress
		},
		{
			name: 'Комментарий к заказу', value: comment, stateFunc: setComment, isTextarea: true, className: styles.orderItemComment
		}
	]

	const formSelfData = [
		{
			name: 'Название компании', value: company, stateFunc: setCompany, className: styles.orderItemName, disabled: true
		},
		{
			name: 'Контактное лицо', value: personal, stateFunc: setPersonal, className: styles.orderItemPhone, disabled: true
		},
		{
			name: 'Телефон', value: phone, stateFunc: setPhone, className: styles.orderItemPhone, isMobile: true, disabled: true
		},
		{
			name: 'Контактный email', value: email, stateFunc: setEmail, className: styles.orderItemEmail, disabled: true
		},
		{
			name: 'Комментарий', value: comment, stateFunc: setComment, isTextarea: true, className: styles.orderItemComment
		}
	]

	//Если выбран тип заказа "Моя компания", то подставляем данные из профиля
	async function handleChangeFormData(){
		try{
			const {dealers} = await api.cabinetApi.getProfile();

			if(dealers){
				setCompany(dealers.name)
				setPersonal(dealers.contact)
				setPhone(dealers.phone)
				setEmail(dealers.email)
			}

		}catch (e) {
			console.log(e)
		}
	}

	function handleClearFormData(){
		setFio('')
		setPhone('')
		setEmail('')
		setAddress('')
		setComment('')
		setCompany('')
		setPersonal('')
		setBillingDetail('')
	}

	async function orderCreate(){
		try{
			let body = {}

			if(selectedOrderType === 'client'){
				if(selectedPayerValue === 'individual') {
					body = {
						customer: {
							name: fio,
							phone: phone,
							email: email,
							address: address
						}
					}
				}else{
					body = {
						customer: {
							company_name: company,
							company_details: billingDetail,
							name: fio,
							phone: phone,
							email: email,
							address: address
						}
					}
				}

				body.customer_type = selectedPayerValue
			}else{
				body.customer_type = selectedOrderType
			}

			body.comment = comment

			const response = await api.basketApi.createOrder(body)

			if(response.status === true){
				Toast(response.message, 'success')
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})

				const updatedBasketCount = await api.basketApi.getBasketCount();

				const updatedProductList = productList.map((item) =>
					response?.basket_attach?.product_id.includes(item.id)
						? { ...item, basket_quantity: 0 }
						: item
				);

				const updatedProductDetail = { ...productDetail, basket_quantity: 0 };

				// Обновляем стейт
				dispatch({type: "SET_BASKET_COUNT", basketCount: updatedBasketCount.count});
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail});
				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedProductList, nextOffset, currentCategoryId});
			}else{
				Toast(response.message, 'error')
			}
		}catch(e){
			console.log(e)
		}
	}


	useEffect(() => {
		if(selectedOrderType === 'self'){
			handleChangeFormData()
		}

		handleClearFormData()

	}, [selectedOrderType]);

	function handleChangeRadio(e){
		setSelectedOrderType(e.value)
		setSelectedPayerValue('individual')
	}

	function handleChangePayer(e){
		setSelectedPayerValue(e.value)
	}

	const payerItems = [
		{name: 'Физическое лицо', value: 'individual'},
		{name: 'Юридическое лицо', value: 'legal'}
	]

	return(
		<div className={`${styles.order} ${open ? styles.open : styles.close}`}>
			<div className={styles.orderTitle}>Оформление заказа</div>
			<div className={styles.orderType}>
				<div className={styles.orderTypeTitle}>
					<span>Получатель заказа: </span>
				</div>
				<div className={styles.orderTypeItems}>
					<RadioButton title={'Внешний клиент'} value={'client'} radioValue={handleChangeRadio} inputChecked={selectedOrderType === 'client'}/>
					<RadioButton title={'Моя компания'} value={'self'} radioValue={handleChangeRadio} inputChecked={selectedOrderType === 'self'}/>
				</div>
			</div>
			<div className={`${styles.orderItems}`}>
				{
					selectedOrderType === 'self'
						?
						formSelfData.map((item, index) => {
							return(
								<CustomInput title={item.name} value={item.value} onChange={(e) => {item.stateFunc(e)}} isTextarea={item.isTextarea} className={item.className} key={index} isMobile={item.isMobile}
									disabled={item.disabled}
								/>
							)
						})
						:
						<>
							<SelectDropdown label={'Тип плательщика'} item={payerItems} defaultValue={selectedPayerName} style={{width: '100%'}} onChange={handleChangePayer}/>
							{
								selectedPayerValue === 'individual'
								?
									formIndividualData.map((item, index) => {
										return(
											<CustomInput title={item.name} value={item.value} onChange={(e) => {item.stateFunc(e)}} isTextarea={item.isTextarea} className={item.className} key={index} isMobile={item.isMobile}
												required={item.req}
											/>
										)
									})
									:
									formLegalData.map((item, index) => {
										return(
											<CustomInput title={item.name} value={item.value} onChange={(e) => {item.stateFunc(e)}} isTextarea={item.isTextarea} className={item.className} key={index} isMobile={item.isMobile}
					              required={item.req}
											/>
										)
									})
							}
						</>
				}
			</div>

			<div className={styles.orderButton}>
				<ButtonLoader title={'Оформить заказ'} onClick={orderCreate}/>
			</div>
		</div>
	)
}

export default Order;