import '../styles/ordersPage.scss'
import {api} from "../../../../api";
import {useEffect, useRef, useState} from "react";
import {OrderListHead, OrderListItem} from "../../../../utils/ui";
import {useDispatch, useSelector} from "react-redux";
import Cookies from "js-cookie";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import {useHref} from "react-router-dom";

const OrdersPage = () => {

	const dispatch = useDispatch()
	const orderBlockWidth = useRef(null)
	const windowWidth = useWindowWidth();
	const showSideBar = useSelector(state => state.profile.showSideBar);

	const getData = async () => {
		try{
			const response = await api.orderApi.getOrderList({offset: 0})

			dispatch({type: "SET_ORDER_LIST_COLUMNS", columns: response.columns})
			dispatch({type: "SET_ORDER_LIST", orderList: response.orders, nextOffset: response.next_offset})

		}	catch (e) {
			console.log(e)
		}
	}

	useEffect(()=>{
		const cookieData = Cookies.get(`columns_order`);

		dispatch({type: 'SET_ORDER_LIST_TABLE_MIN_WIDTH', tableMinWidth: orderBlockWidth.current.offsetWidth})

		if (cookieData) {
			const {columns, tableWidth} = JSON.parse(cookieData);
			const updatedColumns = JSON.parse(columns);

			if (updatedColumns && tableWidth) {
				dispatch({type: 'SET_ORDER_LIST_TABLE_ITEM_WIDTH', tableItemWidth: updatedColumns || null})
				dispatch({type: 'SET_ORDER_LIST_TABLE_WIDTH', tableWidth: tableWidth || null})
			}
		}
		getData()
	},[])

	// Считаем минимальную ширину таблицы , если ширина таблицы оказалась меньше чем основной блок то устанавливаем ширину основного блока
	useEffect(() => {
		let mainWidth = windowWidth - 70;
		if (windowWidth > 1300 && showSideBar) {
			mainWidth = windowWidth - 290;
		}
		mainWidth = mainWidth - 20;

		if(orderBlockWidth.current.offsetWidth > mainWidth){
			dispatch({type: 'SET_ORDER_LIST_TABLE_MIN_WIDTH', tableMinWidth: orderBlockWidth.current.offsetWidth})
		}else{
			dispatch({type: 'SET_ORDER_LIST_TABLE_MIN_WIDTH', tableMinWidth: mainWidth})
		}
	}, [orderBlockWidth?.current?.offsetWidth, showSideBar]);

	const columns = useSelector(state => state.order.columns)
	const orderList = useSelector(state => state.order.orderList)
	const store = useSelector(state => state.order)

	return(
		<div className={'i_orders'} ref={orderBlockWidth}>
			{columns.length > 0 &&
				<>
					<OrderListHead columns={columns} actionType={'order'} store={store}/>
					<OrderListItem columns={columns} store={store} actionType={'order'} orderList={orderList}/>
				</>
			}
		</div>
	)
}

export default OrdersPage;