import styles from './ExportCatalogModal.module.scss'
import SelectDropdown from "../../pages/main/components/SelectDropdown";
import {useCallback, useEffect, useState} from "react";
import {api} from "../../api";
import {ButtonLoader, EmptyBlock, ExportFilesLoader, ScrollBox, Toast} from "../../utils/ui";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";

const ExportCatalogModal = () => {

	const [filesTypeData, setFilesTypeData] = useState([])
	const [selectedFormat, setSelectedFormat] = useState('')
	const [filesList, setFilesList] = useState([])
	const [loader, setLoader] = useState(false)
	const [exportTotal, setExportTotal] = useState(0)
	const [exportCurrent, setExportCurrent] = useState(0)
	const [catalogType, setCatalogType] = useState('')

	const catalogCode = useSelector(state => state.catalog.catalogCode)
	const currentTools = useSelector(state => state.multiTools.currentTools)

	const dispatch = useDispatch();

	//Получаем данные с сервера
	const getData = async () => {
		try {
			const response = await api.cabinetApi.getExportFilesTypeList({catalog_type: catalogType})
			const exportFilesList = await api.cabinetApi.getExportFilesList({catalog_code: catalogCode})

			setFilesTypeData(response.types)
			setSelectedFormat(response.types[0].type)
			setFilesList(exportFilesList.export_files)

			if (exportFilesList.export_files.length === 0 || exportFilesList.export_files[0].status === 'ready' || exportFilesList.export_files[0].status === 'error') {
				setLoader(false);
			} else {
				checkExportFilesStatus(exportFilesList.export_files[0].id);
			}

		} catch (e) {
			console.log(e)
		}
	}

	const runExportFiles = async () => {
		try {
			const response = await api.cabinetApi.runExportFiles({
				type: selectedFormat,
				catalog_code: catalogCode,
				catalog_type: catalogType
			})
			const exportFilesList = await api.cabinetApi.getExportFilesList({catalog_code: catalogCode})

			setFilesList(exportFilesList.export_files)

			if (response.status === true) {
				checkExportFilesStatus(response.id)
				Toast(response.message, 'success');
			} else {
				Toast(response.message, 'error');
			}

		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		// Устанавливаем тип каталога
		const catalogMapping = {
			'catalogs-my-inner': 'dealer',
			'catalogs-distributor-inner': 'distributor',
			'catalogs-source-inner': 'source',
		};
		setCatalogType(catalogMapping[currentTools] || 'dealer'); // Устанавливаем тип каталога

		getData();
	}, []);

	function formatChange(item) {
		setSelectedFormat(item.type)
	}

	async function checkExportFilesStatus(id) {
		setLoader(true);

		// Флаг для управления интервалом
		let isChecking = false;
		let intervalId;

		const checkStatus = async () => {
			// Если уже выполняется запрос, то пропускаем новый
			if (isChecking) return;
			isChecking = true;

			try {
				const response = await api.cabinetApi.checkExportFileStatus({id: id});
				setExportTotal(response.total);
				setExportCurrent(response.current);

				// Если файл готов, останавливаем интервал
				if (response.is_ready || response.is_error) {
					clearInterval(intervalId);
					setLoader(false);
					const exportFilesList = await api.cabinetApi.getExportFilesList({catalog_code: catalogCode})

					setFilesList(exportFilesList.export_files)
				}
			} catch (error) {
				console.error(error);
				clearInterval(intervalId); // Останавливаем интервал при ошибке
				setLoader(false);
			} finally {
				isChecking = false; // Сбрасываем флаг после выполнения
			}
		};
		// Первый запрос отправляется сразу
		await checkStatus();

		// Запуск интервала с проверкой каждые 10 секунд
		intervalId = setInterval(checkStatus, 3000);
		dispatch({type: "SET_MODAL_DATA", modalLevel: 1, modalData: {intervalId: intervalId}});

	}

	return (
		<div className={styles.modal}>
			<div className={styles.modalTitle}>
				<span>Экспорт каталога</span>
			</div>

			<ScrollBox>
				<div className={styles.exportBlock}>
					{loader && <ExportFilesLoader total={exportTotal} current={exportCurrent}/>}
					<div className={styles.modalContent}>
						<div className={styles.modalContentItem}>
							<SelectDropdown item={filesTypeData} defaultValue={filesTypeData[0]?.name} onChange={formatChange}
							                label={'Выберите формат'}/>
						</div>
					</div>
					<div className={styles.modalButton}>
						<ButtonLoader title={'Сформировать'} onClick={runExportFiles}/>
					</div>
				</div>

				{
					filesList?.length === 0 ? <EmptyBlock title={'Список файлов отсутствует'}/>
						:
						<div className={styles.files}>
							<div className={styles.filesTitle}>
								<span>Сформированные файлы</span>
							</div>

							<div className={styles.filesList}>
								<ScrollBox style={{
									padding: "0 15px 0 0", display: "flex", flexDirection: "column", maxHeight: 550
								}}>
									{filesList.map((item, index) => {
										return (
											<div className={styles.file}>
												<div className={styles.fileLeft}>
													<div className={styles.fileName}>
														<span>{item.type}</span>
													</div>

													<div className={styles.fileData}>
														<span>Доступен до: </span>
														<span>{item.expired_at}</span>
													</div>
												</div>

												<div className={styles.fileRight}>
													<div className={`${styles.fileBtn} ${!item.is_ready ? styles.disabled : ''}`}>
														{
															item.is_ready ? <ButtonLoader title={'Скачать'} href={item.url}
															                              style={{fontSize: '0.75rem', height: '35px'}}/> :
																<span>{item.status}</span>
														}
													</div>
												</div>
											</div>
										)
									})}
								</ScrollBox>
							</div>
						</div>
				}
			</ScrollBox>
		</div>
	)
}

export default ExportCatalogModal;