import styles from './MarketplaceConnectModal.module.scss';

import {ScrollBox} from "../../utils/ui";
import {useDispatch, useSelector} from "react-redux";
import KaspiKzConnect from "./marketplaces/KaspiKzConnect";

const MarketplaceConnectModal = () => {
	// Состояния для инпутов
	const {marketCode, marketName} = useSelector(state => state.multiModal.modals[0].modalData);

	return (
		<div className={styles.modal}>
			<div className={styles.modalTitle}>
				<span>Подключение {marketName}</span>
			</div>
			<ScrollBox style={{display: 'flex', flexDirection: 'column', gap: 15}}>
				{
					marketCode === 'kaspikz' && <KaspiKzConnect />
				}
			</ScrollBox>
		</div>
	);
};

export default MarketplaceConnectModal;
