import {useState} from "react";
import {useTranslation} from "react-i18next";
import {api} from "../api";
import {useDispatch} from "react-redux";
import {ButtonLoader, CustomInput} from "../utils/ui";
import {useHref} from "react-router-dom";

const AddCatalogModal = () =>{
	const dispatch = useDispatch();

	//Состояния для инпутов
	const [name, setName] = useState('')
	const [code, setCode] = useState('')

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')

	const url = useHref();

	//Нужно для перевода
	const {t} = useTranslation();

	const formItems =
		[
			{
				name: 'Название внешнего источника',
				value: name,
				stateFunc: setName
			},
			{
				name: 'Код внешнего источника',
				value: code,
				stateFunc: setCode
			}
		]

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		//Создаем тело запроса
		let body = {
			"code":code,
			"name":name
		}

		try {
			const result = await api.cabinetApi.addSourceList(body);

			if(result.status === true) {
				const SourceCatalogList = await api.cabinetApi.getSourceList();
				dispatch({type: "REFRESH_SOURCE_CATALOG_LIST", catalogList: SourceCatalogList.sources})
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})


				//Если мы находимся на странице матчинга, то перезагружаем страницу
				if(url === '/matching/'){
					window.location.reload();
				}

			}else{
				setError(result.message)
			}

		} catch (error) {

			console.log(error);

		}finally {
			setTimeout(() => {
				setLoad(false)
			},700)

		}
	}

	return(
		<div className={'i_add-catalog-modal'}>
			<div className="i_add-catalog-modal-title">
				<span>Новый внешний источник</span>
			</div>
			<div className="i_add-catalog-modal-items">
			{
				formItems.map((item, index) => {
					return (
						<div className={`i_add-catalog-modal-item`} key={index}>
							<CustomInput title={item.name} onChange={(e) => {item.stateFunc(e)}}/>
						</div>
					)
				})
			}

			</div>

			<div className="i_add-catalog-modal-btn" onClick={formSubmit}>
				<ButtonLoader load={load} title={t('I_SELECT_ROLE_FORM_BTN')}/>
			</div>

			{error !== '' ?
			<div className={`i_add-catalog-modal-error`}>
				<span>{error}</span>
			</div> : ''}
		</div>
	)
}

export default AddCatalogModal