import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {api} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoader, CustomInput, SwitchButton, SelectButton, DateTimePicker, Toast} from "../../utils/ui";
import Skeleton from "react-loading-skeleton";
import SelectDropdown from "../../pages/main/components/SelectDropdown";
import SelectMarkupUnit from "./SelectMarkupUnit/SelectMarkupUnit";
import toast from "react-hot-toast";

const EditMarkupElement = () => {
	// Redux методы
	const dispatch = useDispatch();
	const catalogCode = useSelector(state => state.markups.catalogCode)
	const selectedProducts = useSelector(state => state.markups.selectedProduct)
	const selectedCategory = useSelector(state => state.markups.selectedCategory)
	const modalData = useSelector(state => state.multiModal.modals[0].modalData.item)
	const markupsProductList = useSelector(state => state.markups.list.products)
	const catalogCurrency = useSelector(state => state.markups.catalogCurrency)

	//Состояния для инпутов
	const [name, setName] = useState(modalData.original.name)
	const [active, setActive] = useState(modalData.original.active)
	const [selectPeriod, setSelectPeriod] = useState()
	const [activeFrom, setActiveFrom] = useState(modalData.original.date_from)
	const [activeTo, setActiveTo] = useState(modalData.original.date_to)
	const [selectMarkupUnit, setSelectMarkupUnit] = useState(modalData.original.markup_unit)
	const [markupValue, setMarkupValue] = useState(modalData.original.markup)
	const [priorityValue, setPriorityValue] = useState(modalData.original.priority)
	const [conditionValue, setConditionValue] = useState(null)

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false);

	//Нужно для перевода
	const {t} = useTranslation();

	useEffect(() => {
		console.log(activeFrom, activeTo)
	}, [activeFrom, activeTo]);

	// Меняем Show из switchCheckbox
	const checkboxHandler = (data) => {
		setActive(data.isChecked)
	}

	const handleSelectMarkupUnit = (data) => {
		setSelectMarkupUnit(data.type)
		setMarkupValue(data.value)
	}

	// Открытие модального окна выбора категорий и товаров
	const openSelectCategoryAndProductModal = () => {
		dispatch({type: "SET_MARKUPS_CURRENT_CATEGORY_ID", currentCategoryId: 0})
		dispatch({type: "OPEN_MODAL", modalType: 'selectCategoryAndProductModal', modalLevel: 2, modalWidth: 'large'})
	}

	const priorityHelpTitle = `Чем больше цифра приоритета тем выше важность наценки. <br>
																		Например, наценка с приоритетом 7 важнее наценки с приоритетом 5.
																		<br><br>
																		Исключение: <br>
																		Цена продажи товаров из ваших Источников транслируются напрямую и не подлежат наценке.`


	const periodData = [
		{
			name: "Неограниченно",
			value: 'unlimited'
		},
		{
			name: "Период",
			value: 'limited'
		}
	]


	const conditionsData = [
		{
			name: "Весь каталог",
			value: 'all'
		},
		{
			name: "Категории и товары",
			value: 'categoryAndProduct'
		}
	]


	const changeCurrentPeriod = ({value}) => {
		setSelectPeriod(value)
	}

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		let body = {
			'id': modalData.original.id,
			"catalog_code": catalogCode,
			"active": active,
			"name": name,
			"markup": markupValue,
			"markup_unit": selectMarkupUnit === '%' ? selectMarkupUnit : catalogCurrency,
			"priority": Number(priorityValue),
			"segmentation": {"dealer": "default", "distributor": "default"},
		};

		// Проверка условия наценки
		if (conditionValue === 'all') {
			body.condition = {"catalog_all": true}
		} else {
			if (selectedProducts.length > 0 || selectedCategory.length > 0) {
				body.condition = {
					"categories": selectedCategory,
					"products": selectedProducts
				}
			} else {
				body.condition = {"catalog_all": true}
			}
		}

		// Если период активности ограничен, добавляем даты, иначе оставляем пустыми
		if (selectPeriod === 'limited') {
			body.date_from = activeFrom
			body.date_to = activeTo
		} else {
			body.date_from = ''
			body.date_to = ''
		}

		// Делаем запрос
		try {
			const response = await api.cabinetApi.updateCatalogMarkup(body);

			if (response.status === true) {
				// Обновляем детальную информацию о товаре

				Toast(response.message, 'success');

				dispatch({type: "CLOSE_MODAL", modalLevel: 1})

				// Обновляем список наценок
				const updatedMarkupsProductList = markupsProductList.map((item) => {
					if (item.id === modalData.original.id) {
						return response.markups[0]
					} else {
						return item
					}
				})

				dispatch({type: "SET_MARKUPS_LIST_PRODUCTS", products: updatedMarkupsProductList})
			} else {
				Toast(response.message, 'error');
			}

		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				setLoad(false)
			}, 700)
		}
	}

	// Проверка на наличия свойства в наценке для корректоного отображения
	useEffect(() => {
		if (activeFrom || activeTo) {
			setSelectPeriod('limited')
		} else {
			setSelectPeriod('unlimited')
		}

		if (modalData.original?.condition?.categories?.length > 0) {
			dispatch({type: "SET_MARKUPS_SELECTED_CATEGORY", selectedCategory: modalData.original.condition.categories});
		} else {
			dispatch({type: "SET_MARKUPS_SELECTED_CATEGORY", selectedCategory: []});
		}

		if (modalData.original?.condition?.products?.length > 0) {
			dispatch({type: "SET_MARKUPS_SELECTED_PRODUCT", selectedProduct: modalData.original.condition.products});
		} else {
			dispatch({type: "SET_MARKUPS_SELECTED_PRODUCT", selectedProduct: []});
		}

		if (modalData.original?.condition?.products?.length > 0 || modalData.original?.condition?.categories?.length > 0) {
			setConditionValue('categoryAndProduct')
		}
	}, [])


	return (
		<>
			{loading && (
				<div className={`i_add-category-modal-loading`} style={{height: "100%"}}>
					<Skeleton count={1} height={'100%'} borderRadius={10}/>
				</div>
			)}
			{
				!loading && (
					<div className={'i_add-category-modal'}>
						<div className="i_add-category-modal-title">
							<span>Изменение наценки</span>
						</div>
						<div className="i_add-category-content">
							<div className="i_add-category-items">

								{/*Активность*/}

								<div className={`i_add-catalog-modal-item item-switch`}>
									<SwitchButton inputChecked={active} checkBoxValue={checkboxHandler} title={'Активно'} value={''}/>
								</div>

								{/*Название наценки*/}

								<div className={`i_add-catalog-modal-item`}>
									<CustomInput title={'Название наценки'} value={name} onChange={(e) => {
										setName(e)
									}}/>
								</div>

								{/*Период активности*/}

								<div className={`i_add-catalog-modal-item`}>
									<SelectDropdown defaultValue={selectPeriod === 'unlimited' ? 'Неограниченно' : 'Период'}
									                label={'Период активности'} item={periodData}
									                onChange={changeCurrentPeriod}/>
								</div>

								{/*Дата начала и окончания*/}

								{
									selectPeriod && selectPeriod === 'limited' && (
										<>
											<div className={`i_add-catalog-modal-item`}>
												<DateTimePicker title={'Дата начала'} onChange={(e) => setActiveFrom(e)} value={activeFrom}/>
											</div>
											<div className={`i_add-catalog-modal-item`}>
												<DateTimePicker title={'Дата окончания'} onChange={(e) => setActiveTo(e)} value={activeTo}/>
											</div>
										</>
									)
								}

								{/*Тип наценки*/}

								<SelectMarkupUnit onChange={handleSelectMarkupUnit}/>

								{/*Приоритет*/}

								<div className={`i_add-catalog-modal-item`}>
									<CustomInput title={'Приоритет'} type={'number'} value={priorityValue} helpTitle={priorityHelpTitle} helpTitleIsHTML={true} showHelpTitleInMobile={true}
									             onChange={(e) => {
										             setPriorityValue(e)
									             }}/>
								</div>

								{/*Условие*/}

								<SelectDropdown
									defaultValue={selectedProducts?.length > 0 || selectedCategory.length > 0 ? "Категории и товары" : "Весь каталог"}
									label={'Условие'}
									item={conditionsData} onChange={({value}) => {
									setConditionValue(value)
								}}/>

								{conditionValue === 'categoryAndProduct' &&
									<div className="i_add-catalog-modal-item">
										<SelectButton title={'Выбрать категории и товары'} onClick={openSelectCategoryAndProductModal}/>
									</div>
								}

							</div>

							<div className="i_add-category-modal-btn" onClick={formSubmit}>
								<ButtonLoader load={load} title={'Сохранить'}/>
							</div>
							{error !== '' ?
								<div className={`i_add-category-modal-error`}>
									<span>{error}</span>
								</div> : ''
							}
						</div>
					</div>
				)
			}
		</>
	)
}

export default EditMarkupElement