import {MarkupsListHead, MarkupsListItem} from "../../../../../utils/ui/";

const MarkupsList = ({store}) => {

	return (
		<>
			<MarkupsListHead
				columns={store.list.columns}
				store={store}
				actionType={'markups'}
				showCheckBox={true}
			/>
			<MarkupsListItem
				products={store.list.products}
				columns={store.list.columns}
				store={store}
				emptyTitle={'Наценки отсутствуют.'}
				edit={true}
				showCheckBox={true}
				actionType={'markups'}
			/>
		</>
	);
}

export default MarkupsList;