const initialState = {
	orderList: [],
	orderCount: null,
	offset: 0,
	columns: [],
	tableItemWidth: null,
	tableWidth: null,
	tableMinWidth: null,
	nextOffset: 0,
	items: []
}

export const orderReducer = (state = initialState, action) => {
	switch (action.type) {

		case "SET_ORDER_LIST_COLUMNS": {
			return {
				...state,
				columns: action.columns
			}
		}

		case "SET_ORDER_LIST_TABLE_ITEM_WIDTH": {
			return {
				...state,
				tableItemWidth: action.tableItemWidth
			}
		}

		case "SET_ORDER_LIST_TABLE_WIDTH": {
			return {
				...state,
				tableWidth: action.tableWidth
			}
		}

		case "SET_ORDER_LIST_TABLE_MIN_WIDTH" : {
			return {
				...state,
				tableMinWidth: action.tableMinWidth
			}
		}

		case "SET_ORDER_LIST": {
			return {
				...state,
				orderList: action.orderList,
				nextOffset: action.nextOffset,
			}
		}

		case "SET_MORE_ORDER_LIST": {
			return {
				...state,
				orderList: [...state.orderList, ...action.orderList],
				nextOffset: action.nextOffset,
			}
		}
		default:
			return state;
	}
}