import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoader, CustomInput, SwitchButton, Toast} from "../../../utils/ui";
import {api} from "../../../api";
import styles from '../EditDetailProductModal.module.scss'

const EditDetailQuantityProduct = () => {
	const productDetail = useSelector(state => state.catalog.list.productDetail)
	const {productList, offset, nextOffset, currentCategoryId} = useSelector(state => state.catalog.list.products)
	const dispatch = useDispatch()
	const showActiveItem = useSelector(state => state.catalog.list.products.showActiveItem)

	//Состояния для инпутов
	const [quantity, setQuantity] = useState(productDetail?.quantity?.quantity || 0)
	const [order , setOrder] = useState(productDetail?.quantity?.order)
	const [btnLoad, setBtnLoad] = useState(false)

	function checkboxHandler({isChecked}){
		setOrder(isChecked)
	}

	// Отправляем форму для изменения атррибутов товара
	async function formSubmit() {
		setBtnLoad(true)

		try{
			const response = await api.cabinetApi.updateCatalogProductQuantity({id:productDetail.id, quantity: Number(quantity), order:order})
			const updatedProductDetail = await api.cabinetApi.getCatalogProductDetail({id: productDetail.id})

			if(response.status === true){
				// Обновляем детальную информацию о товаре
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail.product})
				Toast(response.message, 'success');
				dispatch({type: "CLOSE_MODAL", modalLevel: 2})

				//Обновляем список товаров в каталоге, чтобы изменения отобразились
				let updatedProductList = productList.map((item) => {
					if(item.id === productDetail.id){
						return {...item,
							quantity: quantity,
							active: updatedProductDetail?.product?.active,
							order: order
						}
					}else{
						return item;
					}
				})

				// Если у нас в каталоге установлен параметр "Показывать только активные товары", то фильтруем список товаров
				if(showActiveItem) updatedProductList = updatedProductList.filter((item) => item.active === true)

				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedProductList, nextOffset: nextOffset, offset: offset, currentCategoryId: currentCategoryId})
			}else{
				Toast(response.message, 'error');
			}

		}catch (e){
			console.log(e)
		}
		setBtnLoad(false)
	}


	return (
		<>
			{/* Инпуты */}
			<CustomInput title={'Остаток'} value={quantity} onChange={setQuantity} type={'number'} disabled={order}/>

			<div className={styles.checkboxBlock}>
				<SwitchButton inputChecked={order} checkBoxValue={checkboxHandler} title={'На заказ'} value={''}/>
			</div>

			{/*Кнопка*/}
			<ButtonLoader title={'Сохранить'} load={btnLoad} onClick={formSubmit}/>
		</>
	)
}

export default EditDetailQuantityProduct;