import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {api} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoader, CustomInput, Toast} from "../../utils/ui";
import CategoryList from "../../modal/components/CategoryList";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";

const AddSourceCategory = () => {
	// Redux методы
	const dispatch = useDispatch();
	const catalogCode = useSelector(state => state.catalog.catalogCode)
	const currentCatalogItem = useSelector(state => state.multiModal.modals[0].currentCategoryId)
	const catalogCategoryList = useSelector(state => state.catalog.categoryList.categoryList);

	//Состояния для инпутов
	const [name, setName] = useState('')

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true);

	//Нужно для перевода
	const {t} = useTranslation();

	// Получаем список категорий
	const getList = async () => {
		try {
			const categoryList = await api.cabinetApi.getSourceCategoryList({source_code: catalogCode});

			if(categoryList.status === true){
				dispatch({
					type: "SET_MODAL_DATA",
					modalLevel: 1,
					modalData: {categoryList: categoryList.categories, currentCategoryId: null}
				})
				setLoading(false)
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		getList()
	}, []);


	//Массив с инпутами
	const formItems =
		[
			{
				name: 'Название категории',
				value: name,
				stateFunc: setName
			}
		]

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		//Создаем тело запроса
		let body = {
			"name": name,
			"parent_id": currentCatalogItem,
			"source_code": catalogCode
		};

		// Делаем запрос
		try {
			const result = await api.cabinetApi.addSourceCategory(body);

			if (result.status === true) {

				const categoryListResponse = await api.cabinetApi.getSourceCategoryList({source_code: catalogCode});

				dispatch({
					type: "SET_MODAL_DATA",
					modalLevel: 1,
					modalData: {categoryList: categoryListResponse.categories, currentCategoryId: null}
				})

				const resultDate = {
					id: result.id,
					parent_id: result.parent_id,
					name: result.name,
					is_active: false,
					active: true,
					depth_level: result.depth_level,
					has_children: result.has_children
				}

				let updatedList = catalogCategoryList.map((item) => {
					if (item.id === result.parent_id) {
						item.has_children = true
					}
					return item
				})

				updatedList.push(resultDate)

				dispatch({type: "SET_CATALOG_CATEGORY_LIST", categoryList: updatedList})

				Toast(result.message, 'success');

			} else {
				Toast(result.message, 'error');

				setError(result.message)
			}

		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				setLoad(false)
			}, 700)

		}
	}

	return (
		<>
			{loading && (
				<div className={`i_add-category-modal-loading`} style={{height:"100%"}}>
					<Skeleton count={1} height={'100%'} borderRadius={10}/>
				</div>
			)}
			{
				!loading && (
					<div className={'i_add-category-modal'}>
						<div className="i_add-category-modal-title">
							<span>Добавление категорий</span>
						</div>
						<div className="i_add-category-content">
							<div className="i_add-category-items">
								{
									formItems.map((item, index) => {
										return (
											<div className={`i_add-catalog-modal-item`} key={index}>
												<CustomInput title={item.name} value={item.value} onChange={(e) => {
													item.stateFunc(e)
												}}/>
											</div>
										)
									})
								}
							</div>
							<div className="i_add-category-list open">
								<div className="i_add-category-list-title">
									<span>Структура категорий</span>
								</div>
								<div className="i_add-category-list-content">
									<CategoryList/>
								</div>
							</div>
							<div className="i_add-category-modal-btn" onClick={formSubmit}>
								<ButtonLoader load={load} title={t('I_SELECT_ROLE_FORM_BTN')}/>
							</div>
							{error !== '' ?
								<div className={`i_add-category-modal-error`}>
									<span>{error}</span>
								</div> : ''
							}
						</div>
					</div>
				)
			}
		</>
	)
}

export default AddSourceCategory