import {useEffect, useLayoutEffect, useState} from "react";
import {useSelector} from "react-redux";
import styles from './MultiHelp.module.scss';

const MultiHelp = () => {
	const [position, setPosition] = useState({x: 0, y: 0});
	const [isVisible, setIsVisible] = useState(false);
	const [tooltipStyle, setTooltipStyle] = useState({});

	const multiHelpData = useSelector(state => state.cursorHelp);

	useEffect(() => {
		const updatePosition = (e) => {
			setPosition({x: e.clientX, y: e.clientY});
			setIsVisible(true);
		};

		document.addEventListener('mousemove', updatePosition);

		return () => {
			document.removeEventListener('mousemove', updatePosition);
		};
	}, []);

	useLayoutEffect(() => {
		const tooltip = document.querySelector('#i_multihelp');
		if (!tooltip || !isVisible || !multiHelpData.show) return;

		// Временно делаем tooltip видимым, чтобы корректно получить размеры
		tooltip.style.visibility = 'hidden';
		tooltip.style.display = 'block';

		const rect = tooltip.getBoundingClientRect();
		const windowWidth = window.innerWidth;
		const windowHeight = window.innerHeight;

		let left = position.x + 15;
		let top = position.y + 10;
		let maxWidth = 400; // Базовая ширина (можно подстроить)

		// Проверка выхода за правый край
		if (left + rect.width > windowWidth) {
			left = windowWidth - rect.width - 15; // Смещаем влево
			maxWidth = rect.width; // Ограничиваем максимальную ширину
		}

		// Если всё равно не помещается, принудительно уменьшаем ширину
		if (left < 0) {
			left = 15;
			maxWidth = windowWidth - 30; // Берём максимум доступного пространства
		}

		// Проверка выхода за нижний край
		if (top + rect.height > windowHeight) {
			top = position.y - rect.height - 10;
		}

		// Применяем стили
		setTooltipStyle({
			position: 'fixed',
			left: `${left}px`,
			top: `${top}px`,
			maxWidth: `${maxWidth}px`, // Гибко меняем ширину
			visibility: 'visible',
			whiteSpace: 'normal', // Позволяет тексту переноситься
		});

		// Возвращаем tooltip в нормальное состояние
		tooltip.style.visibility = '';
		tooltip.style.display = '';
	}, [position, isVisible, multiHelpData.show, multiHelpData.content]);

	return (
		<div
			className={`${styles.multiHelp} ${!multiHelpData.show || !isVisible ? styles.hide : ''} ${multiHelpData.showInMobile ? styles.showInMobile : ''}`}
			style={tooltipStyle}
			id={'i_multihelp'}
		>
			<div className="i_multi-help-content">
				{
					multiHelpData.isHTML ?
						<div dangerouslySetInnerHTML={{__html: multiHelpData.content}}></div>
						:
						<span>{multiHelpData.content}</span>
				}
			</div>
		</div>
	);
};

export default MultiHelp;
