import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as OpenList} from "../../../../images/open-list.svg";
import {ReactComponent as CloseList} from "../../../../images/close-list.svg";
import CatalogTools from "../../../components/CatalogTools/CatalogTools";
import {useEffect} from "react";
import {api} from "../../../../api";

const MatchingRightTools = () => {

	const dispatch = useDispatch()
	const categoryCatalogList = useSelector(state => state.matching.categoryCatalogList);
	const code = useSelector(state => state.matching.currentCatalogList.code);

	const getData = async () => {
		try {
			const categoryList = await api.cabinetApi.getCatalogCategoryList({code: code});
			dispatch({type: "SET_CATALOG_CATEGORY_LIST", categoryList: categoryList.categories})
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {

		getData()

		dispatch({type: "SET_CATALOG_CODE", catalogCode: code})
	}, []);

	//Раскрываем или закрываем элемента списка категорий мои каталоги
	const switchViewCatalogCategoryList = (bool) => {

		const updatedCategoryCatalogList = categoryCatalogList.map(item => {
			if (item.has_children) {
				return {...item, is_show: bool}
			} else {
				return {...item, show_match: item.match ? bool : false}
			}
		})

		dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedCategoryCatalogList})
	}

	return(
		<>
			<CatalogTools type={'catalog'}/>

			<div className="i_matching-catalog-view-item"
			     onClick={() => {
				     switchViewCatalogCategoryList(true)
			     }}
			     onMouseEnter={() => dispatch({
				     type: "SWITCH_CURSOR_HELP",
				     show: true,
				     content: 'Развернуть'
			     })}
			     onMouseLeave={() => dispatch({
				     type: "SWITCH_CURSOR_HELP",
				     show: false
			     })}
			>
				<OpenList/>
			</div>
			<div className="i_matching-catalog-view-item"
			     onClick={() => {
				     switchViewCatalogCategoryList(false)
			     }}
			     onMouseEnter={() => dispatch({
				     type: "SWITCH_CURSOR_HELP",
				     show: true,
				     content: 'Свернуть'
			     })}
			     onMouseLeave={() => dispatch({
				     type: "SWITCH_CURSOR_HELP",
				     show: false
			     })}
			>
				<CloseList/>
			</div>
		</>
	)
}

export default MatchingRightTools;
