import {ButtonLoader, Toast} from "../../../utils/ui";
import {useEffect, useState} from "react";
import styles from '../EditMarketplaceConnectModal.module.scss'
import {api} from "../../../api";
import {useDispatch, useSelector} from "react-redux";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import ProductIntegration from "../components/KaspiKz/ProductIntegration";
import OrderIntegration from "../components/KaspiKz/OrderIntegration";

const KaspiKzConnect = () => {
	const dispatch = useDispatch();

	const {marketCode, marketDescription, marketSettings, marketConnectionID, marketFile} = useSelector(state => state.multiModal.modals[0].modalData);

	const marketplaces = useSelector(state => state.marketplaces.marketplacesList)

	const [catalogList, setCatalogList] = useState([])
	const [selectedCatalog, setSelectedCatalog] = useState('')
	const [defaultCatalog, setDefaultCatalog] = useState('')
	const [currentIntegration, setCurrentIntegration] = useState('product');
	const [integrationProductData, setIntegrationProductData] = useState(
		{
			merchantId: '',
			minQuantity: 1,
			selectedCatalog: '',
			active: true
		}
	)
	const [integrationOrderData, setIntegrationOrderData] = useState({
		kaspiApiKey: '',
		active: false
	})

	const getData = async () => {
		try{
			const distributorCatalogList = await api.cabinetApi.getCatalogList();
			const catalogList = distributorCatalogList.catalogs?.map((item) => {
				return{
					name: `${item.name} (${item.code})`,
					value: Number(item.id),
				}
			})

			setCatalogList(catalogList)
			setSelectedCatalog(marketSettings.catalog_id)
			setDefaultCatalog(catalogList.find(item => item.value === Number(marketSettings.catalog_id))?.name || "");
		}catch (e) {
			console.log(e)
		}
	}

	async function connectMarketplace(){
		try{
			const response = await api.marketplacesApi.editMarketplaceConnect({
				code: marketCode,
				product_on: integrationProductData.active,
				orders_on: integrationOrderData.active,
				catalog_id: integrationProductData.selectedCatalog,
				id: marketConnectionID,
				settings: {
					api_key: integrationOrderData.kaspiApiKey,
					merchant_id: integrationProductData.merchantId,
					min_quantity: integrationProductData.minQuantity
				}
			})

			if(response.status === true){
				const updatedMarketplaces = marketplaces.map((item) => {
					if(item.code === marketCode){
						item.active = true
						item.settings ={
							api_key: integrationOrderData.kaspiApiKey,
							product_on: integrationProductData.active,
							orders_on: integrationOrderData.active,
							catalog_id: integrationProductData.selectedCatalog,
							merchant_id: integrationProductData.merchantId,
							min_quantity: integrationProductData.minQuantity
						}
						item.file = response.file
					}
					return item
				})

				dispatch({type: 'SET_MARKETPLACES_LIST', marketplacesList: updatedMarketplaces})
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})

				Toast(response.message, 'success');
			}else{
				Toast(response.message, 'error');
			}

		}catch (e) {
			console.log(e)
		}
	}

	// Меняем вкладку интеграции
	function handleChangeIntegrationTab(event, newValue) {
		setCurrentIntegration(newValue);
	}

	// Функция получения данных из интеграции товаров
	function changeProductIntegrationData(data) {
		setIntegrationProductData(data)
	}

	// Функция получения данных из интеграции заказов
	function changeOrderIntegrationData(data) {
		setIntegrationOrderData(data)
	}

	useEffect(() => {
		getData()
	}, []);

	return(
		<>

			{/*{OptionData.map((item, index) => (*/}
			{/*	<BorderBlock key={index} style={{ padding: '11px 20px' }}>*/}
			{/*		<SelectInput title={item.title} checkBoxValue={checkBoxHandler} inputChecked={selectedCheckBox.includes(item.value)} value={item.value} disabled={item.disabled} helpTitle={item.helpTitle} />*/}
			{/*		{selectedCheckBox.includes(item.value) && (*/}
			{/*			<div className={styles.expandedContent}>*/}
			{/*				{item.value === 'product_on' && (*/}
			{/*					<>*/}
			{/*						<CustomInput title={'Merchant ID'} type={'text'} onChange={(e) => setMerchantId(e)} value={merchantId} required={true}/>*/}
			{/*						{catalogList.length === 0 ? (*/}
			{/*							<EmptyBlock title={'Каталоги отсутствуют.'} fullWidth={true} Content={RedirectToCatalog} />*/}
			{/*						) : (*/}
			{/*							<SelectDropdown label={'Выберите каталог для выгрузки'} item={catalogList} onChange={changeSelectedCatalog} defaultValue={defaultCatalog} />*/}
			{/*						)}*/}
			{/*						<CustomInput title={'Ограничение по остатку'} type={'number'} value={minQuantity} onChange={(e) => setMinQuantity(e)}*/}
			{/*						             helpTitle={'Не выгружаются товары, если остаток меньше указанного значения'} required={true}/>*/}

			{/*						{*/}
			{/*							marketFile && (*/}
			{/*								<div className={styles.marketplacesItemFile}>*/}
			{/*									<div className={styles.marketplacesItemFileContent}>*/}
			{/*										<span>Прайс-лист: </span>*/}
			{/*										<a href={marketFile} target={'_blank'}>{marketFile}</a>*/}
			{/*									</div>*/}
			{/*									<CopyToClipboardButton textToCopy={marketFile} style={{*/}
			{/*										position: 'relative',*/}
			{/*										top: 'unset',*/}
			{/*										right: 'unset', transform : 'unset'}}/>*/}
			{/*								</div>*/}
			{/*							)*/}
			{/*						}*/}
			{/*					</>*/}
			{/*				)}*/}
			{/*				{item.value === 'orders_on' && (*/}
			{/*					<CustomInput title={'Api-ключ от Kaspi.kz'} type={'text'} value={kaspiApiKey} onChange={(e) => setKaspiApiKey(e)} />*/}
			{/*				)*/}
			{/*				}*/}
			{/*			</div>*/}
			{/*		)}*/}
			{/*	</BorderBlock>*/}
			{/*))}*/}

			<TabContext value={currentIntegration} classList="i_tabs">
				<TabList onChange={handleChangeIntegrationTab}
				         indicatorColor={'transparent'}
				         className={'i_tabs-header'}
				         variant="scrollable"
				         scrollButtons="auto">
					<Tab label="Интеграция товаров" value="product" className={'i_tabs-header-item'}/>
					<Tab label="Интеграция заказов (скоро)" value="order" className={'i_tabs-header-item'}/>
				</TabList>

				<TabPanel value={'product'} className={styles.tabContent}>
					<ProductIntegration marketFile={marketFile}
					                    catalogList={catalogList}
					                    dataSelectedCatalog={selectedCatalog}
					                    dataDefaultCatalog={defaultCatalog}
					                    changeProductIntegrationData={changeProductIntegrationData}
					                    marketSettings={marketSettings}
					/>
				</TabPanel>

				<TabPanel value={'order'} className={styles.tabContent}>
					<OrderIntegration changeOrderIntegrationData={changeOrderIntegrationData}/>
				</TabPanel>

			</TabContext>

			<div className={styles.marketDescription}>
				<span>{marketDescription}</span>
			</div>

			<div className={styles.modalBtn}>
				<ButtonLoader title={'Сохранить'} onClick={connectMarketplace} load={false} />
			</div>
		</>
	)
}

export default KaspiKzConnect;