import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../../../../api";

import '../styles/matchingPage.scss'
import {ReactSVG} from "react-svg";
import plus from "../../../../images/catalogs/plus.svg";
import match from '../../../../images/match.svg'
import infoImg from "../../../../images/info.svg";
import deleteSvg from '../../../../images/closeModal.svg'
import {ReactComponent as OpenList} from "../../../../images/open-list.svg";
import {ReactComponent as CloseList} from "../../../../images/close-list.svg";

import SelectDropdown from "../../components/SelectDropdown";
import MatchingSourceCategoryList from "../../components/Matching/MatchingSourceCategoryList";
import MatchingCatalogCategoryList from "../../components/Matching/MatchingCatalogCategoryList";
import SelectInput from "../../components/SelectInput";
import MatchingCheckedProduct from "../../components/Matching/MatchingCheckedProduct";
import MatchingCatalogCheckedProduct from "../../components/Matching/MatchingCatalogCheckedProduct";
import {EmptyBlock, SearchHighlight, Toast} from "../../../../utils/ui";
import RedirectToCatalog from "../../../../utils/ui/EmptyBlock/components/RedirectToCatalog";
import MatchingRightTools from "../../components/Matching/MatchingRightTools";
import MatchingLeftTools from "../../components/Matching/MatchingLeftTools";

const MatchingPage = () => {

	const [sourceList, setSourceList] = useState('')
	const [catalogList, setCatalogList] = useState('')
	const [showMatchAdd, setShowMatchAdd] = useState(false)
	const [showMessage, setShowMessage] = useState(true)
	const [viewCatalogShowProduct, setViewCatalogShowProduct] = useState(false)
	const [viewSourceShowProduct, setViewSourceShowProduct] = useState(false)

	const [isLoading, setIsLoading] = useState(true)
	const dispatch = useDispatch()

	const currentSourceItem = useSelector(state => state.matching.currentSourceItem);
	const currentCatalogItem = useSelector(state => state.matching.currentCatalogItem);
	const currentSourceList = useSelector(state => state.matching.currentSourceList);
	const currentCatalogList = useSelector(state => state.matching.currentCatalogList);
	const categorySourceList = useSelector(state => state.matching.categorySourceList);
	const categoryCatalogList = useSelector(state => state.matching.categoryCatalogList);
	const productSourceList = useSelector(state => state.matching.sourceProductList);
	const productCatalogList = useSelector(state => state.matching.catalogProductList);
	const showSourceProductList = useSelector(state => state.matching.showSourceProductList);
	const showCatalogProductList = useSelector(state => state.matching.showCatalogProductList);
	const currentCatalogCategoryId = useSelector(state => state.matching.currentCatalogCategoryId);
	const currentCatalogCategoryMatchId = useSelector(state => state.matching.currentCatalogCategoryMatchId);
	const currentSourceCategoryId = useSelector(state => state.matching.currentSourceCategoryId);

	const changeCurrentSourceList = (item) => {
		dispatch({type: 'EDIT_SOURCE_LIST', currentSourceList: item})
		dispatch({type: 'CHANGE_SHOW_SOURCE_PRODUCT_LIST', showSourceProductList: false})
		dispatch({type: 'CHANGE_SHOW_CATALOG_PRODUCT_LIST', showCatalogProductList: false})
	}
	const changeCategoryCatalogList = (item) => {
		dispatch({type: 'EDIT_CATALOG_LIST', currentCatalogList: item})
		dispatch({type: 'CHANGE_SHOW_SOURCE_PRODUCT_LIST', showSourceProductList: false})
		dispatch({type: 'CHANGE_SHOW_CATALOG_PRODUCT_LIST', showCatalogProductList: false})
	}

	//Сохраняем выбранные продукты из source list
	const [selectProductSourceItem, setSelectProductSourceItem] = useState([])

	const checkBoxSourceProductHandler = (value) => {
		// Создаем копию текущего массива selectedDealers
		const updatedSelectedDealers = [...selectProductSourceItem];

		if (value.isChecked) {
			// Добавляем значение в массив, если чекбокс отмечен
			updatedSelectedDealers.push(value.value);
		} else {
			// Удаляем значение из массива, если чекбокс не отмечен
			const index = updatedSelectedDealers.indexOf(value.value);
			if (index > -1) {
				updatedSelectedDealers.splice(index, 1);
			}
		}

		// Обновляем состояние selectedDealers с новым массивом
		setSelectProductSourceItem(updatedSelectedDealers);
	};

	//Закрываем список товаров source
	function closeSourceProductList() {
		dispatch({type: 'CHANGE_SHOW_SOURCE_PRODUCT_LIST', showSourceProductList: !showSourceProductList})
		setTimeout(() => {
			dispatch({type: 'CHANGE_SOURCE_PRODUCT_LIST', sourceProductList: null})
		}, 200)
		setSelectProductSourceItem([])
	}

	//Закрываем список товаров catalog
	function closeCatalogProductList() {
		dispatch({type: 'CHANGE_SHOW_CATALOG_PRODUCT_LIST', showCatalogProductList: !showCatalogProductList})
		setTimeout(() => {
			dispatch({type: 'CHANGE_CATALOG_PRODUCT_LIST', catalogProductList: null})
		}, 200)
	}

	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			dispatch({type: 'CHANGE_SHOW_SOURCE_PRODUCT_LIST', showSourceProductList: false})
			dispatch({type: 'CHANGE_SHOW_CATALOG_PRODUCT_LIST', showCatalogProductList: false})
			setSelectProductSourceItem([])
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);


	// Проверка наличия матча у выбранной категории
	useEffect(() => {
		// Функция, которая рекурсивно проверяет наличие матча в списке категорий
		function checkMatch(item) {
			// Перебираем элементы списка категорий
			item?.forEach(item => {
				// Если текущий элемент имеет подкатегории и его id не совпадает с текущим выбранным источником
				if (item.items && item.id !== currentSourceItem) {
					// Рекурсивно вызываем функцию для подкатегорий
					checkMatch(item.items);
				} else if (item.id === currentSourceItem) { // Если id текущего элемента совпадает с текущим выбранным источником
					// Проверяем наличие матча
					if (!item.match) { // Если матча нет
						setShowMatchAdd(true); // Показываем возможность добавления матча
					} else {
						setShowMatchAdd(false); // Скрываем  возможность добавления матча
					}
				}
			});
		}

		// Вызываем функцию проверки наличия матча для списка выбранных категорий
		checkMatch(categorySourceList);
	}, [currentSourceItem, categorySourceList]);


	// Общая функция для обработки матчинга категорий и товаров в категорию
	const handleMatch = async (matchData, dispatchType, type) => {
		try {
			// Выполняем операцию матчинга
			const matchResult = await matchData();

			// Если матчинг успешен
			if (matchResult.status === true) {

				// Если нужно отрисовать список товаров в категории
				if (type === 'addMatchProduct') {
					productSourceList.products.forEach((item) => {
						matchResult.product_id.forEach((id) => {
							if (item.id === parseInt(id)) {
								item.match.status = true
								item.match.category_name = matchResult.category_name
								item.match.category_id = matchResult.category_id
							}
						})
					})
					setSelectProductSourceItem([])

					// Обновляем список товаров в Моих каталогах
					const catalogProductList = await api.cabinetApi.getMatchingProductMatchList({
						"id": currentCatalogCategoryMatchId,
						"type": currentSourceList.type
					})

					// Если при добавлении товаров в категории не было матча категории, то добавляем его
					// Если были то просто возвращаем item
					const updatedCatalogList = categoryCatalogList.map((item) => {
						 if(matchResult.addcat !== null && matchResult.addcat.status === true){
							 if (item.id === currentCatalogItem) {
								 return {
									 ...item,
									 match: (item.match || []).concat({
										 id: matchResult.addcat.id,
										 name: matchResult.source_category_name,
										 products: true,
										 source_category_id: matchResult.source_category_id
									 }),
									 show_match: true,
								 }
							 } else {
								 return item;
							 }
						 }else{
							 return item;
						 }
					})

					dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedCatalogList});
					dispatch({type: 'CHANGE_CATALOG_PRODUCT_LIST', catalogProductList: catalogProductList})
				}

				// Метод удаления матча товара
				if (type === 'deleteMatchProduct') {

					const productList = await api.cabinetApi.getMatchingProductList({
						"type": currentSourceList.type,
						"category_id": currentSourceCategoryId,
						"catalog_code": currentCatalogList.code
					})

					if (showCatalogProductList) {
						const catalogProductList = await api.cabinetApi.getMatchingProductMatchList({
							"id": currentCatalogCategoryMatchId,
							"type": currentSourceList.type
						})
						if (catalogProductList.products?.length > 0) {
							dispatch({type: 'CHANGE_CATALOG_PRODUCT_LIST', catalogProductList: catalogProductList})
						} else {
							const updatedCatalogList = categoryCatalogList.map((item) => {
								if(item.id === currentCatalogCategoryId){
									return {
										...item,
										match: item.match.filter((match) => match.id !== currentCatalogCategoryMatchId),
										show_match: false,
										is_show: false,
									}
								}else{
									return item;
								}
							})

							dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedCatalogList})
							dispatch({type: 'CHANGE_SHOW_CATALOG_PRODUCT_LIST', showCatalogProductList: false})
						}
					}

					if(matchResult.category_id){
						const updatedCatalogList = categoryCatalogList.map((item) => {
							if(item.id === matchResult.category_id) {
								return {
									...item,
									match: item.match.filter((match) => match.id !== matchResult.match_id),
									show_match: false,
									is_show: false
								}
							}else{
								return item;
							}
						})

						dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedCatalogList});
					}

					dispatch({type: 'CHANGE_SOURCE_PRODUCT_LIST', sourceProductList: productList})
				}

				if (type === 'addMatchCategory') {
					// Обновляем категории
					const updatedSourceList = categorySourceList.map((item) => {
						if (item.id === matchResult.source_category_id) {
							return {
								...item,
								match: {id: matchResult.id, name: matchResult.category_name, match_id: matchResult.category_id},
								show_match: true,
							}
						} else {
							return item;
						}
					})

					const updatedCatalogList = categoryCatalogList.map((item) => {
						if (item.id === matchResult.category_id) {

							let itemMatchList = item.match || []

							itemMatchList.push({id: matchResult.id, name: matchResult.source_category_name, source_category_id: matchResult.source_category_id})

							// Здесь я делаю проверку на наличие матча продукта в категории
							// если такой есть то заменяю его на матч полной категории
							if(item.match && item.match.length > 0){
								itemMatchList.map(match => {
									if(match.source_category_id === matchResult.source_category_id){
										return {...match, id: matchResult.id ,products: false}
									}else{
										return {...match}
									}
								})
							}

							 itemMatchList = itemMatchList.filter((item, index, array) => {
								const duplicate = array.find((el, i) =>
									el.name === item.name && el.source_category_id === item.source_category_id && i !== index
								);
								return !(duplicate && item.products === true);
							});

							return {
								...item,
								match: itemMatchList,
								show_match: true,
							}
						} else {
							return item;
						}
					})

					dispatch({type: 'EDIT_CATEGORY_CATALOG_LIST', categoryCatalogList: updatedCatalogList});
					dispatch({type: 'EDIT_CATEGORY_SOURCE_LIST', categorySourceList: updatedSourceList})
				}

				// Показываем уведомление
				Toast(matchResult.message, 'success');

			} else {
				// Если матчинг неудачен, выводим уведомление
				Toast(matchResult.message, 'error');

			}
		} catch (error) {
			console.log(error);
		}
	};

	// Функция для матчинга категорий
	const matchCategory = async () => {
		await handleMatch(() => api.cabinetApi.addMatchCategory({
			'source_category_id': currentSourceItem,
			'category_id': currentCatalogItem,
			'type': currentSourceList.type,
			'catalog_code': currentCatalogList.code,
		}), "SHOW_NOTIFICATION", 'addMatchCategory');
	};

	// Функция для матчинга товаров в категорию
	const matchProductCategory = async () => {
		await handleMatch(() => api.cabinetApi.addMatchProduct({
			"product_id": selectProductSourceItem,
			"category_id": currentCatalogItem,
			"type": currentSourceList.type
		}), "SHOW_NOTIFICATION", 'addMatchProduct')
	};

	const handleDeleteProductMatch = async (event, product) => {
		event.stopPropagation(); // Предотвращаем всплытие события

		const categoryId = product.match?.category_id || product.dl_category_id
		const productId = product.id || product.product_id

		await handleMatch(() => api.cabinetApi.deleteMatchProduct({
			"product_id": productId,
			"catalog_code": currentCatalogList.code,
			"category_id": categoryId
		}), "SHOW_NOTIFICATION", 'deleteMatchProduct');
	}

	//Получаем данные с сервера
	useEffect(() => {
		const getData = async () => {
			try {
				const sourceList = await api.cabinetApi.getMatchingSourceList()
				const catalogList = await api.cabinetApi.getMatchingCatalogList()

				setSourceList(sourceList)
				setCatalogList(catalogList)
				setIsLoading(false)

			} catch (error) {
				console.log(error) // Выводим ошибку в консоль
			}
		}
		getData()

		// Устанавливаем текущий мультитул
		setTimeout(()=>{
			dispatch({type: "SET_TOOLS", currentTools: "matching-page"})
		},5)
	}, [])

	//Еффект для проверки выбранности двух категорий и получения категорий для выбранных каталогов
	useEffect(() => {
		dispatch({type: 'CLEAR_MATCHING_CURRENT'})
		if (currentSourceList && currentCatalogList) {
			let body = {
				"source_code": currentSourceList.code,
				"catalog_code": currentCatalogList.code,
				"type": currentSourceList.type,
				"distributor_id": currentSourceList.distributor_id
			}

			const getCatalogList = async () => {
				try {
					setShowMessage(false)
					const categoryList = await api.cabinetApi.getMatchingCategoryList(body)

					dispatch({
						type: 'EDIT_CATEGORY_SOURCE_LIST',
						categorySourceList: categoryList.source_categories.categories
					})
					dispatch({
						type: 'EDIT_CATEGORY_CATALOG_LIST',
						categoryCatalogList: categoryList.dl_categories.categories
					})

				} catch (error) {
					console.log(error)
				}
			}
			getCatalogList()
		}
	}, [currentSourceList, currentCatalogList])

	const switchViewSourceProductList = (bool) => {
		setViewSourceShowProduct(bool)
	}

	return (
		<div className={'i_matching'}>
			{!isLoading && (
				<>
					{/*Левый столбец матчинга*/}
					<div className="i_matching-left">
						<SelectDropdown
							item={sourceList.catalogs}
							label={'Каталоги источники'}
							onChange={changeCurrentSourceList}
							isSourceList={true}
							defaultValue={'Выберите каталог'}
						/>
						{
							categorySourceList && categorySourceList.length > 0 && (
								<div className="i_matching-catalog">
									<div className="i_matching-tools">
										<div className="i_matching-tools-left">
											<SearchHighlight containerID={'.i_matching-left .i_catalog-category-item-title'} isMatching={true} matchingType={'source'}/>
										</div>
										<div className="i_matching-catalog-view">
											<MatchingLeftTools />
										</div>
									</div>
									<div className="i_catalog-category-items">
										<MatchingSourceCategoryList categorySourceList={categorySourceList}/>
									</div>
									<div className={`i_catalog-show-product ${!showSourceProductList ? 'hd' : ''}`}>
										<div className="i_catalog-show-product-view">
											<div className="i_catalog-show-product-view-item"
											     onClick={() => {
												     switchViewSourceProductList(true);
											     }}
											     onMouseEnter={() => dispatch({
												     type: "SWITCH_CURSOR_HELP",
												     show: true,
												     content: 'Развернуть'
											     })}
											     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
											>
												<OpenList />
											</div>
											<div className="i_catalog-show-product-view-item"
											     onClick={() => {
												     switchViewSourceProductList(false)
											     }}
											     onMouseEnter={() => dispatch({
												     type: "SWITCH_CURSOR_HELP",
												     show: true,
												     content: 'Свернуть'
											     })}
											     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
											>
												<CloseList />
											</div>
										</div>
										<div className="i_catalog-show-product-close" onClick={closeSourceProductList}>
											<img src={deleteSvg} alt="X"/>
										</div>
										<div
											className="i_catalog-show-product-title">{productSourceList?.category_name}</div>
										<div className="i_catalog-show-products">
											{
												productSourceList?.products && productSourceList.products.length > 0 ? productSourceList.products.map((item, index) => {
													return (
														item.match.status === true ? (
															<div className={'i_catalog-show-product-box'} key={index}>
																<div className={`i_catalog-show-product-status ${item.active ? 'active' : 'inactive'}`}></div>
																	<MatchingCheckedProduct product={item} key={index}
																	                        viewSourceShowProduct={viewSourceShowProduct}
																	                        handleDeleteProductMatch={handleDeleteProductMatch}/>
															</div>
														) : (
															<div className={'i_catalog-show-product-box select-checkbox'} key={index}>
																<div className={`i_catalog-show-product-status ${item.active ? 'active' : 'inactive'}`}></div>
																	<SelectInput title={item.name} value={item.id}
																	             checkBoxValue={checkBoxSourceProductHandler}
																	             key={index}/>
															</div>
														)
													)
												}) : <EmptyBlock title={'Товары отсутствуют.'}/>
											}
										</div>
									</div>
								</div>
							)
						}
					</div>

					{/*Кнопка матча категорий*/}
					{currentCatalogItem && currentSourceItem && showMatchAdd && !showSourceProductList && selectProductSourceItem.length === 0 && (
						<div className="i_matching-btn">
							<ReactSVG src={match} onClick={matchCategory}/>
						</div>
					)}

					{/*Кнопка матча товаров в категории*/}
					{currentCatalogItem && selectProductSourceItem.length > 0 && (
						<div className="i_matching-btn">
							<ReactSVG src={match} onClick={matchProductCategory}/>
						</div>
					)}

					{/*Правый столбец матчинга*/}
					<div className="i_matching-right">
						{catalogList.catalogs.length > 0 ? (
								<>
									<SelectDropdown
										item={catalogList.catalogs}
										label={'Мои каталоги'}
										onChange={changeCategoryCatalogList}
										defaultValue={'Выберите каталог'}
									/>


									{currentCatalogList && currentCatalogList.id && currentSourceList && (
										<div className="i_matching-catalog">
											<div className="i_matching-tools">
												<div className="i_matching-tools-left">
													<SearchHighlight containerID={'.i_matching-right .i_catalog-category-item-title'}
													                 isMatching={true} matchingType={'catalog'}/>
												</div>
												<div className="i_matching-catalog-view">
													<MatchingRightTools />
												</div>
											</div>
											<div className="i_catalog-category-items">
												{categoryCatalogList && categoryCatalogList.length > 0 ?
													<MatchingCatalogCategoryList categoryCatalogList={categoryCatalogList}/>
													:
													<EmptyBlock title={'Каталоги отсутствуют.'} fullWidth={true} Content={RedirectToCatalog}/>
												}

											</div>

											<div
												className={`i_catalog-show-product ${!showCatalogProductList ? 'hd' : ''}`}>
												<div className="i_catalog-show-product-close"
												     onClick={closeCatalogProductList}>
													<img src={deleteSvg} alt="X"/>
												</div>
												<div
													className="i_catalog-show-product-title">{productCatalogList?.category_name}</div>
												<div className="i_catalog-show-products">
													{
														productCatalogList?.products && productCatalogList.products.length > 0 && productCatalogList.products.map((item, index) => {
															return (
																<div className={'i_catalog-show-product-box'} key={index}>
																	<div
																		className={`i_catalog-show-product-status ${item.active ? 'active' : 'inactive'}`}></div>
																	<MatchingCatalogCheckedProduct product={item} key={index}
																	                               viewCatalogShowProduct={viewCatalogShowProduct}
																	                               handleDeleteProductMatch={handleDeleteProductMatch}/>
																</div>
															)
														})
													}
												</div>
											</div>
										</div>
									)}
								</>
							) :
							<>
								<div className="i_matching-catalog-empty">
									<div className={'i_matching-catalog-add'} onClick={() => {
										dispatch({
											type: "OPEN_MODAL",
											modalType: 'addCatalogModal',
											modalLevel: 1
										})
									}}>
										<ReactSVG src={plus} className={'i_main-widgets-svg'}/>
										<span>Добавьте свой каталог</span>
									</div>

								</div>
							</>
						}
					</div>

					{/*Показываем табличку если не выбрано оба каталога*/}
					{showMessage && (
						<div className="i_matching-message">
							<div className="i_matching-message-title">
								<img src={infoImg} alt=""/>
								<span>Для отображения категорий выберите оба каталога</span>
							</div>
						</div>
					)
					}
				</>
			)}
		</div>
	);
}

export default MatchingPage;