import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as OpenList} from "../../../../images/open-list.svg";
import {ReactComponent as CloseList} from "../../../../images/close-list.svg";
import CatalogTools from "../../../components/CatalogTools/CatalogTools";

const MatchingLeftTools = () => {

	const dispatch = useDispatch()
	const categorySourceList = useSelector(state => state.matching.categorySourceList);

	//Раскрываем или закрываем элемента списка категорий источника
	const switchViewSourceCategoryList = (bool) => {

		const updatedCategorySourceList = categorySourceList.map(item => {
			if (item.has_children) {
				return {...item, is_show: bool}
			} else {
				return {...item, show_match: item.match ? bool : false}
			}
		})

		dispatch({type: 'EDIT_CATEGORY_SOURCE_LIST', categorySourceList: updatedCategorySourceList})
	}

	return(
		<>
			<div className="i_matching-catalog-view-item"
			     onClick={() => {
				     switchViewSourceCategoryList(true)
			     }}
			     onMouseEnter={() => dispatch({
				     type: "SWITCH_CURSOR_HELP",
				     show: true,
				     content: 'Развернуть'
			     })}
			     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
			>
				<OpenList/>
			</div>
			<div className="i_matching-catalog-view-item"
			     onClick={() => {
				     switchViewSourceCategoryList(false)
			     }}
			     onMouseEnter={() => dispatch({
				     type: "SWITCH_CURSOR_HELP",
				     show: true,
				     content: 'Свернуть'
			     })}
			     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
			>
				<CloseList/>
			</div>
		</>
	)
}

export default MatchingLeftTools;
