import {useDispatch, useSelector} from "react-redux";
import {SwitchButton} from "../../../utils/ui";
import {api} from "../../../api";
import {useCallback, useEffect, useLayoutEffect, useState} from "react";

const ShowActiveElement = () => {
	const dispatch = useDispatch();
	const {nextOffset, currentCategoryId} = useSelector(state => state.catalog.list.products);
	const code = useSelector(state => state.catalog.catalogCode);
	const show = useSelector(state => state.catalog.list.products.showActiveItem);

	const [checked, setChecked] = useState(show);

	// Получаем данные показа активности товара
	const getData = useCallback(async () => {
		try {
			const response = await api.cabinetApi.getUserSettings({"catalog_code": code});

			if (response.status === true && response?.settings?.active === true) {
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_SHOW_ACTIVE_ITEM", showActiveItem: true});
			} else {
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_SHOW_ACTIVE_ITEM", showActiveItem: false});
			}
		} catch (e) {
			console.log(e);
		}
	} , [])

	// Получаем данные при загрузке компонента
	useEffect(() => {
		getData();
	}, [getData]);

	// При изменении show в Redux меняем checked
	useEffect(() => {
		setChecked(show);
		// Чистим выбранные элементы при смене show
		dispatch({type: "SET_CATALOG_SELECTED_PRODUCT_ID", checkedProductId: []})
	}, [show]);

	// Меняем Show из switchCheckbox
	const checkboxHandler = async(data) => {
		dispatch({type: "SET_CATALOG_LIST_PRODUCT_SHOW_ACTIVE_ITEM", showActiveItem: data.isChecked})

		if (data.isChecked) {
			await api.cabinetApi.setUserSettingsProductShowActive({"catalog_code": code});
		} else {
			await api.cabinetApi.setUserSettingsProductShowAll({"catalog_code": code});
		}

		showActive();
	}

	const showActive = async () => {
		const body = {
			code: code,
			offset: 0,
			filter: !show ?  {"category_id": currentCategoryId, "active": !show} : {"category_id": currentCategoryId}
		}

		const productList = await api.cabinetApi.getCatalogProductList(body);
		dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: productList.products, nextOffset: nextOffset, currentCategoryId: currentCategoryId});
	}

	return (
		<div
			onMouseEnter={() => dispatch({type: "SWITCH_CURSOR_HELP", show: true, content: 'Показывать только активные товары'})}
			onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
		>
			<SwitchButton
				inputChecked={checked}
				checkBoxValue={checkboxHandler}
				size={'small'}
				title={'Только активные'}
			/>
		</div>
	)
}

export default ShowActiveElement;
