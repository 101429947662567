import styles from './QuantityCounter.module.scss';
import InputMask from "react-input-mask";
import React, {useState, useRef, useEffect} from "react";

import { ReactComponent as Plus } from "../../../../images/catalogs/category/plus.svg";
import { ReactComponent as Minus } from "../../../../images/catalogs/category/minus.svg";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../../../../api";
import {useDeleteBasketItem} from "../../../../hooks";

import {Toast} from "../../../../utils/ui";

const QuantityCounter = ({ counterItem , inBasket, inProductDetail, catalogCode}) => {
	const [itemQuantity, setItemQuantity] = useState(Number(counterItem.quantity));
	const [maxQuantity, setMaxQuantity] = useState(Number(counterItem.maxQuantity));
	const delayTimer = useRef(null);
	const dispatch = useDispatch();
	const productList = useSelector(state => state.catalog.list.products.productList)
	const nextOffset = useSelector(state => state.catalog.list.products.nextOffset)
	const currentCategoryId = useSelector(state => state.catalog.list.products.currentCategoryId)
	const buttonLoader = useSelector(state => state.basket.basketLoader)
	const productDetail = useSelector(state => state.catalog.list.productDetail)
	const {deleteBasketItem} = useDeleteBasketItem();

	// При изменении количества товара в корзине, обновляем состояние
	useEffect(() => {
		setItemQuantity(Number(counterItem.quantity));
		setMaxQuantity(Number(counterItem.maxQuantity));
	},[counterItem]);

	// Функция для отправки запроса
	async function setBasketItem(newQuantity) {
		try {

			const body = {
				product_id: counterItem.productID,
				quantity: newQuantity,
			}

			if(catalogCode) body.catalog_code = catalogCode;

			const response = await api.basketApi.setBasketItem(body);

			// Если ошибок нет то устанавливаем значение
			if (response.status === true) {
				// Изменяем показ количества товаров в корзине
			  const updatedBasketCount = await api.basketApi.getBasketCount();

				// Изменяем показ количества товаров в каталоге
				const updatedProductList = productList.map(item => {
					return(
						item.id === counterItem.productID ? {...item, basket_quantity: response.quantity, max_quantity: response.max_quantity} : item
					)
				})

				if(inBasket){
					const updatedBasketItem = await api.basketApi.getBasketList();
					dispatch({type: "SET_BASKET_LIST", basketList: updatedBasketItem.products})
					dispatch({type: "SET_BASKET_TOTAL_PURCHASE", basketTotalPurchase: updatedBasketItem.total_purchase})
					dispatch({type: "SET_BASKET_TOTAL_RETAIL", basketTotalRetail: updatedBasketItem.total_retail})
				}

				if(inProductDetail){
					const updatedProductDetail = {...productDetail, basket_quantity: response.quantity, max_quantity: response.max_quantity}
					dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail})
				}

				dispatch({type: "SET_BASKET_COUNT", basketCount: updatedBasketCount.count})
				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedProductList, nextOffset: nextOffset, currentCategoryId: currentCategoryId})

				Toast(response.message, 'success');
			} else {

				if(inBasket){
					const updatedBasketItem = await api.basketApi.getBasketList();
					dispatch({type: "SET_BASKET_LIST", basketList: updatedBasketItem.products})
				}

				const updatedProductList = productList.map(item => {
					return(
						item.id === counterItem.productID ? {...item, basket_quantity: response.quantity, max_quantity: response.max_quantity} : item
					)
				})
				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedProductList, nextOffset: nextOffset, currentCategoryId: currentCategoryId})
				Toast(response.message, 'error');
			}
		} catch (e) {
			console.log(e);
		} finally {
			setTimeout(() => {
				dispatch({type: "SET_BASKET_LOADER", basketLoader: false})
			},300)
		}
	}

	// Функция для управления таймером
	const handleDelayedRequest = (newQuantity) => {
		setItemQuantity(newQuantity);

		// Очистка предыдущего таймера
		if (delayTimer.current) {
			clearTimeout(delayTimer.current);
		}

		// Установка нового таймера
		delayTimer.current = setTimeout(() => {
			dispatch({type: "SET_BASKET_LOADER", basketLoader: true})

			if(newQuantity <= 0){
				deleteBasketItem(counterItem.productID)
			}else{
				setBasketItem(newQuantity); // Передача актуального значения
			}
		}, 500);
	};

	// Увеличение количества товара
	const incrementQuantity = () => {
		if (itemQuantity < maxQuantity) {
			const newQuantity = itemQuantity + 1;
			handleDelayedRequest(newQuantity);
		}
	};

	// Уменьшение количества товара
	const decrementQuantity = () => {
		if(itemQuantity > 0){
			const newQuantity = itemQuantity - 1;
			handleDelayedRequest(newQuantity);
		}
	};

	// Обработка ввода количества товара
	const handleChangeQuantity = (e) => {
		const onlyNumbers = Math.min(Number(e.target.value.replace(/\D/g, "")), maxQuantity);
		handleDelayedRequest(onlyNumbers);
	};

	return (
		<div className={`${styles.quantityCounter} ${buttonLoader ? styles.disabled : ''}`}>
			<div
				className={`${styles.quantityCounterButton} ${itemQuantity === 0 ? styles.disabled : ''}`} onClick={decrementQuantity}>
				<button>
					<Minus />
				</button>
			</div>
			<div className={`${styles.quantityValue}`}>
				<InputMask
					value={itemQuantity}
					mask=""
					alwaysShowMask={false}
					onChange={(e) => handleChangeQuantity(e)}
					inputMode="numeric"
				/>
			</div>
			<div className={`${styles.quantityCounterButton} ${itemQuantity >= maxQuantity ? styles.disabled : ''}`} onClick={incrementQuantity}>
				<button>
					<Plus />
				</button>
			</div>
		</div>
	);
};

export default QuantityCounter;
