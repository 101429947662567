import styles from './BasketModal.module.scss'
import {useCallback, useEffect, useState} from "react";
import {api} from "../../../api";
import BasketList from "../components/BasketList/BasketList";
import {ButtonLoader, Button, ScrollBox, Toast} from "../../../utils/ui";
import {useDispatch, useSelector} from "react-redux";
import Order from "../../../pages/main/components/Order/Order";
import {ReactComponent as ArrowUp} from "../../../images/catalogs/category/arrow-up.svg";

const BasketModal = () => {

	const dispatch = useDispatch();
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const [open, setOpen] = useState(false);

	function openOrder(){
		setOpen(!open);
	}

	// Получаем данные корзины
	const getData = useCallback(async () => {
		try {
			const response = await api.basketApi.getBasketList()

			if (response.status === true) {
				dispatch({type: "SET_BASKET_LIST", basketList: response.products})

				dispatch({type: "SET_BASKET_TOTAL_PURCHASE", basketTotalPurchase: response.total_purchase})
				dispatch({type: "SET_BASKET_TOTAL_RETAIL", basketTotalRetail: response.total_retail})
			}

		} catch (e) {
			console.log(e)
		}
	} , [])


	// Открытие модального окна для формирования КП
	function openGenerateBasketComModal(){
		if(totalRetail?.price === 0 || totalRetail?.price === null){
			Toast('КП доступно при наличии цен продажи во всех товарах', 'error');
		}else{
			dispatch({type: "OPEN_MODAL", modalType: 'generateBasketComModal', modalLevel: 2});
		}
	}

	useEffect(() => {
		getData()
	}, [getData]);

	// Данные для вывода итоговых цен
	const totalPurchase = useSelector(state => state.basket.basketTotalPurchase)
	const totalRetail = useSelector(state => state.basket.basketTotalRetail)
	const basketList = useSelector(state => state.basket.basketList)

	useEffect(() => {
		if(basketList.length <= 0){
			setButtonDisabled(true)
		}else{
			setButtonDisabled(false)
		}
	}, [basketList]);

	return (
		<>
			<div className={styles.modal}>
				<div className={styles.modalTitle}>
					<span>Корзина</span>
				</div>
				<ScrollBox>
					<BasketList/>
					<div className={styles.modalFooter}>
						<div className={styles.modalFooterTotal}>
							<div className={styles.modalFooterTotalTitle}>
								<span>Итого покупка: </span>
							</div>

							<div className={styles.modalFooterTotalValue}>
								<span>{totalPurchase?.print_price}</span>
							</div>
						</div>

						<div className={styles.modalFooterTotal}>
							<div className={styles.modalFooterTotalTitle}>
								<span>Итого продажа: </span>
							</div>

							<div className={styles.modalFooterTotalValue}>
								<span>{totalRetail?.print_price}</span>
							</div>
						</div>
						<div className={styles.modalFooterButton}>
							<div className={styles.modalButtonKp}>
								<Button title={'Сформировать КП'} disabled={buttonDisabled} onClick={openGenerateBasketComModal}/>
							</div>
						</div>
					</div>

					<Order open={open}/>

				</ScrollBox>
			</div>
		</>
	);
}

export default BasketModal;