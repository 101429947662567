import styles from './SettingsCurrencyList.module.scss'
import {ScrollBox, SelectInput} from "../../../utils/ui";
import RadioButton from "../../../utils/ui/Inputs/RadioButton/RadioButton";
import {useDispatch, useSelector} from "react-redux";

const SettingsCurrencyList = () => {

	const currencyList = useSelector(state => state.settings.currency.currenciesList)
	const currencySelect = useSelector(state => state.settings.currency.selected.currencies)
	const currencyDefault = useSelector(state => state.settings.currency.selected.default)

	const dispatch = useDispatch()


	const checkBoxHandler = (value) => {
		const updatedSelectedCurrency = [...currencySelect];

		if (!updatedSelectedCurrency.includes(value.value)) {
			// Если валюта не выбрана, добавляем в массив
			updatedSelectedCurrency.push(value.value);
			dispatch({type: "SET_SELECTED_CURRENCY", currencies: updatedSelectedCurrency, default: currencyDefault})
		} else {
			// Если валюта выбрана, удаляем из массива
			const index = updatedSelectedCurrency.indexOf(value.value);
			if (index > -1) {
				updatedSelectedCurrency.splice(index, 1);

				if(value.value === currencyDefault){

					const sortedSelected = currencyList
						.filter(item => currencySelect.includes(item.currency) && item.currency !== value.value)
						.map(item => item.currency);

					dispatch({type: "SET_SELECTED_CURRENCY", currencies: updatedSelectedCurrency, default: sortedSelected[0]})
				}else{
					dispatch({type: "SET_SELECTED_CURRENCY", currencies: updatedSelectedCurrency, default: currencyDefault})
				}
			}
		}

	};

	const radioHandler = (item) => {
		dispatch({type: "SET_SELECTED_CURRENCY", currencies: currencySelect, default: item.value})
	};

	return (
		<div className={styles.settingsCurrencyList}>
			<div className={styles.settingsCurrencyListTitle}>
				<span>Валюта</span>
			</div>
			<div className={styles.settingsCurrencyListDefaultTitle}>
				<span>По умолчанию</span>
			</div>
			<ScrollBox style={{
				padding: "0 15px 0 0", gap: 10, display: "flex", flexDirection: "column", maxHeight: 550
			}}>
				{currencyList.map((item, index) => (
					<div className={styles.settingsCurrencyListItem} key={index}>
						<SelectInput
							title={`${item.currency} - ${item.name}`}
							value={item.currency}
							inputChecked={currencySelect?.includes(item.currency)}
							checkBoxValue={checkBoxHandler}
						/>
						{currencySelect?.includes(item.currency) && (
							<RadioButton
								title=""
								value={item.currency}
								radioValue={radioHandler}
								inputChecked={currencyDefault === item.currency}
							/>
						)}
					</div>
				))}
			</ScrollBox>
		</div>
	);
};

export default SettingsCurrencyList;
