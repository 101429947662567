import {EmptyBlock, ProductActiveStatus, SelectInput} from "../index";
import EditElement from "../../../multitools/dealer/catalogPage/EditElement";
import styles from './ProductListItem.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {ACTION_CONFIG} from "../../config";
import {useEffect, useState} from "react";
import {api} from "../../../api";
import QuantityCounter from "../../../pages/main/components/QuantityCounter/QuantityCounter";

const ProductListItem = ({
	                         products,
	                         columns,
	                         emptyTitle,
	                         edit,
	                         showCheckBox,
	                         actionType,
	                         itemClick,
	                         store,
	                         catalogType,
	                         hideTools
                         }) => {

	const dispatch = useDispatch();
	const [showMore, setShowMore] = useState(true);

	const nextOffset = store.list.products.nextOffset;
	const currentCategoryId = store.list.products.currentCategoryId;
	const showActiveItem = store.list.products.showActiveItem;
	const code = store.catalogCode;
	const distributor_id = store.distributor_id;
	const tableWidth = store.list.tableWidth;
	const tableItemWidth = store.list.tableItemWidth;
	const catalogCode = store.catalogCode;

	// Следим за изменением nextOffset и контролируем отображение кнопки "Ещё"
	useEffect(() => {
		if (nextOffset === null || nextOffset === undefined) {
			setShowMore(false);
		} else if (nextOffset >= 100) {
			setShowMore(true);
		}
	}, [nextOffset]);

	// Загрузка дополнительных товаров
	const loadMore = async () => {
		try {
			let productList = '';

			switch (catalogType) {
				case "source" : {
					productList = await api.cabinetApi.getSourceProductList({
						source_code: code,
						offset: nextOffset,
						filter: showActiveItem ? {
							"category_id": currentCategoryId,
							"active": showActiveItem
						} : {"category_id": currentCategoryId}
					});
					break;
				}
				case "distributor" : {
					productList = await api.cabinetApi.getCatalogProductList({
						code: code,
						offset: nextOffset,
						filter: showActiveItem ? {
							"category_id": currentCategoryId,
							"active": showActiveItem
						} : {"category_id": currentCategoryId}
					});
					break;
				}
				case "my" : {
					productList = await api.cabinetApi.getCatalogProductList({
						code: code,
						offset: nextOffset,
						filter: showActiveItem ? {
							"category_id": currentCategoryId,
							"active": showActiveItem
						} : {"category_id": currentCategoryId}
					});
					break;
				}
				case "dealerDistributor" : {
					productList = await api.cabinetApi.getDistributorCatalogProductList({
						code: code,
						distributor_id: distributor_id,
						offset: nextOffset,
						filter: showActiveItem ? {
							"category_id": currentCategoryId,
							"active": showActiveItem
						} : {"category_id": currentCategoryId}
					});
					break;
				}
			}

			// Обновляем список товаров и следующий offset
			dispatch({
				type: ACTION_CONFIG[actionType].updateProductListMore,
				products: productList.products,
				offset: productList.offset,
				nextOffset: productList.next_offset
			});

			// Если больше товаров нет, скрываем кнопку "Ещё"
			if (!productList.next_offset) {
				setShowMore(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	// Получение данных из поля
	const getFieldData = (fieldCode, data) => {
		// Обрабатываем вложенные поля
		switch (fieldCode) {
			case "active" :
				return <ProductActiveStatus active={data.active}/>
			case "segmentation":
				return '';
			case "purchase":
				return data?.purchase?.length > 0 ? data?.purchase : 'Нет цены.';
			case "retail":
				return data?.retail?.length > 0 ? data?.retail : 'Нет цены.';
			case "rrp":
				return data?.rrp?.length > 0 ? data?.rrp : 'Нет цены.';
			case "price":
				return data?.price?.length > 0 ? data?.price : 'Нет цены.';
			case "quantity":
				return data?.order === true ? <div className={styles.productItemOrder}>на заказ</div> : data?.quantity;
			case "buy":
				const counterItem = {
					productID: data.id,
					quantity: data.basket_quantity,
					maxQuantity: data.order ? 10000 : data.quantity
				}
				return <QuantityCounter counterItem={counterItem} catalogCode={catalogType === 'my' ? catalogCode : null}/>
			default:
				return data[fieldCode];
		}
	};

	// Обработчик изменения состояния отдельного чекбокса
	const checkBoxHandler = ({value}) => {
		const updatedSelectedProduct = products.map(productItem => {
			if (productItem.id === value) {
				productItem.checked = !productItem.checked; // Инверсия состояния чекбокса для конкретного товара
			}
			return productItem;
		});

		// Обновляем checkedProductList с выделенными товарами
		const updatedSelectedIds = updatedSelectedProduct
			.filter(productItem => productItem.checked)
			.map(productItem => productItem.id);

		// Обновляем список товаров и список id выделенных товаров в Redux
		dispatch({
			type: ACTION_CONFIG[actionType].updateProductList,
			products: updatedSelectedProduct,
			nextOffset: nextOffset
		});
		dispatch({type: ACTION_CONFIG[actionType].updateCheckedProductId, checkedProductId: updatedSelectedIds});
	};

	return (
		<div
			className={`${styles.productItems} ${showCheckBox ? styles.checkboxList : ''} ${hideTools ? styles.hideTools : ''}`}>
			{
				products?.length > 0 ? products.map((product) => {
					return (
						<div className={`${styles.productItem}`} key={product.id} style={{width: tableWidth}} onClick={(e)=>{if (itemClick) itemClick(product.id);}}>
							{showCheckBox && (
								<div className={`${styles.productItemColumn} ${styles.checkbox}`} onClick={e => e.stopPropagation()}>
									<SelectInput title="" value={product.id} checkBoxValue={checkBoxHandler}
									             inputChecked={product.checked}
									             key={product.id}/>
								</div>
							)}
							{columns && columns.map((column, index) => {
								return (
									<div className={`${styles.productItemColumn} ${styles[column.code]}`} key={index} onClick={(e) => {
										if (column.code === "buy") { e.stopPropagation(); } // Останавливаем распространение события
									}} style={{width: tableItemWidth ? tableItemWidth[column.code] : 'unset'}}>
										<div className={`${styles.productItemColumnName}`}><span>{column.name}:</span></div>
										<div className={styles.productItemColumnValue}>
											{column.html ? (
												<div dangerouslySetInnerHTML={{__html: product[column.code]}}></div>
											) : (
												<span>{getFieldData(column.code, product)}</span>
											)}
										</div>
									</div>
								)
							})}
							{edit && (
								<div className={styles.edit}>
									<EditElement item={product}/>
								</div>
							)}
						</div>
					);
				}) : <EmptyBlock title={emptyTitle}/>
			}
			{showMore && (
				<div className={styles.productMore}>
					<span onClick={loadMore}>Ещё</span>
				</div>
			)}
		</div>
	)
}

export default ProductListItem;