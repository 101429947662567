import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoader, CustomInput, Toast} from "../../../utils/ui";
import {api} from "../../../api";
import toast from "react-hot-toast";
import SelectDropdown from "../../../pages/main/components/SelectDropdown";

const EditDetailParamsProduct = () => {
	const productDetail = useSelector(state => state.catalog.list.productDetail)
	const dispatch = useDispatch()

	//Состояния для инпутов
	const [width, setWidth] = useState(productDetail?.params?.width || 0)
	const [height, setHeight] = useState(productDetail?.params?.height || 0)
	const [length, setLength] = useState(productDetail?.params?.length || 0)
	const [dimensionsUnit, setDimensionsUnit] = useState(productDetail?.params?.dimensions_unit || 'mm')
	const [weight, setWeight] = useState(productDetail?.params?.weight || 0)
	const [weightUnit, setWeightUnit] = useState(productDetail?.params?.weight_unit || 'g')

	const [btnLoad, setBtnLoad] = useState(false)

	const handleChangeDimensionsUnit = (e) => {
		setDimensionsUnit(e.value)
	}

	const handleChangeWeightUnit = (e) => {
		setWeightUnit(e.value)
	}

	const formItems = [
		{
			name: 'Ширина товара',
			value: width,
			stateFunc: setWidth,
			type: 'number'
		},
		{
			name: 'Высота товара',
			value: height,
			stateFunc: setHeight,
			type: 'number'
		},
		{
			name: 'Длина товара',
			value: length,
			stateFunc: setLength,
			type: 'number'
		},
		{
			name: 'Единица измерения размеров',
			value: dimensionsUnit,
			stateFunc: setDimensionsUnit,
			type: 'text',
			isSelect: true,
			selectItems: [
				{
					name: 'Миллиграмм (mg)',
					value: 'mg'
				},
				{
					name: 'Грамм (g)',
					value: 'g'
				},
				{
					name: 'Килограмм (kg)',
					value: 'kg'
				},
				{
					name: 'Тонна (t)',
					value: 't'
				}
			],
			defaultValue: 'Грамм (g)',
			onChange: handleChangeDimensionsUnit
		},
		{
			name: 'Вес товара',
			value: weight,
			stateFunc: setWeight,
			type: 'number'
		},
		{
			name: 'Единица измерения веса',
			value: weightUnit,
			stateFunc: setWeightUnit,
			type: 'text',
			isSelect: true,
			selectItems: [
				{
					name: "Миллиметр (mm)",
					value: "mm"
				},
				{
					name: "Сантиметр (cm)",
					value: "cm"
				},
				{
					name: "Метр (m)",
					value: "m"
				},
				{
					name: "Километр (km)",
					value: "km"
				}
			],
			defaultValue: 'Миллиметр (mm)',
			onChange: handleChangeWeightUnit
		},
	]

	// Отправляем форму для изменения атррибутов товара

	async function formSubmit() {
		setBtnLoad(true)

		const data = {
			id: productDetail.id,
			width: width,
			height: height,
			length: length,
			dimensions_unit: dimensionsUnit,
			weight: weight,
			weight_unit: weightUnit,
		}

		try{
			const response = await api.cabinetApi.updateCatalogProductParam(data)
			const updatedProductDetail = await api.cabinetApi.getCatalogProductDetail({id: productDetail.id})

			if(response.status === true){

				// Обновляем детальную информацию о товаре
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail.product})
				dispatch({type: "CLOSE_MODAL", modalLevel: 2})

				Toast(response.message, 'success');
			}else{
				Toast(response.message, 'error');
			}

		}catch (e){
			console.log(e)
		}
		setBtnLoad(false)
	}


	return (
		<>
			{/* Инпуты */}
			{formItems.map((item, index) => {
				return (
					<>
						{
							item.isSelect ?
								<SelectDropdown label={item.name} style={{width: "100%", textAlign: "start"}} item={item.selectItems} onChange={item.onChange} defaultValue={item.defaultValue}/>
								:
								<CustomInput title={item.name} value={item.value} onChange={(e) => {
									item.stateFunc(e)
								}} showHelp={item.showHelp} helpTitle={item.helpTitle} required={item.required} readOnly={item.readOnly} type={item.type}/>
						}
					</>
				)
			})}

			{/*Кнопка*/}
			<ButtonLoader title={'Сохранить'} load={btnLoad} onClick={formSubmit}/>
		</>
	)
}

export default EditDetailParamsProduct;