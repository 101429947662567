import styles from "./OrderListHead.module.scss";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ACTION_CONFIG} from "../../config";
import Cookies from "js-cookie";

const OrderListHead = ({columns, store, actionType}) => {

	const dispatch = useDispatch();
	const tableMinWidth = store.tableMinWidth;
	const storeTableItemWidth = store.tableItemWidth;
	const storeTableWidth = store.tableWidth;

	const [notTouch, setNotTouch] = useState(false);
	const [isResizing, setIsResizing] = useState(false);
	const [tableWidth, setTableWidth] = useState(0);
	const [columnsWidth, setColumnWidth] = useState({});
	const [activeItem, setActiveItem] = useState(null);

	// Записываем ширину таблицы в cookies, а данные записываем в store при завершении ресайза
	useEffect(() => {
		if (!isResizing) {

			let cookiesName = 'columns_order';

			Cookies.set(cookiesName, JSON.stringify({
				columns: JSON.stringify(columnsWidth),
				tableWidth: tableWidth
			}), {expires: 365})

			dispatch({type: ACTION_CONFIG[actionType].setOrderListTableItemWidth, tableItemWidth: columnsWidth})
			dispatch({type: ACTION_CONFIG[actionType].setOrderListTableWidth, tableWidth: tableWidth})
		}
	}, [isResizing, tableWidth, columnsWidth]);

	// Функция отрабатывается если у клиента нет сохраненных настроек ширины колонок в Cookies, инициализируем ширину колонок по умолчанию
	function initColumnsWidth() {
		let minWidth = 0;

		const updatedColumnsWidth = columns.reduce((acc, item) => {

			let itemWidth = item.width;


			minWidth += itemWidth + 10;

			acc[item.code] = itemWidth;
			return acc;

		}, {});

		if (minWidth < tableMinWidth) {
			// Получение ключей объекта
			const keys = Object.keys(updatedColumnsWidth);
			// Получение последнего ключа
			let lastKey = keys[keys.length - 1];

			if (lastKey === 'buy') lastKey = keys[keys.length - 2];

			// Обращение к значению последнего элемента
			updatedColumnsWidth[lastKey] = updatedColumnsWidth[lastKey] + (tableMinWidth - minWidth);

			minWidth = tableMinWidth - 10;
		}

		setTableWidth(minWidth + 1);
		setColumnWidth(updatedColumnsWidth);
	}

	// Инициализация ширины колонок
	useEffect(() => {
		if (storeTableItemWidth && storeTableWidth) {
			setTableWidth(storeTableWidth);
			setColumnWidth(storeTableItemWidth);
		} else {
			initColumnsWidth();
		}
	}, [columns]);


	// Делаем колонку растягиваемой
	const changeColumnWidth = (e, columnCode) => {
		if (!columnsWidth) return;
		let x = e.clientX;
		setNotTouch(true);
		setIsResizing(true);

		setActiveItem(columnCode)

		const onMouseMove = (e) => {
			let dx = e.clientX - x;
			const updatedColumnsWidth = {...columnsWidth};

			// Определяем минимальную ширину в зависимости от columnCode
			const minWidth = columnCode === 'active' ? 25 : 80;

			// Убедиться, что новая ширина колонки не меньше минимальной
			const newWidth = updatedColumnsWidth[columnCode] + dx;

			if (newWidth < minWidth) {
				dx = minWidth - updatedColumnsWidth[columnCode];
			}

			updatedColumnsWidth[columnCode] += dx;

			let tableWidth = 0;

			Object.entries(updatedColumnsWidth).forEach(([key, width]) => {
				const columnMinWidth = key === 'active' ? 25 : 80;
				tableWidth += width < columnMinWidth ? columnMinWidth : width;
				tableWidth += 10; // Увеличение ширины таблицы на 10 для каждого столбца
			});

			if (tableWidth < tableMinWidth) {
				// Получение ключей объекта
				const keys = Object.keys(updatedColumnsWidth);
				// Получение последнего ключа
				let lastKey = keys[keys.length - 1];

				if (lastKey === 'buy') lastKey = keys[keys.length - 2];

				// Обращение к значению последнего элемента
				updatedColumnsWidth[lastKey] = updatedColumnsWidth[lastKey] + (tableMinWidth - tableWidth);

				tableWidth = tableMinWidth - 5;
			}

			setTableWidth(tableWidth + 1);
			setColumnWidth(updatedColumnsWidth);
		};

		const onMouseUp = () => {
			setNotTouch(false);
			setIsResizing(false); // Снимаем флаг после завершения ресайза
			setActiveItem(null)

			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		};

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
	};

	return (
		<>
			<div
				className={`${styles.productHead} ${styles[actionType]} ${notTouch ? styles.notTouch : ''}`}
				style={{width: tableWidth}}
			>
				{columns && columns.map((column, index) => {
					return (
						<div className={`${styles.productHeadItem} ${styles[column.code]}`} key={index}
						     style={{width: columnsWidth[column.code]}}>
							<div className={`${styles.productHeadItemContent}`}>
								<span>{column.name}</span>
							</div>
							<div className={`${styles.productHeadItemLine} ${activeItem === column.code ? styles.active : ''}`}
							     onMouseDown={(e) => {
								     changeColumnWidth(e, column.code)
							     }}></div>
						</div>
					)
				})}
			</div>
		</>
	)
}

export default OrderListHead;