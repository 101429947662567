import {useCallback, useEffect, useState} from "react";
import {api} from "../api";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoader, CustomInput, SwitchButton, Toast} from "../utils/ui";
import SelectDropdown from "../pages/main/components/SelectDropdown";
import toast from "react-hot-toast";

const EditCatalogModal = () =>{
	const dispatch = useDispatch();

	//Достаем данные из стейта для изменения каталога
	const modalData = useSelector(state => state.multiModal.modals[0].modalData);

	//Состояния для инпутов
	const [name, setName] = useState(modalData.name)
	const [show, setShow] = useState(modalData.isShow)
	const code = modalData.code
	const [currencyData, setCurrencyData] = useState([])
	const [currentCurrency, setCurrentCurrency] = useState('')
	const [defaultCurrency, setDefaultCurrency] = useState('')

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')

	const getData = useCallback(async () => {
		try{

			const currencyList = await api.cabinetApi.getCurrencySelect({show_name: true});

			if(currencyList.status === true){
				setCurrencyData(()=>{
					return currencyList?.select?.list?.map((item) => {
						return {
							name: `${item.currency} - ${item.name}`,
							value: item.currency
						}
					})
				})
			}

			currencyList?.select?.list?.map((item) => {
				if(item.currency === modalData.currency){
					setDefaultCurrency(`${item.currency} -  ${item.name}`)
				}
			})

		}catch (e) {
			console.log(e)
		}
	}, [])

	useEffect(() => {
		getData();
	}, [getData]);


	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		//Создаем тело запроса
		let body = {
			"code":code,
			"name":name,
			"show":show,
			"currency": currentCurrency
		}

		try {
			const result = await api.cabinetApi.editCatalog(body);

			if(result.status === true) {
				const distributorCatalogList = await api.cabinetApi.getCatalogList();
				dispatch({type: "REFRESH_CATALOG_LIST", catalogList: distributorCatalogList.catalogs})
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})
				Toast(result.message, 'success');
			}else{
				Toast(result.message, 'error');
				setError(result.message)
			}

		} catch (error) {

			console.log(error);

		}finally {
			setTimeout(() => {
				setLoad(false)
			},700)

		}
	}


	// Меняем Show из switchCheckbox
	const checkboxHandler = (data) => {
		setShow(data.isChecked)
	}

	return(
		<div className={'i_add-catalog-modal'}>
			<div className="i_add-catalog-modal-title">
				<span>Изменить каталог</span>
			</div>
			<div className="i_add-catalog-modal-items">
				<div className="i_add-catalog-modal-code">
					<span>Код: {code}</span>
				</div>
				<div className={`i_add-catalog-modal-item`}>
					<CustomInput title={'Название каталога'} onChange={(e) => {setName(e)}} value={name}/>
				</div>
				{
					!modalData.isDistributor && (
						<>
							{/*Выбор валюты*/}
							<SelectDropdown defaultValue={defaultCurrency} item={currencyData} label={'Валюта каталога'}
							                onChange={({value}) => {
								                setCurrentCurrency(value)
							                }}/>
						</>
					)
				}
				{
					modalData.isDistributor && (
						<div className="i_add-catalog-modal-item item-switch">
							<SwitchButton inputChecked={show} checkBoxValue={checkboxHandler} title={'Открыть для внешнего доступа'}
							              value={''}/>
						</div>
					)
				}
			</div>

			<div className="i_add-catalog-modal-btn" onClick={formSubmit}>
				<ButtonLoader load={load} title={'Сохранить'}/>
			</div>

			{error !== '' ? <div className={`i_add-catalog-modal-error`}>
				<span>{error}</span>
			</div> : ''}
		</div>
	)
}

export default EditCatalogModal