import {useDispatch, useSelector} from "react-redux";
import styles from './Overlay.module.scss'
import {useNavigate} from "react-router-dom";
import {useUrlParams} from "../../../hooks";

const Overlay = () =>{
	const dispatch = useDispatch();
	const modalIsOpenLevel1 = useSelector(state => state.multiModal.modals[0].modalIsOpen);
	const modalIsOpenLevel2 = useSelector(state => state.multiModal.modals[1].modalIsOpen);
	const modalIsOpenLevel3 = useSelector(state => state.multiModal.modals[2].modalIsOpen);
	const popupIsOpen = useSelector(state => state.multiPopup.popupIsOpen);
	const showTabBar = useSelector(state => state.menu.showTabBar);
	const showTabBarCatalog = useSelector(state => state.menu.showTabBarCatalog);

	const { removeParam } = useUrlParams();

	const changeShowOverlay = (lvl) =>{
		dispatch({type: "CLOSE_MODAL", modalLevel: lvl})
		dispatch({type: "CLEAR_NOTICE"})
		dispatch({type: "CLEAR_UPLOADED_IMAGES"})
		dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
		dispatch({type: "SHOW_TABBAR_MORE", showTabBar: false})
		dispatch({type: "SHOW_TABBAR_CATALOG", showTabBarCatalog: false})

		if(lvl === 1){
			dispatch({type: "SET_MARKUPS_SELECTED_CATEGORY", selectedCategory: []});
			dispatch({type: "SET_MARKUPS_SELECTED_PRODUCT", selectedProduct: []});

			removeParam('product')
		}

	}

	return(
		<>
			<div
				className={`${styles.overlay} ${styles.overlayLevel_1} ${modalIsOpenLevel1 || showTabBar || popupIsOpen || showTabBarCatalog ? '' : styles.hide}`}
				onClick={()=> changeShowOverlay(1)}></div>
			<div
				className={`${styles.overlay} ${styles.overlayLevel_2} ${modalIsOpenLevel2 ?  '' : styles.hide}`}
				onClick={()=> changeShowOverlay(2)}></div>
			<div
				className={`${styles.overlay} ${styles.overlayLevel_3} ${modalIsOpenLevel3  ? '' : styles.hide}`}
				onClick={()=> changeShowOverlay(3)}></div>
		</>
	)
}

export default Overlay;