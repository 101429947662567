const initialState = {
	distributor: {
		catalogList: {}
	},
	productList: {
		productList: [],
		offset: 0,
		nextOffset: 0,
		currentCategoryId: null,
		checkedProductList : []
	},
	source: {
		catalogList: {}
	},
	categoryList: {
		categoryList: null,
		currentCategoryId: null,
		currentCategoryName: null
	},
	catalogCode : null,
	distributor_id : null,
	isLoading: true,
	list:{
		columns:[],
		products: {
			productList: [],
			offset: 0,
			nextOffset: 0,
			currentCategoryId: null,
			checkedProductId: [],
			showActiveItem : false,
		},
		tableItemWidth: null,
		tableWidth: null,
		tableMinWidth: null,
		productDetail: null,
		offset: 0,
		nextOffset: 0,
		currentCategoryId: null
	}
};

export const catalogReducer = (state = initialState, action) => {
	switch (action.type) {
		case "REFRESH_CATALOG_LIST":
			return {
				...state,
				distributor: {
					...state.distributor,
					catalogList: action.catalogList
				}
			};
		case "GET_PRODUCT_LIST":
			return {
				...state,
				productList: {
					...state.productList,
					productList: action.productList,
					offset: action.offset,
					nextOffset: action.nextOffset,
					currentCategoryId: action.currentCategoryId
				}
			};
		case "GET_MORE_PRODUCT_LIST":
			return {
				...state,
				productList: {
					...state.productList,
					productList: [...state.productList.productList, ...action.productList],
					offset: action.offset,
					nextOffset: action.nextOffset
				}
			};
		case "REFRESH_SOURCE_CATALOG_LIST":
			return {
				...state,
				source: {
					...state.source,
					catalogList: action.catalogList
				}
			};
		case "SET_CATALOG_CATEGORY_LIST" : {
			return{
				...state,
				categoryList: {
					...state.categoryList,
					categoryList: action.categoryList
				}
			}
		}
		case "SET_CATALOG_CURRENT_CATEGORY_ID" : {
			return{
				...state,
				categoryList: {
					...state.categoryList,
					currentCategoryId: action.currentCategoryId
				}
			}
		}
		case "SET_CATALOG_CURRENT_CATEGORY_NAME" : {
			return{
				...state,
				categoryList: {
					...state.categoryList,
					currentCategoryName: action.currentCategoryName
				}
			}
		}
		case "SET_CATALOG_CODE" : {
			return {
				...state,
				catalogCode: action.catalogCode
			}
		}
		case "SET_DISTRIBUTOR_ID" : {
			return {
				...state,
				distributor_id: action.distributor_id
			}
		}
		case "CLEAR_CATALOG" : {
			return {
				distributor: {
					catalogList: {}
				},
				productList: {
					productList: [],
					offset: 0,
					nextOffset: 0,
					currentCategoryId: null
				},
				source: {
					catalogList: {}
				},
				categoryList: {
					categoryList: null,
					currentCategoryId: null
				},
				catalogCode : null,
				distributor_id: null,
				list:{
					columns:[],
					products: {
						productList: [],
						offset: 0,
						nextOffset: 0,
						currentCategoryId: null,
						checkedProductId: [],
					},
					offset: 0,
					nextOffset: 0,
					currentCategoryId: null
				}
			}
		}
		case "SET_CHECKED_PRODUCT_LIST" : {
			return {
				...state,
				productList: {
					...state.productList,
					checkedProductList: action.checkedProductList
				}
			}
		}

		// НОВЫЕ МЕТОДЫ
		case "SET_CATALOG_LIST_COLUMNS":{
			return {
				...state,
				list:{
					...state.list,
					columns: action.columns
				}
			}
		}
		case "SET_CATALOG_LIST_PRODUCTS":{
			return {
				...state,
				list:{
					...state.list,
					products: {
						...state.list.products,
						productList: action.products,
						offset: action.offset,
						nextOffset: action.nextOffset,
						currentCategoryId: action.currentCategoryId
					}
				}
			}
		}
		case "SET_CATALOG_LIST_PRODUCTS_MORE":{
			return {
				...state,
				list:{
					...state.list,
					products: {
						...state.list.products,
						productList: [...state.list.products.productList, ...action.products],
						offset: action.offset,
						nextOffset: action.nextOffset
					}
				}
			}
		}
		case 'SET_CATALOG_SELECTED_PRODUCT_ID':
			return {
				...state,
				list: {
					...state.list,
					products: {
						...state.list.products,
						checkedProductId: action.checkedProductId
					}
				}
			}
		case 'SET_CATALOG_LIST_PRODUCT_DETAIL':
			return{
				...state,
				list:{
					...state.list,
					productDetail: action.productDetail
				}
			}
		case 'SET_CATALOG_LIST_PRODUCT_SHOW_ACTIVE_ITEM':
			return {
				...state,
				list:{
					...state.list,
					products: {
						...state.list.products,
						showActiveItem: action.showActiveItem
					}
				}
			}

		case 'SET_CATALOG_LIST_TABLE_ITEM_WIDTH':
			return {
				...state,
				list:{
					...state.list,
					tableItemWidth: action.tableItemWidth
				}
			}

		case 'SET_CATALOG_LIST_TABLE_WIDTH':
			return {
				...state,
				list:{
					...state.list,
					tableWidth: action.tableWidth
				}
			}

		case 'SET_CATALOG_LIST_TABLE_MIN_WIDTH':
			return {
				...state,
				list:{
					...state.list,
					tableMinWidth: action.tableMinWidth
				}
			}

		case 'SET_CATALOG_IS_LOADING':
			return {
				...state,
				isLoading: action.isLoading
			}

		default:
			return state;
	}
};
