import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {api} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";
import SelectDropdown from "../../pages/main/components/SelectDropdown";
import {DateTimePicker, CustomInput, ButtonLoader, SwitchButton, SelectButton, Toast} from "../../utils/ui";
import SelectMarkupUnit from "./SelectMarkupUnit/SelectMarkupUnit";
import toast from "react-hot-toast";

const AddMarkupElement = () => {
	// Redux методы
	const dispatch = useDispatch();
	const catalogCode = useSelector(state => state.markups.catalogCode)
	const selectedProducts = useSelector(state => state.markups.selectedProduct)
	const selectedCategory = useSelector(state => state.markups.selectedCategory)
	const productList = useSelector(state => state.markups.list.products)
	const catalogCurrency = useSelector(state => state.markups.catalogCurrency)

	//Состояния для инпутов
	const [name, setName] = useState('')
	const [active, setActive] = useState(true)
	const [selectPeriod, setSelectPeriod] = useState('unlimited')
	const [activeFrom, setActiveFrom] = useState(null)
	const [activeTo, setActiveTo] = useState(null)
	const [selectMarkupUnit, setSelectMarkupUnit] = useState('percent')
	const [markupValue, setMarkupValue] = useState(null)
	const [priorityValue, setPriorityValue] = useState('1')
	const [conditionValue, setConditionValue] = useState(null)

	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false);
	//Нужно для перевода
	const {t} = useTranslation();

	// Меняем Show из switchCheckbox
	const checkboxHandler = (data) => {
		setActive(data.isChecked)
	}

	//Чистим выбранные категории и товары при открытии добаления наценки
	useEffect(() => {
		dispatch({type: "SET_MARKUPS_SELECTED_CATEGORY", selectedCategory: []});
		dispatch({type: "SET_MARKUPS_SELECTED_PRODUCT", selectedProduct: []});
	}, []);

	// Открытие модального окна выбора категорий и товаров\

	const openSelectCategoryAndProductModal = () => {
		dispatch({type: "SET_MARKUPS_CURRENT_CATEGORY_ID", currentCategoryId: 0})
		dispatch({type: "OPEN_MODAL", modalType: 'selectCategoryAndProductModal', modalLevel: 2, modalWidth: 'large'})
	}

	const handleSelectMarkupUnit = (data) => {
		setSelectMarkupUnit(data.type)
		setMarkupValue(data.value)
	}

	const periodData = [
		{
			name: "Неограниченно",
			value: 'unlimited'
		},
		{
			name: "Период",
			value: 'limited'
		}
	]

	const conditionsData = [
		{
			name: "Весь каталог",
			value: 'all'
		},
		{
			name: "Категории и товары",
			value: 'categoryAndProduct'
		}
	]


	const changeCurrentPeriod = ({value}) => {
		setSelectPeriod(value)
	}

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		let body = {
			"catalog_code": catalogCode,
			"active": active,
			"name": name,
			"markup": markupValue,
			"markup_unit": selectMarkupUnit === '%' ? selectMarkupUnit : catalogCurrency,
			"priority": Number(priorityValue),
			"segmentation": {"dealer":"default", "distributor":"default"},
		};

		// Проверка условия наценки
		if(conditionValue === 'all'){
			body.condition = {"catalog_all": true}
		}else{
			if(selectedProducts.length > 0 || selectedCategory.length > 0){
				body.condition = {
					"categories": selectedCategory,
					"products": selectedProducts
				}
			}else{
				body.condition = {"catalog_all": true}
			}
		}

		// Если период активности ограничен, добавляем даты, иначе оставляем пустыми
		if(selectPeriod === 'limited'){
			body.date_from = activeFrom
			body.date_to = activeTo
		}else {
			body.date_from = ''
			body.date_to = ''
		}

		// Делаем запрос
		try {
			const response = await api.cabinetApi.addMarkup(body);

			if (response.status === true) {

				dispatch({type: "SET_MARKUPS_LIST_PRODUCTS", products: [...productList, response.markups[0]]})
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})
				Toast(response.message, 'success');
			} else {
				Toast(response.message, 'error');
				setError(response.message)
			}

		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				setLoad(false)
			}, 700)
		}
	}

	return (
		<>
			{loading && (
				<div className={`i_add-category-modal-loading`} style={{height: "100%"}}>
					<Skeleton count={1} height={'100%'} borderRadius={10}/>
				</div>
			)}
			{
				!loading && (
					<div className={'i_add-category-modal'}>
						<div className="i_add-category-modal-title">
							<span>Добавление наценки</span>
						</div>
						<div className="i_add-category-content">
							<div className="i_add-category-items">

								<div className={`i_add-catalog-modal-item item-switch`}>
									<SwitchButton inputChecked={active} checkBoxValue={checkboxHandler} title={'Активно'} value={''}/>
								</div>

								<div className={`i_add-catalog-modal-item`}>
									<CustomInput title={'Название наценки'} value={name} onChange={(e) => {
										setName(e)
									}}/>
								</div>

								<div className={`i_add-catalog-modal-item`}>
									<SelectDropdown defaultValue={'Неограниченно'}
									                label={'Период активности'} item={periodData}
									                onChange={changeCurrentPeriod}/>
								</div>

								{
									selectPeriod && selectPeriod === 'limited' && (
										<>
											<div className={`i_add-catalog-modal-item`}>
												<DateTimePicker title={'Дата начала'} onChange={(e) => setActiveFrom(e)}/>
											</div>
											<div className={`i_add-catalog-modal-item`}>
												<DateTimePicker title={'Дата окончания'} onChange={(e) => setActiveTo(e)}/>
											</div>
										</>
									)
								}

								{/*Тип наценки*/}

								<SelectMarkupUnit onChange={handleSelectMarkupUnit}/>

								<div className={`i_add-catalog-modal-item`}>
									<CustomInput title={'Приоритет'} type={'number'} value={priorityValue}
									             onChange={(e) => {
										             setPriorityValue(e)
									             }}/>
								</div>

								<SelectDropdown defaultValue={'Весь каталог'} label={'Условие'}
								                item={conditionsData} onChange={({value}) => {
									setConditionValue(value)
								}}/>

								{conditionValue === 'categoryAndProduct' &&
									<div className="i_add-catalog-modal-item">
										<SelectButton title={'Выбрать категории и товары'} onClick={openSelectCategoryAndProductModal}/>
									</div>
								}

							</div>

							<div className="i_add-category-modal-btn" onClick={formSubmit}>
								<ButtonLoader load={load} title={t('I_SELECT_ROLE_FORM_BTN')}/>
							</div>
							{error !== '' ?
								<div dangerouslySetInnerHTML={{__html: error}} className={`i_add-category-modal-error`}></div> : ''
							}
						</div>
					</div>
				)
			}
		</>
	)
}

export default AddMarkupElement