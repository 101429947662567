import {useCallback, useEffect, useState} from "react";
import {api} from "../../../api";
import {useDispatch, useSelector} from "react-redux";
import {ButtonLoader, DoubleInput, Toast} from "../../../utils/ui";
import styles from '../EditDetailProductModal.module.scss'
import SettingsPriceList from "../../components/SettingsPriceList/SettingsPriceList";
import toast from "react-hot-toast";

const EditDetailPriceProduct = () => {
	const productDetail = useSelector(state => state.catalog.list.productDetail)
	const [priceList, setPriceList] = useState([])
	const [btnLoad, setBtnLoad] = useState(false)
	const {productList, offset, nextOffset, currentCategoryId} = useSelector(state => state.catalog.list.products)
	const dispatch = useDispatch()

	const editPriceListItem = (index, name, value) => {
		const updatedPriceList = priceList.map(item => {
			if (priceList.indexOf(item) === index) {
				return {
					...item,
					price: name,
					currency: value
				}
			}
			return item
		});

		setPriceList(updatedPriceList)
	}

	const formSubmit = async () => {
		setBtnLoad(true)

		// Формируем данные для отправки на сервер, чистим от лишних данных
		const dataToFetch = priceList.map(item => {
			return {
				type_code: item.type_code,
				price: item.price,
				currency: item.currency
			}
		})

		try {

			const response = await api.cabinetApi.updateCatalogProductPrice({id: productDetail.id, prices: dataToFetch})
			const updatedProductDetail = await api.cabinetApi.getCatalogProductDetail({id: productDetail.id})

			if (response.status === true) {

				// Обновляем детальную информацию о товаре
				Toast(response.message, 'success');

				//Обновляем список товаров в каталоге, чтобы изменения отобразились
				const updatedProductList = productList.map((item) => {
					if(item.id === productDetail.id){
						return {...item,
							price: response?.product?.price,
							rrp: response?.product?.rrp,
							active: updatedProductDetail?.product?.active
						}
					}else{
						return item;
					}
				})

				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: updatedProductList, nextOffset: nextOffset, offset: offset, currentCategoryId: currentCategoryId})
				dispatch({type: "SET_CATALOG_LIST_PRODUCT_DETAIL", productDetail: updatedProductDetail.product})
				dispatch({type: "CLOSE_MODAL", modalLevel: 2})

			} else {
				Toast(response.message, 'error');
			}

			setBtnLoad(false)

		} catch (e) {
			console.log(e)
		}
	}

	const getData = useCallback(async () => {
		try {
			const response = await api.cabinetApi.getCatalogProductPriceList({id: productDetail.id})

			if (response.status === true) {
				setPriceList(response.price_list)
			}

		} catch (e) {
			console.log(e)
		}
	},[])

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<>
			{
				priceList.length > 0 && priceList.map((item, index) => {
					return (
						<div className={styles.modalItem}>
							<SettingsPriceList
								key={index}
								index={index} // Передаем индекс для обновления конкретного элемента
								name={item.price}
								value={item.currency}
								firstInputWidth={'78%'}
								firstInputTitle={item.type_name}
								firstInputConfig={{type: 'number'}}
								secondInputWidth={'20%'}
								secondInputTitle={'Валюта'}
								secondInputConfig={{type: 'textOnly', maxLength: 3}}
								onChange={editPriceListItem}
							/>
						</div>
					)
				})
			}

			<ButtonLoader load={btnLoad} onClick={formSubmit} title={'Сохранить'}/>
		</>
	)
}

export default EditDetailPriceProduct;