import { useDispatch, useSelector } from "react-redux";
import { api } from "../../api";
import toast from "react-hot-toast";
import {Toast} from "../../utils/ui";


// Функция удаления товара из корзины
const useDeleteBasketItem = () => {
	// Получаем данные из стейта
	const dispatch = useDispatch();
	const productList = useSelector(state => state.catalog.list.products.productList);
	const nextOffset = useSelector(state => state.catalog.list.products.nextOffset);
	const currentCategoryId = useSelector(state => state.catalog.list.products.currentCategoryId);
	const productDetail = useSelector(state => state.catalog.list.productDetail);

	// Асинхронная функция удаления товара из корзины
	const deleteBasketItem = async (productID, hideToast) => {
		try {
			const response = await api.basketApi.deleteBasketItem({ product_id: productID });

			if (response.status === true) {
				// Получаем обновленные данные
				const updatedBasketList = await api.basketApi.getBasketList();
				const updatedBasketCount = await api.basketApi.getBasketCount();

				const updatedProductList = productList.map((item) =>
					item.id === productID
						? { ...item, basket_quantity: 0, max_quantity: response.max_quantity }
						: item
				);

				const updatedProductDetail = { ...productDetail, basket_quantity: 0, max_quantity: response.max_quantity };

				// Обновляем стейт
				dispatch({ type: "SET_BASKET_LIST", basketList: updatedBasketList.products });
				dispatch({ type: "SET_BASKET_TOTAL_PURCHASE", basketTotalPurchase: updatedBasketList.total_purchase });
				dispatch({ type: "SET_BASKET_TOTAL_RETAIL", basketTotalRetail: updatedBasketList.total_retail });
				dispatch({ type: "SET_BASKET_COUNT", basketCount: updatedBasketCount.count });
				dispatch({
					type: "SET_CATALOG_LIST_PRODUCT_DETAIL",
					productDetail: updatedProductDetail,
				});
				dispatch({
					type: "SET_CATALOG_LIST_PRODUCTS",
					products: updatedProductList,
					nextOffset,
					currentCategoryId,
				});

				// Выводим сообщение в уведомлении
				!hideToast && Toast(response.message, 'success');

			} else {
				// Выводим сообщение в уведомлении
				Toast(response.message, 'error');
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch({ type: "SET_BASKET_LOADER", basketLoader: false });
		}
	};

	return { deleteBasketItem };
};

export default useDeleteBasketItem;
