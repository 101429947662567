import styles from './RadioButton.module.scss'
import React from "react";

const RadioButton = ({title, value, inputChecked, radioValue}) => {

	function handleRadioChange(e){
		radioValue({
			value: e.target.value,
			isChecked: e.target.checked
		})
	}

	return(
		<label className={styles.radioButton}>
			<input
				type="radio"
				value={value}
				checked={inputChecked}
				onChange={handleRadioChange}
			/>
			<div className={styles.radioButtonInput}></div>
			{title && (
				<span>{title}</span>
			)}
		</label>
	)
}

export default RadioButton;