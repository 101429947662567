import styles from "../style.module.scss";
import {useEffect, useState} from "react";
import {CustomInput, SwitchButton} from "../../../../utils/ui";

const OrderIntegration = ({changeOrderIntegrationData}) => {
	const [kaspiApiKey, setKaspiApiKey] = useState('');
	const [integrationActive, setIntegrationActive] = useState(false);

	// Пока мы никак не можем менять значение так как функция в разработке
	function changeIntegrationActive({isChecked}) {
		setIntegrationActive(false);
	}

	useEffect(() => {
		changeOrderIntegrationData({
			kaspiApikey: kaspiApiKey,
			active: integrationActive
		})
	}, [kaspiApiKey, integrationActive]);

	return(
		<div className={styles.integrationItems}>

			<div className={styles.integrationItemDisabled}>
				<SwitchButton title={'Активно'} value={'product_on'} checkBoxValue={changeIntegrationActive} inputChecked={integrationActive} />
			</div>

			<CustomInput title={'Api-ключ от Kaspi.kz'} type={'text'} value={kaspiApiKey} onChange={(e) => setKaspiApiKey(e)} disabled={true} />
		</div>
	)
}

export default OrderIntegration;