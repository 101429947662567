import {CustomInput, EmptyBlock, SwitchButton} from "../../../../utils/ui";
import RedirectToCatalog from "../../../../utils/ui/EmptyBlock/components/RedirectToCatalog";
import SelectDropdown from "../../../../pages/main/components/SelectDropdown";
import {useEffect, useState} from "react";
import styles from './style.module.scss'

const ProductIntegration = ({catalogList, dataSelectedCatalog, dataDefaultCatalog, changeProductIntegrationData}) => {

	const [defaultCatalog, setDefaultCatalog] = useState(null);
	const [merchantId, setMerchantId] = useState('');
	const [minQuantity, setMinQuantity] = useState(1);
	const [selectedCatalog, setSelectedCatalog] = useState(null);
	const [integrationActive, setIntegrationActive] = useState(true);

	useEffect(() => {
		setDefaultCatalog(dataDefaultCatalog);
		setSelectedCatalog(dataSelectedCatalog);
	}, [dataSelectedCatalog, dataDefaultCatalog])

	function changeSelectedCatalog(e) {
		setSelectedCatalog(e.value);
	}

	function changeIntegrationActive({isChecked}) {
		setIntegrationActive(isChecked);
	}

	useEffect(() => {
		changeProductIntegrationData({
			merchantId: merchantId,
			minQuantity: minQuantity,
			selectedCatalog: Number(selectedCatalog),
			active: integrationActive
		})
	}, [merchantId, minQuantity, selectedCatalog, integrationActive])


	return(
		<div className={styles.integrationItems}>
			<SwitchButton title={'Активно'} value={'product_on'} checkBoxValue={changeIntegrationActive} inputChecked={integrationActive} />

			<CustomInput title={'Merchant ID'} type={'text'} onChange={(e) => setMerchantId(e)} value={merchantId} required={true}/>

			{catalogList.length === 0 ? (
				<EmptyBlock title={'Каталоги отсутствуют.'} fullWidth={true} Content={RedirectToCatalog} />
			) : (
				<SelectDropdown label={'Выберите каталог для выгрузки'} item={catalogList} onChange={changeSelectedCatalog} defaultValue={defaultCatalog} />
			)}

			<CustomInput title={'Ограничение по остатку'} type={'number'} value={minQuantity} onChange={(e) => setMinQuantity(e)}
			             helpTitle={'Не выгружаются товары, если остаток меньше указанного значения'} required={true}/>
		</div>
	)
}

export default ProductIntegration;