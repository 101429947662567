import styles from "./GenerateBasketComModal.module.scss";
import {ButtonLoader, CustomInput, ScrollBox} from "../../utils/ui";
import {api} from "../../api";
import {useState} from "react";
import Cookies from "js-cookie";

const GenerateBasketComModal = () => {
	const cookiesData = Cookies.get('KP_TEXT')

	const [companyName, setCompanyName] = useState('')
	const [text, setText] = useState(cookiesData ? cookiesData : '')
	const [error, setError] = useState('')
	const [btnLoad, setBtnLoad] = useState(false)


	// Функция для формирования коммерческого предложения
	async function generateBasketCom(){
		setBtnLoad(true)
		if(companyName === ''){
			setError('Поле обязательно для заполнения')
			setBtnLoad(false)
			return
		}

		try{

			Cookies.set('KP_TEXT', text,  {expires: 100})

			const response = await api.basketApi.generateBasketCom({language: "ru", company_name: companyName, text: text})

			// console.log(response)
		}catch (e) {
			console.log(e)
		}finally {
			setBtnLoad(false)
		}
	}

	return(
		<>
			<div className={styles.modal}>
				<div className={styles.modalTitle}>
					<span>Формирование коммерческого предложения</span>
				</div>
				<div className={styles.modalContent}>
					<ScrollBox style={{display:'flex', flexDirection: 'column', gap:20, maxHeight: '80vh'}}>
						<div className={styles.modalContentItem}>
							<CustomInput title={'Название компании клиента'} onChange={(e) => {
								setCompanyName(e)
							}} required={true} error={error}/>
						</div>
						<div className={styles.modalContentItem}>
							<CustomInput title={'Комментарий в КП'} onChange={(e) => {
								setText(e)
							}} isTextarea={true} value={text}/>
						</div>
					</ScrollBox>
				</div>
				<div className={styles.modalFooterButton}>
					<ButtonLoader title={'Сформировать КП'} onClick={generateBasketCom} load={btnLoad}/>
				</div>
			</div>
		</>
	);
}

export default GenerateBasketComModal;