const initialState = {
	basketCount: null,
	basketList : [],
	basketTotalPurchase: null,
	basketTotalRetail: null,
	basketLoader: false
}

export const basketReducer = (state = initialState, action) => {
	switch (action.type) {

		case "SET_BASKET_COUNT":
			return{
				...state,
				basketCount: action.basketCount
			}

		case "SET_BASKET_LIST":
			return{
				...state,
				basketList: action.basketList
			}

		case "SET_BASKET_TOTAL_PURCHASE":
			return{
				...state,
				basketTotalPurchase: action.basketTotalPurchase
			}

		case "SET_BASKET_TOTAL_RETAIL":
			return{
				...state,
				basketTotalRetail: action.basketTotalRetail
			}

		case "SET_BASKET_LOADER":
			return{
				...state,
				basketLoader: action.basketLoader
			}

		default:
			return state;
	}
}