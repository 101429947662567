import {useSelector} from "react-redux";
import Profile from "../Profile/Profile";
import SearchBar from "../SearchBar";
import MultiTools from "../../../components/MultiTools";
import Notice from "../Notice/Notice";
import Company from "../Company/Company";
import Basket from "../Basket/Basket";

const Header = () => {
	const title = useSelector(state => state.appData.title)
	const role = useSelector(state => state?.userData?.data?.entity?.type)

	return (
		<div className={'header'}>
			<div className="header-left">
				<div className="header-title">
					<span>{title}</span>
				</div>
			</div>

			<div className="header-right">
				<MultiTools />
				<SearchBar/>
				<Notice />
				{ role === 'dealer' && <Basket /> }
				<div className="header-items">
					<Company />
					<Profile/>
				</div>
			</div>
		</div>
	);
}

export default Header;