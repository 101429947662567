import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
	accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {ScrollBox} from "../../../../../../utils/ui";



const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	fontFamily: "Montserrat , sans-serif",
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&::before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	// backgroundColor: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	gap: '15px',
	[`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
		{
			transform: 'rotate(90deg)',
		},
	[`& .${accordionSummaryClasses.content}`]: {
		marginLeft: theme.spacing(1),
	},
	...theme.applyStyles('dark', {
		backgroundColor: 'rgba(255, 255, 255, .05)',
	}),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
}));

export default function MainDealerStartDashboard({items}) {
	const [expanded, setExpanded] = React.useState('panel0');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<ScrollBox>
			{
				items.map((item, index) => {
					return(
						<Accordion className={'i_dealer-start-accordion'} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
							<AccordionSummary aria-controls={`panel${index}d-content`} id={`panel${index}d-header`}>
								<Typography component="span" className={'i_dealer-start-accordion-head'}>{item.title}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<div dangerouslySetInnerHTML={{__html: item.html}} className={`i_dealer-start-accordion-title`}></div>
							</AccordionDetails>
						</Accordion>
					)
				})
			}
		</ScrollBox>
	);
}