import styles from './SettingsPriceList.module.scss'
import {CustomInput} from "../../../utils/ui";
import {useCallback, useEffect, useState} from "react";
import SelectDropdown from "../../../pages/main/components/SelectDropdown";
import {api} from "../../../api";

const SettingsPriceList = ({onChange ,firstInputTitle , firstInputConfig, index, name, value}) => {

	const [inputName, setInputName] = useState(name);
	const [selectCurrencyData, setSelectCurrencyData] = useState([])
	const [currentCurrency, setCurrentCurrency] = useState(value)
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		if (isChanged) {
			onChange(index, inputName, currentCurrency);
			setIsChanged(false); // сбрасываем флаг после обновления
		}
	}, [isChanged, inputName, currentCurrency, index, onChange]);

	const handleChangeName = (e) =>{
		setInputName(e);
		setIsChanged(true); // Помечаем, что значение изменилось
	}

	const handleChangeValue = (e) => {
		setCurrentCurrency(e);
		setIsChanged(true); // Помечаем, что значение изменилось
	}

	const getData = useCallback(async () => {
		try{
			const response = await api.cabinetApi.getCurrencySelect()

			setSelectCurrencyData(()=>{
				return response?.select?.currencies?.map((item) => {
					return {
						name: item,
						value: item
					}
				})
			})
		}catch (e) {
			console.log(e)
		}
	}, [])

	useEffect(() => {
		getData()
	}, [getData]);

	return (
		<div className={styles.SettingsPriceList}>
			<div className={styles.SettingsPriceListItem}>
				<CustomInput
					title={firstInputTitle}
					value={inputName}
					onChange={handleChangeName}
					{...firstInputConfig}
				/>
			</div>

			<div className={styles.SettingsPriceListItem}>
				<SelectDropdown defaultValue={currentCurrency} item={selectCurrencyData} label={'Валюта'} onChange={({value}) => {
					handleChangeValue(value)
				}}/>
			</div>
		</div>
	);
}

export default SettingsPriceList;