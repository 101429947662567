import {useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useDispatch, useSelector} from "react-redux";

const ImagesUploader = () => {
	// Состояние для хранения списка файлов
	const [files, setFiles] = useState([])
	const uploadedImages = useSelector(state => state.uploadImage.uploadedImages)

	// Эффект, который срабатывает при изменении списка загруженных изображений
	useEffect(() => {
		setFiles(uploadedImages)
	}, [uploadedImages]);

	// Получаем функцию dispatch из Redux для отправки действий
	const dispatch = useDispatch()


	// Функция, которая вызывается при drop файлов в зону загрузки
	const onDrop = acceptedFiles => {
		acceptedFiles.forEach(file => {
			// Создаем FileReader для чтения файла
			const reader = new FileReader()

			// Обработка событий ошибки и отмены чтения файла
			reader.onabort = () => console.log("file reading was aborted")
			reader.onerror = () => console.log("file reading has failed")

			// Когда файл успешно прочитан
			reader.onload = () => {
				const binaryStr = reader.result // Результат чтения файла (в формате base64)

				// Проверка, есть ли файл с таким же именем уже в списке
				if(files && files?.length !== 0){
					const isExist = files.some(item => item.name === file.name)
					if(isExist) return // Если файл уже существует, не добавляем его
				}

				// Создаем объект данных для нового файла
				const data = {
					name: file.name, // Имя файла
					data: binaryStr, // Данные файла (base64)
					file: file // Сам файл
				}

				// Обновляем состояние files, добавляя новый файл
				setFiles(prevState => Array.isArray(prevState) ? [...prevState, data] : [data])
			}

			// Чтение файла в формате DataURL (base64)
			reader.readAsDataURL(file)
		})
	}

	// Настройки dropzone
	const {
		getRootProps,   // Функция для получения свойств корневого элемента dropzone
		getInputProps,  // Функция для получения свойств input элемента
		isDragActive,   // Состояние: активен ли драг-н-дроп
	} = useDropzone({onDrop}) // Передаем функцию onDrop в useDropzone

	// Эффект, который срабатывает при изменении списка файлов
	useEffect(() => {
		if(!files || files.length === 0) return
		// Отправляем файлы в Redux store через dispatch
		dispatch({type: "SET_UPLOADED_IMAGES", uploadedImages: files})
	}, [files]); // Этот эффект зависит от переменной files

	return (
		<div className="i_images-uploader">
			<div className="zone">
					<div className="drop-zone" {...getRootProps()}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<div className="drop-files">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									height="50"
									width="50"
									fill="currentColor"
								>
									<path
										d="M1 14.5C1 12.1716 2.22429 10.1291 4.06426 8.9812C4.56469 5.044 7.92686 2 12 2C16.0731 2 19.4353 5.044 19.9357 8.9812C21.7757 10.1291 23 12.1716 23 14.5C23 17.9216 20.3562 20.7257 17 20.9811L7 21C3.64378 20.7257 1 17.9216 1 14.5ZM16.8483 18.9868C19.1817 18.8093 21 16.8561 21 14.5C21 12.927 20.1884 11.4962 18.8771 10.6781L18.0714 10.1754L17.9517 9.23338C17.5735 6.25803 15.0288 4 12 4C8.97116 4 6.42647 6.25803 6.0483 9.23338L5.92856 10.1754L5.12288 10.6781C3.81156 11.4962 3 12.927 3 14.5C3 16.8561 4.81833 18.8093 7.1517 18.9868L7.325 19H16.675L16.8483 18.9868ZM13 13V17H11V13H8L12 8L16 13H13Z"></path>
								</svg>
							</div>
						) : (
							<div className="drag-files">
								<span>Перетащите файлы сюда.</span>
							</div>
						)}
					</div>
			</div>
		</div>
	)
}

export default ImagesUploader;