import {api} from "../../../api";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {ReactSVG} from "react-svg";
import {Link, useHref, useNavigate} from "react-router-dom";
import menuSvg from '../../../images/sidebar/tabbar-menu.svg'
import Profile from "./Profile/Profile";
import Company from "./Company/Company";
import Basket from "./Basket/Basket";

const TabBar = () => {
	const dispatch = useDispatch(); // Используем useDispatch для отправки действий в store
	const [tabBarItem, setTabBarItem] = useState(null); // Локальный стейт для хранения элементов таббара
	const currentPath = useHref(); // Получаем текущий путь
	const role = useSelector(state => state.userData?.data?.entity?.type)
	const modalType = useSelector(state => state.multiModal.modals[0].modalType)

	// Получаем состояния showTabBar и showTabBarCatalog из store
	const showTabBar = useSelector(state => state.menu.showTabBar);
	const showTabBarCatalog = useSelector(state => state.menu.showTabBarCatalog);

	const navigate = useNavigate();

	// Асинхронная функция для получения меню с сервера
	const getMenu = async () => {
		try {
			const result = await api.cabinetApi.getMenu(); // Запрос меню с сервера
			dispatch({type: "SET_MENU", menu: result.menu}); // Отправка действия для установки меню в store
			setTabBarItem(result.menu); // Установка меню в локальный стейт
		} catch (error) {
			console.log(error); // Обработка ошибок
		}
	};

	// Функция для отображения/скрытия основного меню
	function showMoreMenu() {
		dispatch({type: "SHOW_TABBAR_CATALOG", showTabBarCatalog: false}); // Скрываем каталог
		dispatch({type: "SHOW_TABBAR_MORE", showTabBar: !showTabBar}); // Переключаем отображение основного меню
	}

	// Функция для отображения/скрытия каталога
	function showMoreCatalog() {
		dispatch({type: "SHOW_TABBAR_CATALOG", showTabBarCatalog: !showTabBarCatalog}); // Переключаем отображение каталога
		dispatch({type: "SHOW_TABBAR_MORE", showTabBar: false}); // Скрываем основное меню
	}

	// Функция для закрытия всех меню
	function closeTabBar(item){
		dispatch({type: "CLOSE_MODAL", modalLevel: 1})
		dispatch({type: "CLOSE_MODAL", modalLevel: 2})
		dispatch({type: "CLOSE_MODAL", modalLevel: 3})

		dispatch({type: "SHOW_TABBAR_CATALOG", showTabBarCatalog: false}); // Скрываем каталог
		dispatch({type: "SHOW_TABBAR_MORE", showTabBar: false}); // Скрываем основное меню

		if(item.is_modal){
			setTimeout(() => {
				switch (item.link) {
					case 'basket' :
						modalType !== 'basketModal' && dispatch({type: "OPEN_MODAL", modalLevel: 1, modalType: 'basketModal'});
						break;
					default:
						return false;
				}
			}, 300)
		}
	}

	// Получаем меню с сервера при загрузке компонента
	useEffect(() => {
		getMenu();
	}, []);

	return (
		<>
			{
				tabBarItem && ( // Отображаем таббар, если элементы меню загружены
					<div className={`i_tabbar ${role}`}>
						<div className="i_tabbar-items">
							{tabBarItem && tabBarItem.map((item, index) => {
								if (!item.mobile_more && !item.items) {
									return (
										<div key={index} className={`i_tabbar-item ${currentPath === item.link ? 'active' : ''}`}
										     onClick={() => {
											     closeTabBar(item)
										     }} // Закрываем таббар при клике
										>
											{/*Если у нас параметра is_modal нет то тогда мы делаем элемент с ссылкой, иначе мы делаем элемент без ссылки*/}
											{
												!item.is_modal ?
													<Link to={`${item.link}`}>
														<ReactSVG src={item.icon} className={'i_tabbar-item-svg'}/>
														<span>{item.name}</span>
													</Link>
													:
													<>
														<ReactSVG src={item.icon} className={'i_tabbar-item-svg'}/>
														<span>{item.name}</span>
													</>
											}
										</div>
									)
								} else if (item.items) {
									return (
										<div key={index} className={`i_tabbar-item`} onClick={showMoreCatalog}>
											<ReactSVG src={item.icon} className={'i_tabbar-item-svg'}/>
											<span>{item.name}</span>
										</div>
									)
								}
							})}

							{role === 'dealer' &&
								<div className={`i_tabbar-item menu`}>
								<Basket/>
									<span>Корзина</span>
								</div>
							}

							<div className={`i_tabbar-item menu`} onClick={showMoreMenu}>
								<ReactSVG src={`${menuSvg}`} className={'i_tabbar-item-svg'}/>
								<span>Меню</span>
							</div>
						</div>
						<div className={`i_tabbar-more ${showTabBar ? 'show' : ''}`}>
							<div className="header-items">
								<Company/>
								<Profile/>
							</div>
							<div className="i_tabbar-more-items">
								{tabBarItem && tabBarItem.map((item, index) => {
									if (item.mobile_more) {
										return (
											<div key={index} className={`i_tabbar-item ${currentPath === item.link ? 'active' : ''}`}
											     onClick={()=>{closeTabBar(item)}} // Закрываем таббар при клике
											>
												<Link to={`${item.link}`}>
													<ReactSVG src={item.icon} className={'i_tabbar-item-svg'}/>
													<span>{item.name}</span>
												</Link>
											</div>
										)
									}
									return null;
								})}
							</div>
						</div>
						<div className={`i_tabbar-catalogs ${showTabBarCatalog ? 'show' : ''}`}>
							{tabBarItem && tabBarItem.map((item, index) => {
								if (item.link === '/catalogs/') {
									return (
										<div key={index} className='i_tabbar-catalogs-items'>
											{item.items?.map((catalog, catalogIndex) => (
												<div key={catalogIndex} className={`i_tabbar-item ${currentPath === catalog.link ? 'active' : ''}`} onClick={()=>{closeTabBar(item)}}>
													<Link to={`${catalog.link}`}>
														<ReactSVG src={catalog.icon} className='i_tabbar-item-svg'/>
														<span>{catalog.name}</span>
													</Link>
												</div>
											))}
										</div>
									);
								}
								return null;
							})}
						</div>
					</div>
				)
			}
		</>
	)
}

export default TabBar;
