import {useDispatch, useSelector} from "react-redux";
import {api} from "../api";
import toast from "react-hot-toast";
import {Toast} from "../utils/ui";

const DeleteMarkupsElement = () => {
	const dispatch = useDispatch();
	const store = useSelector(store => store.markups);

	const deleteMarkupsElement = async () => {
		try{
			const response = await api.cabinetApi.deleteMarkups({id: store.list.checkedProductId});
			if(response.status === true){
				const updatedList = store.list.products.filter(item => !store.list.checkedProductId.includes(item.id));

				dispatch({type: "SET_MARKUPS_LIST_PRODUCTS", products: updatedList})
				dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})

				Toast(response.message, 'success');
			}
		}catch (err){
			console.log(err)
		}
	}

	return (
		<div className={'i_delete-distributor-catalog-popup'}>
			<div className="i_popup-title">
				<span>Удалить наценку?</span>
			</div>
			<div className="i_popup-buttons">
				<div className="i_popup-button-success" onClick={deleteMarkupsElement}>
					<button>Да</button>
				</div>
				<div className="i_popup-button-cancel" onClick={()=>{
					dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
				}}>
					<button>Нет</button>
				</div>
			</div>
		</div>
	)
}

export default DeleteMarkupsElement;