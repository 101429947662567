import {ButtonLoader, Toast} from "../../../utils/ui";
import {useEffect, useState} from "react";
import styles from '../MarketplaceConnectModal.module.scss';
import {api} from "../../../api";
import {useDispatch, useSelector} from "react-redux";

//MUI components
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProductIntegration from "../components/KaspiKz/ProductIntegration";
import OrderIntegration from "../components/KaspiKz/OrderIntegration";

const KaspiKzConnect = () => {
	const dispatch = useDispatch();

	const {marketCode, marketDescription} = useSelector(state => state.multiModal.modals[0].modalData);
	const marketplaces = useSelector(state => state.marketplaces.marketplacesList);

	const [catalogList, setCatalogList] = useState([]);
	const [selectedCatalog, setSelectedCatalog] = useState('');
	const [defaultCatalog, setDefaultCatalog] = useState('');

	const [currentIntegration, setCurrentIntegration] = useState('product');
	const [integrationProductData, setIntegrationProductData] = useState(
		{
			merchantId: '',
			minQuantity: 1,
			selectedCatalog: '',
			active: true
		}
	)
	const [integrationOrderData, setIntegrationOrderData] = useState({
		kaspiApiKey: '',
		active: false
	})

	// Получаем данные с сервера
	const getData = async () => {
		try {
			const distributorCatalogList = await api.cabinetApi.getCatalogList();
			const catalogList = distributorCatalogList.catalogs?.map(item => ({
				name: `${item.name} (${item.code})`,
				value: item.id,
			}));

			setCatalogList(catalogList);
			setSelectedCatalog(catalogList[0]?.value || '');
			setDefaultCatalog(catalogList[0]?.name || '');
		} catch (e) {
			console.log(e);
		}
	};

	// Подключение маркетплейса
	async function connectMarketplace() {
		try {
			const response = await api.marketplacesApi.addMarketplaceConnect({
				code: marketCode,
				product_on: integrationProductData.active,
				orders_on: integrationOrderData.active,
				catalog_id: integrationProductData.selectedCatalog,
				settings: {
					api_key: integrationOrderData.kaspiApiKey,
					merchant_id: integrationProductData.merchantId,
					min_quantity: integrationProductData.minQuantity
				}
			});

			if (response.status) {
				const updatedMarketplaces = marketplaces.map(item => {
					if (item.code === marketCode) {
						return {
							...item,
							connection_id: response.connection_id,
							active: true,
							file: response.file,
							settings: {
								api_key: integrationOrderData.kaspiApiKey,
								catalog_id: integrationProductData.selectedCatalog,
								product_on: integrationProductData.active,
								orders_on: integrationOrderData.active,
								merchant_id: integrationProductData.merchantId,
								min_quantity: integrationProductData.minQuantity
							},
						};
					}
					return item;
				});

				dispatch({ type: 'SET_MARKETPLACES_LIST', marketplacesList: updatedMarketplaces });
				dispatch({ type: 'CLOSE_MODAL', modalLevel: 1 });

				Toast(response.message, 'success');
			} else {
				Toast(response.message, 'error');
			}
		} catch (e) {
			console.log(e);
		}
	}

	// Меняем вкладку интеграции
	function handleChangeIntegrationTab(event, newValue) {
		setCurrentIntegration(newValue);
	}

	// Функция получения данных из интеграции товаров
	function changeProductIntegrationData(data) {
		setIntegrationProductData(data)
	}

	// Функция получения данных из интеграции заказов
	function changeOrderIntegrationData(data) {
		setIntegrationOrderData(data)
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<TabContext value={currentIntegration} classList="i_tabs">
				<TabList onChange={handleChangeIntegrationTab}
				         indicatorColor={'transparent'}
				         className={'i_tabs-header'}
				         variant="scrollable"
				         scrollButtons="auto">
					<Tab label="Интеграция товаров" value="product" className={'i_tabs-header-item'}/>
					<Tab label="Интеграция заказов (скоро)" value="order" className={'i_tabs-header-item'}/>
				</TabList>

				<TabPanel value={'product'} className={styles.tabContent}>
					<ProductIntegration catalogList={catalogList} dataSelectedCatalog={selectedCatalog} dataDefaultCatalog={defaultCatalog} changeProductIntegrationData={changeProductIntegrationData}/>
				</TabPanel>

				<TabPanel value={'order'} className={styles.tabContent}>
					<OrderIntegration changeOrderIntegrationData={changeOrderIntegrationData}/>
				</TabPanel>

			</TabContext>

			<div className={styles.marketDescription}>
				<span>{marketDescription}</span>
			</div>

			<div className={styles.modalBtn}>
				<ButtonLoader title={'Подключить'} onClick={connectMarketplace} load={false} />
			</div>
		</>
	);
};

export default KaspiKzConnect;
