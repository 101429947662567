import styles from './Basket.module.scss'
import { ReactComponent as BasketSvg } from "../../../../images/basket.svg";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../../../../api";
import {useCallback, useEffect} from "react";

const Basket = () => {

	const basketCount = useSelector(state => state.basket.basketCount);
	const dispatch = useDispatch();

	// Получаем количество товаров в корзине
	const getData = useCallback(async ()=>{
		try{
			const response = await api.basketApi.getBasketCount()
			dispatch({type: "SET_BASKET_COUNT", basketCount: response.count})

		}catch (e) {
			console.log(e)
		}
	},[])

	useEffect(() => {
		getData()
	}, [getData])

	function openModal(){
		dispatch({type: "OPEN_MODAL", modalType: 'basketModal', modalLevel: 1, modalWidth: 'large'});
	}

	return(
		<div className={`${styles.basket} i_basket`} onClick={openModal} >
			{basketCount !== null && basketCount !== 0 && (
				<div className={styles.basketCount}>{basketCount > 100 ? '99+' : basketCount}</div>
			)}
			<BasketSvg />
		</div>
	)
}

export default Basket;