import styles from './SettingsCurrencyEditModal.module.scss'
import {ButtonLoader, ScrollBox, Toast} from "../../utils/ui";
import {useCallback, useEffect, useState} from "react";
import {api} from "../../api";
import SettingsCurrencyList from "../components/SettingsCurrencyList/SettingsCurrencyList";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";

const SettingsCurrencyEditModal = () => {
	const [btnLoad, setBtnLoad] = useState(false);

	const currentSelect = useSelector(state => state.settings.currency.selected);

	const dispatch = useDispatch();

	const getData = useCallback(async () => {
		try {
			const currencyList = await api.cabinetApi.getCurrencyList();
			const currentSelect = await api.cabinetApi.getCurrencySelect();

			dispatch({type: "SET_SELECTED_CURRENCY", currencies: currentSelect.select.currencies, default: currentSelect.select.default})
			dispatch({type: "SET_CURRENCIES_LIST", currenciesList: currencyList.currencies})

		} catch (e) {
			console.log(e);
		}
	}, [])

	// Отправка данных
	const formSubmit = async () => {

		setBtnLoad(true);

		try{
			const response = await api.cabinetApi.setSettingsCurrency({currencies: currentSelect.currencies, default: currentSelect.default})
			const currency = await api.cabinetApi.getSettingsCurrencySelect();

			if(response.status === true) {
				Toast(response.message, 'success');

				dispatch({type: "CLOSE_MODAL", modalLevel: 1})

				dispatch({type: "SET_CURRENCY", currencies: currency.currencies, default: currency.default})

			}else{
				Toast(response.message, 'error');
			}

		}catch (e){
			console.log(e);
		}

		setBtnLoad(false);
	};

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<div className={styles.modal}>
			<div className={styles.modalTitle}>
				<span>Выбор используемых валют</span>
			</div>
			<SettingsCurrencyList />
			<ButtonLoader title={"Изменить"} load={btnLoad} onClick={formSubmit} />
		</div>
	);
};

export default SettingsCurrencyEditModal;