import '../styles/marketplacesPage.scss'
import {api} from "../../../../api";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MarketplacesItem from "../../components/MarketplacesItem/MarketplacesItem";

const MarketplacesPage = () => {

	const marketplaces = useSelector(state => state.marketplaces.marketplacesList)
	const dispatch = useDispatch();

	const getData = useCallback(async () => {
		try {
			const result = await api.marketplacesApi.getMarketplaceList();

			dispatch({type: 'SET_MARKETPLACES_LIST', marketplacesList: result.marketplaces})
		} catch (e) {
			console.log(e)
		}
	}, []);

	useEffect(() => {
		getData()
	}, [getData]);

	return (
		<div className={'i_marketplaces'}>
			<div className="i_marketplaces-items">
				{marketplaces && marketplaces.map((item, index) => {
					return (
						<MarketplacesItem item={item} key={index}/>
					)
				})}
			</div>
		</div>
	);
}

export default MarketplacesPage;