import {ReactComponent as InfoSvg} from "../../../../images/info.svg";
import {ReactComponent as EditSvg} from "../../../../images/catalogs/catalog-edit.svg";
import styles from './Currency.module.scss'
import {ScrollBox} from "../../../../utils/ui";
import {useDispatch, useSelector} from "react-redux";
import {api} from "../../../../api";
import {useCallback, useEffect, useState} from "react";

const Currency = () => {

	// Стейты для компоненты
	const currencyList = useSelector(state => state.settings.currency.currencies)
	const currencyDefault = useSelector(state => state.settings.currency.default)

	const dispatch = useDispatch()

	// Получение данных
	const getData = useCallback(async () => {
		try {
			const currency = await api.cabinetApi.getSettingsCurrencySelect();

			dispatch({type: "SET_CURRENCY", currencies: currency.currencies, default: currency.default})
		} catch (err) {
			console.log(err)
		}
	}, [])

	useEffect(() => {
		getData()
	}, [getData]);

	// Открытие модального окна для редактирования валюты
	function openEditModal() {
		dispatch({type: "OPEN_MODAL", modalLevel: 1, modalType: "settingsCurrencyEditModal"})
	}

	return (
		<div className={styles.currency}>
			<div className={styles.currencyTitle}>
				<span>Валюта</span>
				<div className={styles.currencyEdit} onClick={openEditModal}>
					<EditSvg/>
				</div>
			</div>

			<div className={styles.currencyDefault}>
				<span>Валюта по умолчанию: {currencyDefault?.currency_code} - {currencyDefault?.currency_name}</span>
			</div>

			<div className={styles.currencyList}>
				<div className={styles.currencyListTitle}>
					<span>Используемые валюты: </span>
				</div>

				<div className={styles.currencyListItems}>
					<ScrollBox>
						{currencyList.map((item, index) => {
							return (
								<div key={index} className={styles.currencyListItem}>
									<span>{item.currency_code} - {item.currency_name}</span>
								</div>
							)
						})}
					</ScrollBox>
				</div>
			</div>
		</div>
	)
}

export default Currency;