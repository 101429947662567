import { PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip } from 'chart.js';

// Регистрация компонентов Chart.js
ChartJS.register(RadialLinearScale, ArcElement, Tooltip);

function MainPageCatalogWidget({catalogStatistic}) {

	let labelsData = [];
	let valuesData = [];

	// Собираем данные для графика
	catalogStatistic.map((item, index) => {
		if(item.value !== 0){
			labelsData.push(item.name);
			valuesData.push(item.value);
		}
	})

	const data = {
		labels: labelsData,
		datasets: [
			{
				data: valuesData,
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(255, 205, 86, 0.2)',
					'rgba(201, 203, 207, 0.2)',
					'rgba(54, 162, 235, 0.2)',
				],
				borderColor: [
					'rgb(255, 99, 132)',
					'rgb(75, 192, 192)',
					'rgb(255, 205, 86)',
					'rgb(201, 203, 207)',
					'rgb(54, 162, 235)',
				],
				borderWidth: 1,
			},
		],
	};

	// Опции графика
	const options = {
		plugins: {
			legend: {
				display: false, // Отключение легенды
			},
		},
	};


	return(
		<div id="mainpagecatalogwidget">
			<PolarArea data={data} options={options}/>
		</div>
	)

}

export default MainPageCatalogWidget;