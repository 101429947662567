import {EmptyBlock, ProductActiveStatus, SelectInput} from "../index";
import EditElement from "../../../multitools/dealer/catalogPage/EditElement";
import styles from './OrderListItem.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {api} from "../../../api";

const OrderListItem = ({
	                         orderList,
	                         columns,
	                         emptyTitle,
	                         edit,
	                         store,
	                         catalogType,
	                         hideTools
                         }) => {

	const dispatch = useDispatch();
	const [showMore, setShowMore] = useState(false);

	const nextOffset = store.nextOffset;
	const tableWidth = store.tableWidth;
	const tableItemWidth = store.tableItemWidth;

	// Следим за изменением nextOffset и контролируем отображение кнопки "Ещё"
	useEffect(() => {
		if (nextOffset === null || nextOffset === undefined) {
			setShowMore(false);
		} else if (nextOffset >= 20) {
			setShowMore(true);
		}
	}, [nextOffset]);

	// Загрузка дополнительных товаров
	const loadMore = async () => {
		try {
			const response = await api.orderApi.getOrderList({offset: nextOffset});
			dispatch({type: "SET_MORE_ORDER_LIST", orderList: response.orders, nextOffset: response.next_offset});
		} catch (e) {
			console.log(e);
		}
	};

	// Получение данных из поля
	const getFieldData = (fieldCode, data) => {
		// Обрабатываем вложенные поля
		switch (fieldCode) {

			default:
				return data[fieldCode];
		}
	};

	function openOrderDetailModal(id){
		dispatch({type: "OPEN_MODAL", modalType: "orderDetailModal", modalLevel: 1, modalData: {id : id}, modalWidth: 'large'})
	}

	return (
		<div
			className={`${styles.productItems} ${hideTools ? styles.hideTools : ''}`}>
			{
				orderList?.length > 0 ? orderList.map((product) => {
					return (
						<div className={`${styles.productItem}`} key={product.id} style={{width: tableWidth}} onClick={()=>{openOrderDetailModal(product.id)}}>

							{columns && columns.map((column, index) => {
								return (
									<div className={`${styles.productItemColumn} ${styles[column.code]}`} key={index} onClick={(e) => {
										if (column.code === "buy") { e.stopPropagation(); } // Останавливаем распространение события
									}} style={{width: tableItemWidth ? tableItemWidth[column.code] : 'unset'}}>
										<div className={`${styles.productItemColumnName}`}><span>{column.name}:</span></div>
										<div className={styles.productItemColumnValue}>
											{column.html ? (
												<div dangerouslySetInnerHTML={{__html: product[column.code]}}></div>
											) : (
												<span>{getFieldData(column.code, product)}</span>
											)}
										</div>
									</div>
								)
							})}
							{edit && (
								<div className={styles.edit}>
									<EditElement item={product}/>
								</div>
							)}
						</div>
					);
				}) : <EmptyBlock title={emptyTitle}/>
			}
			{showMore && (
				<div className={styles.productMore}>
					<span onClick={loadMore}>Ещё</span>
				</div>
			)}
		</div>
	)
}

export default OrderListItem;