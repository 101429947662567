import {useEffect, useState} from "react";

import Home from "./pages/home";
import AuthForm from "./pages/auth";
import MultiModal from "./pages/components/MultiModal";
import Overlay from "./pages/components/Overlay/Overlay";
import Loader from "./pages/components/Loader/Loader";

import './styles/normalize.css'
import './styles/style.scss'

import {useLocalStorage} from "./hooks";

import {api} from "./api";
import {useDispatch, useSelector} from "react-redux";

import MultiPopup from "./pages/components/MultiPopup";
import MultiHelp from "./pages/components/MultiHelp/MultiHelp";
import TabBar from "./pages/main/components/TabBar";
import {useUrlParams} from "./hooks";
import {Toaster} from "react-hot-toast";
import {TOAST_CONFIG} from "./utils/config";

function App() {

	const authorized = useSelector(state => state.userData.data.authorized)
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useDispatch()
	const { removeParam } = useUrlParams();

	// Хук для сохранения языка в localStorage
	const [language, setLanguage] = useLocalStorage('language', 'ru')

	// Если язык не установлен, то устанавливаем русский
	if (!language) {
		setLanguage('ru')
	}

	useEffect(() => {
		// Запрашиваем данные аккаунта
		const getAccountData = async () => {
			try {
				const result = await api.accountApi.getInfo(); // Запрашиваем данные аккаунта
				dispatch({ type: 'SET_USER_AUTH', authorized: result.authorized}) // Устанавливаем данные в authorized
				dispatch({type: "SET_USER_DATA", userData: result})

			} catch (error) {
				console.log(error) // Выводим ошибку в консоль
			} finally {
				setTimeout(() => {
					setIsLoading(false); // Устанавливаем isLoading в false после завершения запроса, независимо от того, успешен ли он или нет
				}, 700)
			}
		}
		getAccountData()
	}, []);

	// Если пользователь не авторизован, то показываем лоадер
	useEffect(() => {
		if(!authorized) {
			setIsLoading(true);

			setTimeout(() => {
				setIsLoading(false);
			}, 700)
		}
	}, [authorized]);

	const modals = useSelector(state => state.multiModal.modals)

	// Закрытие модального окна по нажатию на Escape
	const handleKeyDown = (event) => {

		if (event.key === 'Escape') {
			// Проходимся по массиву c конца , и если модальное окно открыто , то закрываем его и выходим с цикла
			// Метод find() находит первый открытый модал.
			const openModal = modals.slice().reverse().find(modal => modal.modalIsOpen === true);

			if (openModal) {

				dispatch({ type: "CLOSE_MODAL", modalLevel: openModal.modalLevel });
				dispatch({type: "CLEAR_UPLOADED_IMAGES"})
				dispatch({type: "CLEAR_NOTICE"})

				if(openModal.modalLevel === 1){
					dispatch({type: "SET_MARKUPS_SELECTED_CATEGORY", selectedCategory: []});
					dispatch({type: "SET_MARKUPS_SELECTED_PRODUCT", selectedProduct: []});
					removeParam('product')
				}
			}

			dispatch({type: "SWITCH_POPUP", popupType: null, popupIsOpen: false, currentCatalogItem: null})
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [modals]);

	return (
		<div className={'i_cabinet'}>
			<Loader show={isLoading}/>
			<MultiModal />
			<MultiHelp />
			<MultiPopup />
			<Overlay />
			<Toaster toastOptions={TOAST_CONFIG}/>

			{authorized && (
				<>
					<Home/>
					<TabBar />
				</>
			)}

			{!authorized && (
				<AuthForm />
			)}
		</div>
	);
}

export default App;


