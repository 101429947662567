import { API_CABINET_URL } from "../utils/config";
import multiFetch from "../utils/multiFetch";

export class BasketApi{

	// API для получения количества товара в корзине
	// Передаём пустой BODY
	getBasketCount(){
		return multiFetch(`${API_CABINET_URL}/basket.count`, {}, {})
	}

	// API для получения списка товаров в корзине
	// Передаём пустой BODY
	getBasketList(){
		return multiFetch(`${API_CABINET_URL}/basket.list`, {}, {})
	}

	// Метод для удаления товара из корзины
	// Передаём BODY с параметрами: product_id
	deleteBasketItem(body){
		return multiFetch(`${API_CABINET_URL}/basket.delete`, body, {})
	}

	// Метод для изменения количества товара в корзине
	// Передаём BODY с параметрами: product_id, quantity, catalog_code
	setBasketItem(body){
		return multiFetch(`${API_CABINET_URL}/basket.set`, body, {})
	}

	// Формируем файл коммерческого предложения для скачивания и скачиваем его (PDF)

	generateBasketCom(body) {
		return fetch(`${API_CABINET_URL}/basket.com.generate`, {
			body: JSON.stringify(body),
			method: 'POST',
			credentials: 'include'
		})
			.then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok ' + response.statusText);
				}
				return response.blob(); // Преобразует ответ в Blob
			})
			.then(blob => {
				const url = URL.createObjectURL(blob); // Создает временный URL для Blob
				window.open(url); // Открывает PDF в новом окне
			})
			.catch(error => {
				console.error('There has been a problem with your fetch operation:', error);
			});
	}

	// Метод для создания заказа
	createOrder(body){
		return multiFetch(`${API_CABINET_URL}/order.create`, body, {})
	}

}

