import {api} from "../../../../api";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import '../styles/profilePage.scss'

import editImg from '../../../../images/catalogs/catalog-edit.svg'

import {ReactSVG} from "react-svg";
import {use} from "i18next";
import {COUNTRY_CONFIG} from "../../../../utils/config";

const ProfilePage = () => {

	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true)

	const openEditProfileModal = () =>{
		dispatch({type: "OPEN_MODAL", modalType: "editProfileModal", modalLevel: 1, modalData: profileDataArr})
	}

	// Асинхронная функция получения данных с сервера
	const getData = useCallback(async () => {
		try {
			const result = await api.cabinetApi.getProfile();

			if (result.status === true) {
				dispatch({type: "GET_PROFILE_DATA", profileData: result.distributor})
			}

			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	}, [])

	useEffect(() => {
		getData();
	}, [getData]);


	//Подтягиваем данные из стейта и обновляем их
	const siteID = useSelector(state => state.userData.data.country).toLowerCase()
	const profileData = useSelector(state => state.profile.profileData);
	const [profileDataArr, setProfileDataArr] = useState([]);

	useEffect(() => {

		const newData = [{
			name: 'Название компании: ', value: profileData.name , code: 'name'
		}, {
			name: 'Дата регистрации: ', value: profileData.date_register , code: 'date_register'
		}, {
			name: 'Телефон: ', value: profileData.phone , code: 'phone'
		}, {
			name: 'Контактное лицо: ', value: profileData.contact , code: 'contact'
		}, {
			name: 'Email: ', value: profileData.email , code: 'email'
		}, {
			name: 'Cайт: ', value: profileData.web , code: 'web'
		}, {
			name: COUNTRY_CONFIG[siteID].binName + ': ', value: profileData.bin , code: 'bin'
		}, {
			name: 'Показывается в списке дистрибьюторов: ', value: profileData.hide ? 'Нет' : 'Да', code: 'hide'
		}]

		setProfileDataArr(newData)

	},[profileData])


	return (
		<>
			<div className="i_profile">
				<div className="i_profile-items">
					<div className="i_profile-edit">
						<ReactSVG src={editImg} onClick={openEditProfileModal}/>
					</div>
					{profileData.length !== 0 && profileDataArr.map((item, index) => (
						<div className="i_profile-item" key={index}>
							<div className="i_profile-item-name">
								<span>{item.name}</span>
							</div>
							<div className="i_profile-item-value">
								<span>{item.value}</span>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export default ProfilePage;
