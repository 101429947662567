const initialState = {
	show: false,
	content: null,
	isHTML: false,
	showInMobile: false
}

export const cursorHelpReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SWITCH_CURSOR_HELP":
			return {
				...state,
				show: action.show,
				content: action.content,
				isHTML : action.isHTML,
				showInMobile: action.showInMobile
			};
		default:
			return state;
	}
}