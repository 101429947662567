import styles from './Button.module.scss'

const Button = ({title, onClick, disabled}) => {
	return (
		<button className={`${styles.button} ${disabled ? styles.disabled : ''}`} onClick={onClick}>
			<span>{title}</span>
		</button>
	)
}

export default Button;