import {ButtonLoader, SwitchButton, Toast} from "../../../../utils/ui";
import {useDispatch, useSelector} from "react-redux";
import {ReactComponent as EditSvg} from "../../../../images/catalogs/catalog-edit.svg";
import {ReactComponent as DeleteSvg} from "../../../../images/catalogs/catalog-trash.svg";
import {useEffect, useState} from "react";
import styles from './MarketplacesItem.module.scss'
import {api} from "../../../../api";
import toast from "react-hot-toast";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";

const MarketplacesItem = ({item}) => {

	const dispatch = useDispatch();
	const [active, setActive] = useState(item.active);
	const marketplaces = useSelector(state => state.marketplaces.marketplacesList)


	// Меняем статус подключения к маркетплейсу при изменении в стейте
	useEffect(() => {
		setActive(item.active)
	}, [item.active]);


	// Функция для изменения статуса подключения к маркетплейсу
	async function checkboxHandler(e) {
		try{
			const response = await api.marketplacesApi.setMarketplaceConnectionState({id: item.connection_id, is_active: e.isChecked})

			if(response.status === true){

				const updatedMarketplaces = marketplaces.map((market) => {
					if(market.connection_id === item.connection_id){
						market.active = e.isChecked
					}
					return market
				})

				setActive(e.isChecked)
				dispatch({type: 'SET_MARKETPLACES_LIST', marketplacesList: updatedMarketplaces})
			}else{
				Toast(response.message, 'success');
			}
		}catch (e){
			console.log(e)
		}
	}

	// Функция для открытия модального окна подключения маркетплейса
	function openMarketplaceConnectModal(item) {
		dispatch({
			type: "OPEN_MODAL",
			modalType: 'marketplaceConnectModal',
			modalLevel: 1,
			modalData: {marketCode: item.code, marketName: item.name, marketDescription: item.description, marketFile: item.file}
		})
	}

	// Открываем popup окно с вопросом удаления
	function deleteConnection(id) {
		dispatch({type: 'SWITCH_POPUP', popupType: 'deleteMarketplaceConnection', popupIsOpen: true, currentItem: id})
	}

	// Открываем модальное окно для редактирования подключения
	function editConnection(item){
		dispatch({
			type: "OPEN_MODAL",
			modalType: 'editMarketplaceConnectModal',
			modalLevel: 1,
			modalData: {marketCode: item.code, marketName: item.name, marketDescription: item.description, marketSettings: item.settings, marketConnectionID : item.connection_id, marketFile: item.file}
		})
	}

	return (
		<>
			<div className={styles.marketplacesItem}>
				{/* Если есть подключение тогда показывает блок с отключением и подлкючением доступа */}
				{item.connection_id && (
					<div className={`${styles.marketplacesItemSwitch} ${active ? styles.on : styles.off}`}>
						<span>{active ? 'Подключен' : 'Отключен'}</span>

						<SwitchButton checkBoxValue={checkboxHandler} inputChecked={active}/>
					</div>
				)}

				{
					item.image && (
						<div className={styles.marketplacesItemImg}>
							<img src={item.image} alt=""/>
						</div>
					)
				}
				<div className={styles.marketplacesItemName}>
					<span>{item.name}</span>
				</div>
				{/*
					Здесь проверяем если статус готов и есть подлючение то ставим блок с изменением подключением маркетплейса
				  Если нет то кнопка подключения к маркетплейсу или же статус скоро или в плане
				*/}
				{item.status === 'ready' ?
					item.connection_id ?
						<>
							<div className={styles.marketplacesItemTools}>
								<div className={styles.marketplacesItemToolsEdit} onMouseEnter={() => dispatch({
									type: "SWITCH_CURSOR_HELP",
									show: true,
									content: 'Изменить настройки подключения'
								})}
								     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
								     onClick={() => {
									     editConnection(item)
								     }}
								>
									<EditSvg/>
								</div>
								<div className={styles.marketplacesItemToolsDelete}
								     onMouseEnter={() => dispatch({
									     type: "SWITCH_CURSOR_HELP",
									     show: true,
									     content: 'Удалить подключение'
								     })}
								     onMouseLeave={() => dispatch({type: "SWITCH_CURSOR_HELP", show: false})}
								     onClick={() => {
									     deleteConnection(item.connection_id)
								     }}
								>
									<DeleteSvg/>
								</div>
							</div>

							{
								item.file && (
									<div className={styles.marketplacesItemFile}>
										<a href={item.file} target={'_blank'}>Ссылка на прайс-лист</a>
										<CopyToClipboardButton textToCopy={item.file} style={{position: 'relative', top: 'unset', right: 'unset', transform : 'unset'}}/>
									</div>
								)
							}
						</>


						:
						<div className={styles.marketplacesItemConnect}>
						<ButtonLoader title={'Подключить'} onClick={() => {
								openMarketplaceConnectModal(item)
							}} load={false}/>
						</div>
					:
					<div className={`${styles.marketplacesItemStatus} ${styles[item.status]}`}>
						<span>{item.status === 'soon' ? 'Скоро' : 'В плане'}</span>
					</div>
				}
			</div>
		</>
	)
}

export default MarketplacesItem;