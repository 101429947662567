const initialState = {
	marketplacesList: [],

}

export const marketplacesReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_MARKETPLACES_LIST":
			return {
				...state,
				marketplacesList: action.marketplacesList
			}

		default:
			return state;
	}
}