import closeBtn from '../../images/closeModal.svg'
import {useDispatch, useSelector} from "react-redux";

import SelectRoleModalDealer from "../../modal/SelectRoleModalDealer";
import SelectRoleModalDistributor from "../../modal/SelectRoleModalDistributor";
import AddCatalogModal from "../../modal/AddCatalogModal";
import EditCatalogModal from "../../modal/EditCatalogModal";
import DistributorConnectModal from "../../modal/DistributorConnectModal";
import EditProfileModal from "../../modal/EditProfileModal";
import AddPriceTypeModal from "../../modal/AddPriceTypeModal";
import EditPriceTypeModal from "../../modal/EditPriceTypeModal";
import DistributorCatalogProductDetailModal from "../../modal/ProductDetail/DistributorCatalogProductDetailModal";
import DealerCatalogProductDetailModal from "../../modal/ProductDetail/DealerCatalogProductDetailModal";
import MyCatalogProductDetailModal from "../../modal/ProductDetail/MyCatalogProductDetailModal";
import AddSourceCatalogModal from "../../modal/AddSourceCatalogModal";
import EditSourceCatalogModal from "../../modal/EditSourceCatalogModal";
import AddCategoryModal from "../../modal/AddCategoryModal";
import EditCategoryModal from "../../modal/EditCategoryModal";
import SourceCatalogProductDetailModal from "../../modal/ProductDetail/SourceCatalogProductDetailModal";
import CategoryListMobileModal from "../../modal/CategoryListMobileModal";
import EditProductImagesModal from "../../modal/EditProductImagesModal";
import CategoryListMobileModalNew from "../../modal/CategoryListMobileModal/CategoryListMobileModal";
import AttachCategoryModal from "../../modal/AttachCategoryModal";
import AddElementModal from "../../modal/AddElementModal";
import SelectCategoryAndProductModal from "../../modal/SelectCategoryAndProductModal";
import EditElementModal from "../../modal/EditElementModal";
import AddEmailModal from "../../modal/AddEmailModal/AddEmailModal";
import NoticeModal from "../../modal/NoticeModal/NoticeModal";
import SetAccountPasswordModal from "../../modal/SetAccountPasswoldModal/SetAccountPasswordModal";
import EditDetailProductModal from "../../modal/EditDetailProductModal/EditDetailProductModal";
import SettingsCurrencyEditModal from "../../modal/SettingsCurrencyEditModal/SettingsCurrencyEditModal";
import BasketModal from "../../modal/Basket/BasketModal/BasketModal";
import ExportCatalogModal from "../../modal/ExportCatalogModal/ExportCatalogModal";
import GenerateBasketComModal from "../../modal/GenerateBasketComModal/GenerateBasketComModal";
import MarketplaceConnectModal from "../../modal/MarketplaceConnectModal/MarketplaceConnectModal";

import {useViewportHeight, useWindowWidth, useUrlParams} from "../../hooks";
import EditMarketplaceConnectModal from "../../modal/EditMarketplaceConnectModal/EditMarketplaceConnectModal";
import OrderDetailModal from "../../modal/OrderDetailModal/OrderDetailModal";


const MultiModal = () => {

	const dispatch = useDispatch();
	const modals = useSelector(state => state.multiModal.modals);
	const { removeParam } = useUrlParams();

	const closeModal = (modalLevel) =>{
		dispatch({type: "CLOSE_MODAL", modalLevel: modalLevel})
		dispatch({type: "CLEAR_UPLOADED_IMAGES"})
		dispatch({type: "CLEAR_NOTICE"})

		if(modalLevel === 1){
			dispatch({type: "SET_MARKUPS_SELECTED_CATEGORY", selectedCategory: []});
			dispatch({type: "SET_MARKUPS_SELECTED_PRODUCT", selectedProduct: []});

			removeParam('product')
		}

	}

	const viewportHeight = useViewportHeight();
	const windowWidth = useWindowWidth();

	return (
		modals.map((modal, index) => {
			return (
				<div
					className={`i_multi_modal ${!modal.modalIsOpen ? 'hide' : ''} ${modal.modalWidth ? modal.modalWidth : ''} modal-level-${modal.modalLevel}`}
					key={index}
					style={windowWidth < 1024 ? {height: viewportHeight - 76} : {height: viewportHeight}}
				>
					<div className="i_container">
						<div className={`i_multi_modal-close`} onClick={() => {
							closeModal(modal.modalLevel)
						}}>
							<img src={`${closeBtn}`} alt="close"/>
						</div>

						{/*В зависимоти от переданного значения будет открываться определенное модальное окно*/}
						{modal.modalType === 'selectRoleModalDealer' && <SelectRoleModalDealer/>}
						{modal.modalType === 'selectRoleModalDistributor' && <SelectRoleModalDistributor/>}
						{modal.modalType === 'addCatalogModal' && <AddCatalogModal/>}
						{modal.modalType === 'editCatalogModal' && <EditCatalogModal/>}
						{modal.modalType === 'distributorConnectModal' && <DistributorConnectModal/>}
						{modal.modalType === 'editProfileModal' && <EditProfileModal/>}
						{modal.modalType === 'addPriceTypeModal' && <AddPriceTypeModal/>}
						{modal.modalType === 'editPriceTypeModal' && <EditPriceTypeModal/>}
						{modal.modalType === 'distributorCatalogProductDetailModal' && <DistributorCatalogProductDetailModal/>}
						{modal.modalType === 'dealerCatalogProductDetailModal' && <DealerCatalogProductDetailModal/>}
						{modal.modalType === 'myCatalogProductDetailModal' && <MyCatalogProductDetailModal/>}
						{modal.modalType === 'sourceCatalogProductDetailModal' && <SourceCatalogProductDetailModal/>}
						{modal.modalType === 'addSourceCatalogModal' && <AddSourceCatalogModal/>}
						{modal.modalType === 'editSourceCatalogModal' && <EditSourceCatalogModal/>}
						{modal.modalType === 'addCategoryModal' && <AddCategoryModal/>}
						{modal.modalType === 'editCategoryModal' && <EditCategoryModal/>}
						{modal.modalType === 'categoryListMobileModal' && <CategoryListMobileModal/>}
						{modal.modalType === 'categoryListMobileModalNew' && <CategoryListMobileModalNew/>}
						{modal.modalType === 'editProductImagesModal' && <EditProductImagesModal/>}
						{modal.modalType === 'attachCategoryModal' && <AttachCategoryModal />}
						{modal.modalType === 'addElementModal' && <AddElementModal />}
						{modal.modalType === 'editElementModal' && <EditElementModal />}
						{modal.modalType === 'selectCategoryAndProductModal' && <SelectCategoryAndProductModal />}
						{modal.modalType === 'addEmailModal' && <AddEmailModal />}
						{modal.modalType === 'noticeModal' && <NoticeModal />}
						{modal.modalType === 'setAccountPasswordModal' && <SetAccountPasswordModal />}
						{modal.modalType === 'editDetailProductModal' && <EditDetailProductModal />}
						{modal.modalType === 'settingsCurrencyEditModal' && <SettingsCurrencyEditModal />}
						{modal.modalType === 'basketModal' && <BasketModal />}
						{modal.modalType === 'exportCatalogModal' && <ExportCatalogModal />}
						{modal.modalType === 'generateBasketComModal' && <GenerateBasketComModal />}
						{modal.modalType === 'marketplaceConnectModal' && <MarketplaceConnectModal />}
						{modal.modalType === 'editMarketplaceConnectModal' && <EditMarketplaceConnectModal />}
						{modal.modalType === 'orderDetailModal' && <OrderDetailModal />}
					</div>
				</div>
			)
		})
	);
}

export default MultiModal;