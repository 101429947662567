import {useCallback, useEffect, useState} from "react";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import "../styles/mainPage.scss"
import MainDealerStartDashboard from "../../components/distributor/mainPage/dealer/MainDealerStartDashboard";
import MainDealerDistributorDashboard
	from "../../components/distributor/mainPage/dealer/MainDealerDistributorDashboard";
import {ReactSVG} from "react-svg";
import {api} from "../../../../api";
import {useWindowWidth} from "../../../../hooks";
import {useSelector} from "react-redux";
import {ScrollBox} from "../../../../utils/ui";

//Личный кабинет/главная страница
const MainPage = () => {
	//Создаем состояния для компонентов и виджетов
	const [isLoading, setIsLoading] = useState(true)

	const windowWidth = useWindowWidth()
	const showSideBar = useSelector(state => state.profile.showSideBar)

	let styles = {
		width: '100%'
	}

	const [dealerDistributor, setDealerDistributor] = useState([])
	const [dealerDistributorTitle, setDealerDistributorTitle] = useState('')
	const [dealerDistributorIcon, setDealerDistributorIcon] = useState('')
	const [dealerStart, setDealerStart] = useState([])
	const [dealerStartTitle, setDealerStartTitle] = useState('')
	const [dealerStartIcon, setDealerStartIcon] = useState('')

	const getData = useCallback(async () => {
		try{
			const dealerDistributor = await api.cabinetApi.getDealerDistributionDashboard();
			const dealerStart = await api.cabinetApi.getDealerStartDashboard();

			if(dealerDistributor.status === true){
				setDealerDistributor(dealerDistributor.distributors || [])
				setDealerDistributorTitle(dealerDistributor.name)
				setDealerDistributorIcon(dealerDistributor.icon)
			}

			if(dealerStart.status === true){
				setDealerStart(dealerStart.items || [])
				setDealerStartTitle(dealerStart.title)
				setDealerStartIcon(dealerStart.icon)
			}

		}catch (e) {
			console.log(e)
		} finally {
			setIsLoading(false)
		}
	},[])

	useEffect(() => {
		getData()
	}, [getData]);

	return (
		<div className={'i_main-page'}>
			{/*Если данные полностью загрузились , то показываем виджеты*/}
			{!isLoading && (
				<div className={'i_dealer-main-page'}>
					<div className="i_dealer-main-stats">
						<div className="i_main-widget" style={{...styles}}>
							<div className="i_main-widget-header">
								<MainDealerDistributorDashboard statistic={dealerDistributor}/>
							</div>
							<div className="i_main-widget-bottom">
								<div className="i_main-widget-title">
									<ReactSVG src={dealerDistributorIcon} className={'i_main-widgets-svg'}/>
									<span>{dealerDistributorTitle}</span>
								</div>
								<div className="i_main-widget-items">
									<ScrollBox height={'90%'}>
										{dealerDistributor.map((item, index) => {
											return (
												<div className="i_main-widget-item" key={index}>
													<div className="i_main-widget-item-title">
														<span>{item.name}</span>
													</div>
													<div className="i_main-widget-item-count">
														<span>{item.products} товаров</span>
													</div>
												</div>
											)
										})}
									</ScrollBox>
								</div>
							</div>
						</div>
					</div>
					<div className="i_dealer-main-start">
						<div className="i_dealer-main-start-title">
							<ReactSVG src={dealerStartIcon} className={'i_dealer-main-start-svg'}/>
							<span>{dealerStartTitle}</span>
						</div>
						<MainDealerStartDashboard items={dealerStart}/>
					</div>
				</div>
			)}
			{/*Если данные не загрузились, то показываем Skeleton*/}
			{isLoading && (
				<>
					<div style={{display: 'flex'}}>
						<Skeleton
							count={3}
							height={'80vh'}
							width={'30%'}
							inline={true}
							borderRadius={30}
							containerClassName={'i_main-page-skeleton'}
						/>
					</div>
				</>
			)}


		</div>
	)
}

export default MainPage;


