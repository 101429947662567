import React from "react";
import { useDispatch, useSelector } from "react-redux";
import minus from "../../../images/catalogs/category/minus.svg";
import plus from "../../../images/catalogs/category/plus.svg";
import {api} from "../../../api";
import { useParams } from "react-router-dom";
import styles from './CatalogListItem.module.scss';
import {ACTION_CONFIG} from "../../config";

// Рекурсивный компонент для отображения категорий
const Category = ({ category, categories, actionType, type }) => {
	// Находим подкатегории для текущей категории
	const distributor_id = useSelector(state => state.catalog.distributor_id);
	const subCategories = categories.filter(subCategory => subCategory.parent_id === category.id);
	const dispatch = useDispatch();
	const level = category.depth_level;
	const catalogCode = useSelector(state => state.catalog.catalogCode);
	const currentCategoryId = useSelector(state => state.catalog.categoryList.currentCategoryId);
	const nextOffset = useSelector(state => state.catalog.list.products.nextOffset);
	const showActiveItem = useSelector(state => state.catalog.list.products.showActiveItem);

	// Делаем запрос на выбранный каталог для показа товаров
	const changeProductList = async (id, name) => {
		dispatch({ type: ACTION_CONFIG[actionType].setCurrentCategoryID, currentCategoryId: id });
		dispatch({ type: ACTION_CONFIG[actionType].setCurrentCategoryName, currentCategoryName: name });
		dispatch({ type: ACTION_CONFIG[actionType].updateCheckedProductId, checkedProductId: [] });

		try {
			switch (type) {
				case 'source':
					let sourceBody = {
						"source_code": catalogCode,
						"offset": 0,
						"filter": { "category_id": id }
					};

					// Если есть фильтр по активности товара
					if (showActiveItem) {
						sourceBody.filter.active = showActiveItem;
					}

					let sourceProductList = await api.cabinetApi.getSourceProductList(sourceBody);

					dispatch({
						type: ACTION_CONFIG[actionType].updateProductList,
						products: sourceProductList.products,
						offset: sourceProductList.offset,
						nextOffset: !sourceProductList.next_offset ? null : sourceProductList.next_offset,
						currentCategoryId: id
					});
					break;

				case 'distributor':
					let distributorBody = {
						"code": catalogCode,
						"offset": 0,
						"filter": { "category_id": id }
					};

					if (showActiveItem) {
						distributorBody.filter.active = showActiveItem;
					}

					let distributorProductList = await api.cabinetApi.getCatalogProductList(distributorBody);

					dispatch({
						type: ACTION_CONFIG[actionType].updateProductList,
						products: distributorProductList.products,
						offset: distributorProductList.offset,
						nextOffset: !distributorProductList.next_offset ? null : distributorProductList.next_offset,
						currentCategoryId: id
					});
					break;

				case 'my':
					let myBody = {
						"code": catalogCode,
						"offset": 0,
						"filter": { "category_id": id }
					};

					if (showActiveItem) {
						myBody.filter.active = showActiveItem;
					}

					let myProductList = await api.cabinetApi.getCatalogProductList(myBody);

					dispatch({
						type: ACTION_CONFIG[actionType].updateProductList,
						products: myProductList.products,
						offset: myProductList.offset,
						nextOffset: !myProductList.next_offset ? null : myProductList.next_offset,
						currentCategoryId: id
					});
					break;

				case 'dealerDistributor': {
					let dealerDistributorBody = {
						"code": catalogCode,
						"distributor_id": distributor_id,
						"offset": 0,
						"filter": { "category_id": id }
					};

					if (showActiveItem) {
						dealerDistributorBody.filter.active = showActiveItem;
					}

					let dealerDistributorProductList = await api.cabinetApi.getDistributorCatalogProductList(dealerDistributorBody);

					dispatch({
						type: ACTION_CONFIG[actionType].updateProductList,
						products: dealerDistributorProductList.products,
						offset: dealerDistributorProductList.offset,
						nextOffset: !dealerDistributorProductList.next_offset ? null : dealerDistributorProductList.next_offset,
						currentCategoryId: id
					});
					break
				}

				default:
					console.error(`Unknown type: ${type}`);
			}

			dispatch({ type: "CLOSE_MODAL", modalLevel: 1 });

			//После изменения категории делаем плавный скролл наверх в productlist
			// const catalogProductItems = document.querySelector('.i_catalog-product-items');
			// catalogProductItems.scrollTo({ top: 0 });

		} catch (e) {
			console.log(e);
		}
	};

	// Делаем активной выбранную категорию
	function changeSelectedItem(id) {
		let updatedItem = categories.map((item) => {
			if (item.id === id) {
				item.is_active = true;
				changeProductList(id, item.name);
			} else {
				item.is_active = false;
			}

			return item;
		});

		dispatch({ type: ACTION_CONFIG[actionType].setCategoryList, categoryList: updatedItem });
	}

	// Если у категории нет дочерних элементов то раскрываем ее по нажатию на круг
	function changeIsShow(id) {
		let updatedItem = categories.map((item) => {
			if (item.id === id && item.has_children) {
				item.is_show = !item.is_show;
			} else {
				return item;
			}
			return item;
		});

		dispatch({ type: ACTION_CONFIG[actionType].setCategoryList, categoryList: updatedItem });
	}

	return (
		<div className={`${styles.iCatalogCategoryItem} ${category.is_show ? styles.active : ''} ${styles[`iCatalogCategoryItemLevel${level}`]} ${!category.has_children ? 'last-child' : ''}`}
		     style={{ paddingLeft: level === 0 ? '0' : '20px' }}>
			<div className={`${styles.iCatalogCategoryItemTitle} ${currentCategoryId === category.id ? styles.selected : ''}`}
			     onClick={() => { changeSelectedItem(category.id); }}>
				{
					category.id !== 0 && (
						<div
							className={`${styles.iCatalogTitleCircle} ${!category.has_children ? '' : styles.lastChild} ${currentCategoryId === category.id ? styles.active : category.is_active ? styles.active : ''}`}
							onClick={(e) => {
								e.stopPropagation();
								category.has_children ? changeIsShow(category.id) : changeSelectedItem(category.id)
							}}
						>
							{category.is_show && category.has_children ?
								<img src={minus} alt="-" /> : (!category.has_children ? null : <img src={plus} alt="+" />)}
						</div>
					)
				}
				{category.name}
			</div>
			{
				category.id !== 0 && (
					subCategories.map(subCategory => (
						<Category key={subCategory.id} category={subCategory} categories={categories} actionType={actionType} type={type} />
					))
				)
			}
		</div>
	);
};

const CatalogListItem = ({categories, actionType, type}) => {
	// Находим корневые категории (parent_id === 0)

	const rootCategories = categories.filter(category => category.parent_id === 0 || category.id === 0);

	return (
		<div>
			{rootCategories.map(category => (
				<Category key={category.id} category={category} categories={categories} actionType={actionType} type={type}/>
			))}
		</div>
	);
};

export default CatalogListItem;
