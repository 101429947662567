import Skeleton from "react-loading-skeleton";
import {api} from "../../../../../api";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import "../../styles/catalogPage.scss"
import CategoryListMobile from "../../../components/CategoryListMobile/CategoryListMobile";
import ElementTools from "../../../../components/ElementTools";
import {SourceList} from "../../../components/ProductList";

import styles from './style.module.scss'
import CategoryList from "../../../../main/components/CategoryList/CategoryList";

const CurrentSourceCatalogPage = () => {
	const [isLoading, setIsLoading] = useState(true)
	const dispatch = useDispatch();

	let {code} = useParams();

	//Достаем список категорий и товаров
	const getList = async () => {
		try {
			const categoryList = await api.cabinetApi.getSourceCategoryList({source_code: code});
			const productList = await api.cabinetApi.getSourceProductList({source_code: code, offset: 0});

			dispatch({type: "SET_CATALOG_LIST_COLUMNS", columns: productList.columns})
			dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: productList.products, nextOffset: productList.next_offset})
			dispatch({type: "SET_CATALOG_CATEGORY_LIST", categoryList: categoryList.categories})

			setIsLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		// Устанавливаем текущий мультитул
		setTimeout(()=>{
			dispatch({type: "SET_TOOLS", currentTools: "catalogs-source-inner"})
		},5)
		dispatch({type: "SET_CATALOG_CODE", catalogCode: code})

		dispatch({type: 'SET_CATALOG_LIST_TABLE_ITEM_WIDTH', tableItemWidth: null})
		dispatch({type: 'SET_CATALOG_LIST_TABLE_WIDTH', tableWidth: null})

		// Получаем список категорий и товаров
		getList()
	}, [])

	//Достаем список товаров из стейта
	const productListState = useSelector(state => state.catalog.productList.productList);
	const categoryList = useSelector(state => state.catalog.categoryList.categoryList);
	const store = useSelector(state => state.catalog)

	return (
		<div className={styles.catalogPage}>
			{isLoading && (
				<>
					<div style={{display: 'flex', height: "100%"}}>
						<Skeleton
							count={2}
							height={'100%'}
							inline={true}
							borderRadius={20}
							containerClassName={'i_catalog-page-in-skeleton'}
						/>
					</div>
				</>
			)}
			{!isLoading && categoryList && productListState && (
				<div className={styles.catalogMain}>

					<CategoryList category={categoryList} type={'source'}/>

					{/*	/!*Мобильная версия категорий*!/*/}
					<CategoryListMobile />

					{/*Выводим список товаров*/}
					<div className={styles.catalogProducts}>
						<ElementTools/>
						<SourceList store={store}/>
					</div>

				</div>
			)}
		</div>
	)
}

export default CurrentSourceCatalogPage;