import styles from "./ProductListHead.module.scss";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ACTION_CONFIG} from "../../config";
import {SelectInput} from "../index";
import Cookies from "js-cookie";

const ProductListHead = ({columns, store, showCheckBox, actionType, catalogType}) => {

	const [checkedAllProduct, setCheckedAllProduct] = useState(false);
	const dispatch = useDispatch();
	const products = store.list.products.productList;
	const nextOffset = store.list.products.nextOffset;
	const tableMinWidth = store.list.tableMinWidth;
	const storeTableItemWidth = store.list.tableItemWidth;
	const storeTableWidth = store.list.tableWidth;
	const distributorID = store.distributor_id

	const [notTouch, setNotTouch] = useState(false);
	const [isResizing, setIsResizing] = useState(false);
	const [tableWidth, setTableWidth] = useState(0);
	const [columnsWidth, setColumnWidth] = useState({});
	const [activeItem, setActiveItem] = useState(null);
	const catalogCode = store.catalogCode;

	// Записываем ширину таблицы в cookies, а данные записываем в store при завершении ресайза
	useEffect(() => {
		if (!isResizing) {

			let cookiesName = '';

			switch (catalogType) {
				case 'dealerDistributor':
					cookiesName = `columns_${catalogType}_${catalogCode}_${distributorID}`;
					break;
				default:
					cookiesName = `columns_${catalogType}_${catalogCode}`;
					break;
			}

			Cookies.set(cookiesName, JSON.stringify({
				columns: JSON.stringify(columnsWidth),
				tableWidth: tableWidth
			}), {expires: 365})

			dispatch({type: ACTION_CONFIG[actionType].setCatalogListTableItemWidth, tableItemWidth: columnsWidth})
			dispatch({type: ACTION_CONFIG[actionType].setCatalogListTableWidth, tableWidth: tableWidth})
		}
	}, [isResizing, tableWidth, columnsWidth]);

	// Функция отрабатывается если у клиента нет сохраненных настроек ширины колонок в Cookies, инициализируем ширину колонок по умолчанию
	function initColumnsWidth() {
		let minWidth = showCheckBox ? 50 : 0;

		const updatedColumnsWidth = columns.reduce((acc, item) => {

			let itemWidth = 150;

			if (item.code === 'active') itemWidth = 25;
			if (item.code === 'name') itemWidth = 400;
			if (item.code === 'buy') itemWidth = 110;

			minWidth += itemWidth + 10;

			acc[item.code] = itemWidth;
			return acc;

		}, {});

		if (minWidth < tableMinWidth) {
			// Получение ключей объекта
			const keys = Object.keys(updatedColumnsWidth);
			// Получение последнего ключа
			let lastKey = keys[keys.length - 1];

			if (lastKey === 'buy') lastKey = keys[keys.length - 2];

			// Обращение к значению последнего элемента
			updatedColumnsWidth[lastKey] = updatedColumnsWidth[lastKey] + (tableMinWidth - minWidth);

			minWidth = tableMinWidth - 10;
		}

		setTableWidth(minWidth + 1);
		setColumnWidth(updatedColumnsWidth);
	}

	// Инициализация ширины колонок
	useEffect(() => {
		if (storeTableItemWidth && storeTableWidth) {
			setTableWidth(storeTableWidth);
			setColumnWidth(storeTableItemWidth);
		} else {
			initColumnsWidth();
		}
	}, [columns]);


	// Проверяем, все ли товары выделены
	useEffect(() => {
		if (store.list.products.checkedProductId?.length <= 0) {
			setCheckedAllProduct(false);
		}
	}, [store.list.products.checkedProductId]);

	// Обработчик для изменения состояний всех чекбоксов
	const checkAllCheckBox = () => {
		// Если нет товаров, то выходим
		if (!Array.isArray(store?.list?.products?.productList)) return

		setCheckedAllProduct(!checkedAllProduct);
		const updatedSelectedProduct = [...store.list.products.productList].map(productItem => {
			productItem.checked = !checkedAllProduct;
			return productItem;
		});

		// Обновляем checkedProductList с товарами, которые отмечены
		const selectedItems = updatedSelectedProduct
			.filter(productItem => productItem.checked)
			.map(productItem => productItem.id);

		dispatch({
			type: ACTION_CONFIG[actionType].updateProductList,
			products: updatedSelectedProduct,
			nextOffset: nextOffset
		});
		dispatch({type: ACTION_CONFIG[actionType].updateCheckedProductId, checkedProductId: selectedItems});
	};

	// Делаем колонку растягиваемой
	const changeColumnWidth = (e, columnCode) => {
		if (!columnsWidth) return;
		let x = e.clientX;
		setNotTouch(true);
		setIsResizing(true);

		setActiveItem(columnCode)

		const onMouseMove = (e) => {
			let dx = e.clientX - x;
			const updatedColumnsWidth = {...columnsWidth};

			// Определяем минимальную ширину в зависимости от columnCode
			const minWidth = columnCode === 'active' ? 25 : 80;

			// Убедиться, что новая ширина колонки не меньше минимальной
			const newWidth = updatedColumnsWidth[columnCode] + dx;

			if (newWidth < minWidth) {
				dx = minWidth - updatedColumnsWidth[columnCode];
			}

			updatedColumnsWidth[columnCode] += dx;

			let tableWidth = showCheckBox ? 50 : 0;

			Object.entries(updatedColumnsWidth).forEach(([key, width]) => {
				const columnMinWidth = key === 'active' ? 25 : 80;
				tableWidth += width < columnMinWidth ? columnMinWidth : width;
				tableWidth += 10; // Увеличение ширины таблицы на 10 для каждого столбца
			});

			if (tableWidth < tableMinWidth) {
				// Получение ключей объекта
				const keys = Object.keys(updatedColumnsWidth);
				// Получение последнего ключа
				let lastKey = keys[keys.length - 1];

				if (lastKey === 'buy') lastKey = keys[keys.length - 2];

				// Обращение к значению последнего элемента
				updatedColumnsWidth[lastKey] = updatedColumnsWidth[lastKey] + (tableMinWidth - tableWidth);

				tableWidth = tableMinWidth - 5;
			}

			setTableWidth(tableWidth + 1);
			setColumnWidth(updatedColumnsWidth);
		};

		const onMouseUp = () => {
			setNotTouch(false);
			setIsResizing(false); // Снимаем флаг после завершения ресайза
			setActiveItem(null)

			document.removeEventListener('mousemove', onMouseMove);
			document.removeEventListener('mouseup', onMouseUp);
		};

		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseup', onMouseUp);
	};

	return (
		<>
			<div
				className={`${styles.productHead} ${styles[actionType]} ${showCheckBox ? styles.checkboxList : ''} ${notTouch ? styles.notTouch : ''}`}
				style={{width: tableWidth}}
			>
				{showCheckBox && (
					<div
						className={`${styles.productHeadItem} ${styles.checkbox} ${styles.showMobile} ${products === undefined ? styles.disabled : ''}`}>
						<SelectInput title="" value={0}
						             checkBoxValue={checkAllCheckBox}
						             inputChecked={checkedAllProduct}
						             onlyOne={true}/>
					</div>
				)}
				{columns && columns.map((column, index) => {
					return (
						<div className={`${styles.productHeadItem} ${styles[column.code]}`} key={index}
						     style={{width: columnsWidth[column.code]}}>
							<div className={`${styles.productHeadItemContent}`}>
								<span>{column.name}</span>
							</div>
							<div className={`${styles.productHeadItemLine} ${activeItem === column.code ? styles.active : ''}`}
							     onMouseDown={(e) => {
								     changeColumnWidth(e, column.code)
							     }}></div>
						</div>
					)
				})}
			</div>
		</>
	)
}

export default ProductListHead;