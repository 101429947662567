import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from './NoticeModal.module.scss';

import {Button, ScrollBox, NoticeList, EmptyBlock} from "../../utils/ui";
import {api} from "../../api";
import {useDispatch, useSelector} from "react-redux";

const NoticeModal = () => {
	// Состояния для инпутов

	// Состояния компоненты
	const dispatch = useDispatch();

	// Для перевода
	const {t} = useTranslation();

	const getData = useCallback(async () => {
		const response = await api.cabinetApi.getNotificationList({offset: 0});
		dispatch({type: "SET_NOTICE_LIST", noticeList: response.notifications});
		dispatch({type: "SET_NOTICE_OFFSET", offset: response.next_offset});
	}, [])

	useEffect(() => {
		getData();
	}, [getData]);

	const store = useSelector((state) => state.notice);

	return (
		<div className={styles.modal}>
			<div className={styles.modalTitle}>
				<span>Уведомления</span>
			</div>
			<ScrollBox>
				{
					store.noticeList && store.noticeList.length > 0 ? (
						<div className={styles.modalItems}>
							<NoticeList store={store}/>
						</div>
					) : (
						<EmptyBlock title={'Список уведомлений пуст'} fullWidth={true}/>
					)
				}
			</ScrollBox>
		</div>
	);
};

export default NoticeModal;
