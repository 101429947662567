import {api} from "../../../../api";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import Skeleton from "react-loading-skeleton";
import "../styles/settingsPage.scss"
import {ReactSVG} from "react-svg";

import eyeOpen from "../../../../images/eye.svg"
import eyeClose from "../../../../images/eye-close.svg"

import {useDispatch, useSelector} from "react-redux";
import Currency from "../../components/Currency/Currency";
import CurrencyRate from "../../components/CurrencyRate/CurrencyRate";

const SettingsPage = () => {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	//Храним api ключ
	const apiKey = useSelector(state => state.profile.apiKey)
	const showApiKey = useSelector(state => state.profile.showApiKey)
	const [isLoading, setIsLoading] = useState(true)

	//Ассинхронная функция получения Api ключа с сервера
	const getData = useCallback(async() => {
		try{
			const apiKey = await api.cabinetApi.getApiKeys();

			if(apiKey.status === false){
				dispatch({type: "SET_API_KEY", apiKey: t('I_API_KEY_NOT_FOUND')})
				dispatch({type: "SET_SHOW_API_KEY", showApiKey: true})
			}else if(apiKey.status === true){
				dispatch({type: "SET_API_KEY", apiKey: apiKey.key})
			}
			setIsLoading(false)

		}catch (err){
			console.log(err)
		}
	}, [])

	//Фунцкия генерации нового api ключа
	const openGenerateApiKeyPopup = async () => {
		dispatch({type: "SWITCH_POPUP", popupType: "generateApiKey", popupIsOpen: true})
	}

	//Функция переключения показа api ключа
	const switchShowApiKey = () => {
		dispatch({type: "SET_SHOW_API_KEY", showApiKey: !showApiKey})
	}

	useEffect(()=>{
		getData()
	},[getData])

	return (
		<div className="i_settings-page">
			{isLoading && (
				<>
					<Skeleton
						count={1}
						height={'14rem'}
						width={'100%'}
						inline={true}
						borderRadius={20}
					/>
				</>
			)}
			{!isLoading && (
				<div className="i_settings-content">
					<Currency />
					<CurrencyRate />
					<div className="i_settings-items">
						<div className="i_settings-item">
							<div className="i_settings-item-title">
								<span>{t('I_API_KEY')}</span>
							</div>
							<div className="i_settings-item-value">
								<span>{showApiKey ? apiKey : '##############'}</span>
								<ReactSVG src={showApiKey ? eyeOpen : eyeClose} className={'i_settings-item-showkey'} onClick={switchShowApiKey}/>
								<CopyToClipboardButton textToCopy={apiKey}/>
							</div>
							<div className="i_settings-item-button" onClick={openGenerateApiKeyPopup}>
								<span>{t("I_API_KEY_BTN")}</span>
							</div>

							<div className="i_setting-item-alert">
								<span>! {t('I_API_KEY_ALERT')}</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default SettingsPage;