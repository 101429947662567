import { useState, useRef, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale";
import { format } from "date-fns";
import { ReactComponent as CalendarSvg } from "../../../images/calendar.svg";

// Регистрация русской локали
registerLocale("ru", ru);

function DateTimePicker({ title, onChange, value }) {
	const [selectedDate, setSelectedDate] = useState(null);
	const datePickerRef = useRef(null); // Создаем ref для DatePicker

	useEffect(() => {
		if (value) {
			try {
				// Разбор строки даты
				const [datePart, timePart] = value.split(' ');
				const [day, month, year] = datePart.split('.').map(Number);
				const [hours, minutes, seconds] = timePart.split(':').map(Number);
				const parsedDate = new Date(year, month - 1, day, hours, minutes, seconds);

				if (!isNaN(parsedDate)) {
					// Установка разобранной даты
					setSelectedDate(parsedDate);
				} else {
					console.error("Недопустимый формат даты");
				}
			} catch (error) {
				console.error("Ошибка при разборе даты:", error);
			}
		}else{
			setSelectedDate(null);
		}
	}, [value]);

	// Отправка значения в родительский компонент
	const onChangeDate = (date) => {
		if(date !== null){
			setSelectedDate(date);
			onChange(format(date, "dd.MM.yyyy HH:mm", { locale: ru }));
		}else{
			onChange('');
		}
	};

	// Открытие календаря при клике на иконку
	const handleIconClick = () => {
		datePickerRef.current.setOpen(true);
	};

	return (
		<div className="i_date-picker">
			<label>{title}</label>
			<div className="date-picker-wrapper">
				<DatePicker
					ref={datePickerRef}                     // Привязываем ref к DatePicker
					selected={selectedDate}
					onChange={onChangeDate}
					showTimeSelect
					showYearDropdown
					dateFormat="d MMMM, yyyy HH:mm"
					placeholderText="Выберите дату и время"
					timeCaption="Время"
					locale="ru"
					timeFormat="HH:mm"
					timeIntervals={15}
					yearDropdownItemNumber={15}
					scrollableYearDropdown
				/>
				<div onClick={handleIconClick} className={'date-picker-svg'}>
					<CalendarSvg />
				</div>
			</div>
		</div>
	);
}

export default DateTimePicker;