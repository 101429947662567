import {useSelector} from "react-redux";
import AddMyCategory from "../multitools/components/AddMyCategory";
import AddCategory from "../multitools/components/AddCategory";
import AddSourceCategory from "../multitools/components/AddSourceCategory";


const AddCategoryModal = () => {
	// Redux методы
	const currentTools = useSelector(state => state.multiTools.currentTools)


	return (
		<>
			{currentTools === "catalogs-my-inner" && <AddMyCategory />}
			{currentTools === "matching-page" && <AddMyCategory />}
			{currentTools === "catalogs-source-inner" && <AddSourceCategory />}
			{currentTools === "distributor-catalogs-inner" && <AddCategory />}
		</>
	)
}

export default AddCategoryModal