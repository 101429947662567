const initialState = {
	modals: [
		{
			modalType: null,
			modalIsOpen: false,
			modalData: null,
			modalWidth: null,
			currentCategoryId: 0,
			currentCategoryName: null,
			modalLevel: 1,
			modalHeight: null,
			categoryList: [],
		},
		{
			modalType: null,
			modalIsOpen: false,
			modalData: null,
			modalWidth: null,
			currentCategoryId: 0,
			currentCategoryName: null,
			modalLevel: 2,
			modalHeight: null,
			categoryList: [],
		},
		{
			modalType: null,
			modalIsOpen: false,
			modalData: null,
			modalWidth: null,
			currentCategoryId: 0,
			currentCategoryName: null,
			modalLevel: 3,
			modalHeight: null,
			categoryList: []
		},
	],
}

export const modalReducer = (state = initialState, action) => {

	switch (action.type) {
		case "OPEN_MODAL":
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							modalType: action.modalType,
							modalIsOpen: true,
							modalData: action.modalData,
							modalWidth: action.modalWidth,
							modalHeight: action.modalHeight
						}
					}
					return modal;
				})
			};
		case "SET_MODAL_DATA": {
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							modalData: action.modalData
						}
					}
					return modal;
				})
			}
		}
		case "SET_MODAL_CURRENT_CATEGORY_ID": {
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							currentCategoryId: action.currentCategoryId
						}
					}
					return modal;
				})
			}
		}
		case "SET_MODAL_CURRENT_CATEGORY_NAME": {
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							currentCategoryName: action.currentCategoryName
						}
					}
					return modal;
				})
			}
		}

		case "CLOSE_MODAL": {
			return{
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {

						// Остановка интервала
						if(modal?.modalData?.intervalId){
							clearInterval(modal.modalData.intervalId);
						}

						return {
							...modal,
							modalType: null,
							modalIsOpen: false,
							modalData: null,
							modalWidth: null,
							currentCategoryId: null,
							modalLevel: action.modalLevel,
							modalHeight: null,
						}
					}
					return modal;
				})
			}
		}
		case "SET_MODAL_CATEGORY_LIST": {
			return {
				...state,
				modals: state.modals.map((modal) => {
					if (modal.modalLevel === action.modalLevel) {
						return {
							...modal,
							categoryList: action.categoryList
						}
					}
					return modal;
				})
			}
		}
		default:
			return state;
	}
}