const initialState = {
	priceTypes : null,
	currency: {
		currencies: [],
		default: null,
		selected:{
			currencies: [],
			default: null
		},
		currenciesList : []
	}
}

export const settingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SET_PRICE_TYPES" :
			return {
				...state,
				priceTypes: action.priceTypes
			}

		case "SET_CURRENCY" : {
			return {
				...state,
				currency: {
					...state.currency,
					currencies: action.currencies,
					default: action.default
				}
			}
		}

		case "SET_SELECTED_CURRENCY" : {
			return {
				...state,
				currency: {
					...state.currency,
					selected: {
						currencies: action.currencies,
						default: action.default
					}
				}
			}
		}

		case "SET_CURRENCIES_LIST" : {
			return {
				...state,
				currency: {
					...state.currency,
					currenciesList: action.currenciesList
				}
			}
		}

		default:
			return state;
	}
}