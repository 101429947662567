import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {api} from "../../api";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";
import CategoryList from "../../modal/components/CategoryList";
import {ButtonLoader, CustomInput, Toast} from "../../utils/ui";
import toast from "react-hot-toast";

const AddCatalogDistributorElement = () => {
	// Redux методы
	const dispatch = useDispatch();
	const catalogCode = useSelector(state => state.catalog.catalogCode)
	const currentCategoryId = useSelector(state => state.multiModal.modals[0].currentCategoryId);
	const productList = useSelector(state => state.catalog.list.products.productList);
	//Состояния для инпутов
	const [name, setName] = useState('')
	const [longName, setLongName] = useState('')
	const [barcode, setBarcode] = useState('')
	const [brand, setBrand] = useState('')
	const [vendorCode, setVendorCode] = useState('')
	const [innerCode, setInnerCode] = useState('')

	const formItems = [
		{
			name: 'Название товара',
			value: name,
			stateFunc: setName,
			required: true,
			helpTitle: 'Тип товара, Бренд товара, модель товара, несколько наиболее важных характеристик. Например: Ноутбук Asus N53s / Экран 15" / Core i5 / 512 ОЗУ / 512 SSD',
		},
		{
			name: 'Детальное название товара',
			value: longName,
			stateFunc: setLongName,
			helpTitle: 'В детальном названии можно указать как можно больше важных характеристик товара, короткими значениями или общепринятыми сокращениями. Используйте единый подход для всех похожих товаров.',
		},
		{
			name: 'Бренд',
			value: brand,
			stateFunc: setBrand,
			helpTitle: 'Укажите название производителя товара. Используйте единое написание для всех товаров данного производителя. Например Asus или Samsung. Если вы пишите на английском, то во всех товарах указываете бренд, так-же, на английском языке.'
		},
		{
			name: 'Артикул',
			value: vendorCode,
			stateFunc: setVendorCode,
			helpTitle: 'Это код, который продавцы и производители используют для идентификации и учета своих товаров. Артикул товара чаще всего печатается на этикетке или упаковке товара.'
		},
		{
			name: 'Штрихкод',
			value: barcode,
			stateFunc: setBarcode,
			helpTitle: 'Штрих код товара — это графическое изображение, содержащее закодированные буквы и цифры. Вы можете внести через штрих код сканер или вручную, под линиями штрих кода располагаются цифры, дублирующие информацию. Если штрих кодом является QR код, вы можете считать его своим смартфоном и внести полученное значение.'
		},
		{
			name: 'Внутренний код',
			value: innerCode,
			stateFunc: setInnerCode,
			helpTitle: 'Это ваш внутренний код. Он может быть любым, на ваше усмотрение. Данный код служит идентификатором данного товара в вашей базе товаров и по нему можно сверять товар в вашей базе и товар в базе Apicore.'
		},
	]


	//Состояния компоненты
	const [load, setLoad] = useState(false)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false);
	//Нужно для перевода
	const {t} = useTranslation();

	const formSubmit = async () => {
		//Отправляем запрос на добавление каталога
		setLoad(true)
		setError('')

		let body = {
			"catalog_code": catalogCode,
			"name": name,
			"long_name": longName,
			"category_id":currentCategoryId,
			"vendor": brand,
			"vendor_code": vendorCode,
			"barcode": barcode,
			"inner_code": innerCode
		}

		// Делаем запрос
		try {
			const response = await api.cabinetApi.addCatalogProduct(body);

			if(response.status === true){

				dispatch({type: "SET_CATALOG_LIST_PRODUCTS", products: [response.products[0], ...productList]})
				Toast(response.message, 'success');
				dispatch({type: "CLOSE_MODAL", modalLevel: 1})
			}else{
				setError(response.message)
			}

		} catch (error) {
			console.log(error);
		} finally {
			setTimeout(() => {
				setLoad(false)
			}, 700)
		}
	}

	console.log(productList)

	return (
		<>
			{loading && (
				<div className={`i_add-category-modal-loading`} style={{height:"100%"}}>
					<Skeleton count={1} height={'100%'} borderRadius={10}/>
				</div>
			)}
			{
				!loading && (
					<div className={'i_add-category-modal'}>
						<div className="i_add-category-modal-title">
							<span>Добавление товара</span>
						</div>
						<div className="i_add-category-content" style={{paddingTop:15}}>
							<div className="i_add-category-items">
								{formItems.map((item, index) => {
									return (
										<div className={`i_add-catalog-modal-item`} key={index}>
											<CustomInput title={item.name} value={item.value} onChange={(e) => {
												item.stateFunc(e)
											}} showHelp={item.showHelp} helpTitle={item.helpTitle} required={item.required}/>
										</div>
									)
								})}

								<div className="i_add-category-list open">
									<div className="i_add-category-list-title">
										<span>Структура категорий</span>
									</div>

									<div className="i_add-category-list-content add-item">
										<CategoryList/>
									</div>

								</div>

							</div>

							<div className="i_add-modal-description">
								<span>
									<strong>Фото, описание, свойства товара и другие атрибуты товара</strong>, можно будет добавить, используя функцию редактирования данной карточки товара.
								</span>
							</div>

							<div className="i_add-category-modal-btn" onClick={formSubmit}>
								<ButtonLoader load={load} title={t('I_SELECT_ROLE_FORM_BTN')}/>
							</div>
							{error !== '' ?
								<div dangerouslySetInnerHTML={{__html: error}} className={`i_add-category-modal-error`}></div> : ''
							}
						</div>
					</div>
				)
			}
		</>
	)
}

export default AddCatalogDistributorElement